import { availableSlots } from "../constants/constants";
import {
  Order,
  OrderRaw,
  PAYMENT_TYPE,
  STATUS,
} from "../redux/features/order/order.types";

export const formatStatus = (x: string): STATUS => {
  if (x === "0") return STATUS.CLEANING;
  else if (x === "1") return STATUS.READY;
  else if (x === "2") return STATUS.COMPLETED;
  else if (x === "4") return STATUS.PENDING;
  else if (x === "5") return STATUS.PICKED_UP;
  else return STATUS.PENDING;
};

export const formatPaymentType = (x: string): PAYMENT_TYPE => {
  if (x === "0") return PAYMENT_TYPE.NO_PAYMENT;
  else if (x === "1") return PAYMENT_TYPE.CASH;
  else if (x === "2") return PAYMENT_TYPE.CARD;
  else if (x === "3") return PAYMENT_TYPE.CHECK;
  else return PAYMENT_TYPE.NO_PAYMENT;
};

export const formatDate = (x: string): string => {
  return new Date(parseInt(x) * 1000).toISOString();
};

export const formatOrder = (x: OrderRaw): Order => {
  const order: Order = {
    ...x,
    customerID: parseInt(x.customerID),
    total: parseFloat(x.total),
    discount: parseFloat(x.discount),
    tax1: parseFloat(x.tax1),
    tax2: parseFloat(x.tax2),
    tax3: parseFloat(x.tax3),
    paid: parseInt(x.paid) === 1,
    paymentType: formatPaymentType(x.paymentType),
    pieces: parseInt(x.pieces),
    paymentTime: formatDate(x.paymentTime),
    status: formatStatus(x.status),
    deliveryDate: formatDate(x.deliveryDate),
    pickupDate: formatDate(x.pickupDate),
  };
  return order;
};

export const getUpdatedOrdersArray = (
  orders: Order[],
  order: OrderRaw
): Order[] => {
  const ordersCopy = [...orders];
  const orderIndex = ordersCopy.findIndex((o) => o.id === order.id);
  orderIndex !== -1 && (ordersCopy[orderIndex] = formatOrder(order));
  return ordersCopy;
};

export const getActiveStepForOrder = (x: Order): number => {
  if (!x) return 0;
  switch (x.status) {
    case STATUS.PICKED_UP:
      return 0;
    case STATUS.CLEANING:
      return 1;
    case STATUS.READY:
      return x.paymentType === PAYMENT_TYPE.NO_PAYMENT ? 2 : 3;
  }
  return 0;
};

export const sortOrders = (orders: OrderRaw[]): OrderRaw[] => {
  return orders.sort((o1, o2) => {
    if (parseInt(o1.id) > parseInt(o2.id)) return -1;
    else if (parseInt(o1.id) < parseInt(o2.id)) return 1;
    else return 0;
  });
};

export function calculateTotalTax({
  tax1,
  tax2,
  tax3,
}: {
  tax1: number;
  tax2: number;
  tax3: number;
}) {
  var res = +tax1 + +tax2 + +tax3;

  return res;
}

export const getOrderStatus = (order: Order) => {
  if (
    (order.paymentType === PAYMENT_TYPE.CARD ||
      order.paymentType === PAYMENT_TYPE.CHECK) &&
    order.status === STATUS.READY
  )
    return STATUS.PENDING;

  if (order.status === STATUS.CLEANING) return STATUS.READY;

  if (order.status === STATUS.READY) return STATUS.COMPLETED;

  return STATUS.CLEANING;
};

export const calculateTitleForOrderStatus = (status: STATUS) => {
  switch (status) {
    case STATUS.CLEANING:
      return "Picked up completed";
    case STATUS.READY:
      return "Order in progress";
    case STATUS.COMPLETED:
      return "Ready for Delivery";
    case STATUS.PENDING:
      return "Delivery Scheduled";
    default:
      return "Unknown Status";
  }
};

export const getSlotFromTime = (time: string) => {
  const timeArr = time.split("-");
  timeArr.map((t) => {
    t.replace("am", "");
    t.replace("pm", "");
    return t;
  });
  const startTime = parseInt(timeArr[0]);
  const endTime = parseInt(timeArr[1]);
  const slot = availableSlots.find(
    (s) => s.startTime === startTime && s.endTime === endTime
  );
  if (!slot) return availableSlots[0];
  else return slot;
};
