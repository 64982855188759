import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateOrderDetails,
  Order,
  OrderState,
  RescheduleOrderDetails,
} from "./order.types";

const initialState: OrderState = {
  createOrderDetails: {},
  rescheduleOrderDetails: {},
};

export const orderSlice = createSlice({
  initialState,
  name: "order",
  reducers: {
    setOrders: (state: OrderState, action: PayloadAction<Order[]>) => {
      state.orders = action.payload;
    },
    resetOrders: (state: OrderState) => {
      state.orders = undefined;
    },
    setSelectedOrder: (state: OrderState, action: PayloadAction<Order>) => {
      state.selectedOrder = action.payload;
    },
    resetSelectedOrder: (state: OrderState) => {
      state.selectedOrder = undefined;
    },
    setCreateOrderDetails: (
      state: OrderState,
      action: PayloadAction<Partial<CreateOrderDetails>>
    ) => {
      state.createOrderDetails = action.payload;
    },
    resetCreateOrderDetails: (state: OrderState) => {
      state.createOrderDetails = {};
    },
    setRescheduleOrderDetails: (
      state: OrderState,
      action: PayloadAction<Partial<RescheduleOrderDetails>>
    ) => {
      state.rescheduleOrderDetails = action.payload;
    },
    resetRescheduleOrderDetails: (state: OrderState) => {
      state.rescheduleOrderDetails = {};
    },
  },
});

export const {
  resetOrders,
  setOrders,
  resetSelectedOrder,
  setSelectedOrder,
  resetCreateOrderDetails,
  setCreateOrderDetails,
  resetRescheduleOrderDetails,
  setRescheduleOrderDetails,
} = orderSlice.actions;
