import { useQuery } from "@tanstack/react-query";
import { APIService } from "../../../../utils/api.service";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Address, Location } from "../address.type";
import {
  setCustomerAddresses,
  setServiceableLocations,
} from "../address.slice";
import { nonChangingOneYear } from "../../../../config/query.config";

const api = new APIService();

export const useGetAllLocations = () => {
  const dispatch = useAppDispatch();
  return useQuery(
    ["address/location"],
    () =>
      api.get<Location[]>({
        url: "location",
      }),
    {
      onSuccess: (locations) => {
        dispatch(setServiceableLocations(locations));
      },
    }
  );
};

export const useGetAllAddressForCustomer = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  return useQuery(
    ["address/getAll"],
    () =>
      api.get<Address[]>({
        url: "address",
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      ...nonChangingOneYear,
      enabled: !!customer,
      onSuccess: (addresses) => {
        let formattedAddresses = [];
        //* Format the address
        formattedAddresses = addresses.map((item) => {
          let a = { ...item };
          a.latitude = parseFloat(a.latitude.toString());
          a.longitude = parseFloat(a.longitude.toString());
          return a;
        });
        dispatch(setCustomerAddresses(formattedAddresses));
      },
    }
  );
};
