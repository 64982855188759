import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { groupBy } from "lodash";
import { useState } from "react";
import Appbar from "../../components/appbar/appbar";
import Bottombar from "../../components/appbar/bottom_bar";
import { FaqData, FaqModel } from "../../constants/faq";
import { PRIMARY_COLOR } from "../../constants/theme";

function Faq() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [faqList] = useState<FaqModel[]>(FaqData.data);
  const grouped = groupBy(faqList, (item) => item.type);

  return (
    <div>
      <Appbar currentTabIndex={0} />
      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div style={{ margin: "12px" }}>
          {Object.keys(grouped).map((key) => (
            <div key={key}>
              <Typography
                variant="h6"
                sx={{ my: "15px", color: PRIMARY_COLOR }}
              >
                {key}
              </Typography>
              {grouped[key].map((item) => {
                return (
                  <Accordion sx={{ marginY: "15px" }}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {item.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {item.answer.split("/n").map((ans: string) => {
                          return (
                            <p>
                              <p>{ans}</p>
                              <br />
                            </p>
                          );
                        })}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
              {/* Homes in {key}: {grouped[key].join(", ")} */}
            </div>
          ))}
        </div>
        <Box height="100px" />
      </Box>
      {matches && <Bottombar currentTabIndex={0} />}
    </div>
  );
}

export default Faq;
