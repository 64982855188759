import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";

interface OrderNotesDialogProps {
  notesDialogOpen: boolean;
  setNotesDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (notes: string) => void;
  orderLoading: boolean;
}

function OrderNotesDialog({
  notesDialogOpen,
  setNotesDialogOpen,
  handleSubmit,
  orderLoading,
}: OrderNotesDialogProps) {
  const [notes, setNotes] = useState<string>("");

  return (
    <Dialog open={notesDialogOpen}>
      <DialogTitle>Do you have any specific instructions?</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="notes"
          label="Add a note"
          type="text"
          fullWidth
          variant="standard"
          value={notes}
          onChange={(event) => {
            setNotes(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setNotesDialogOpen(false)}>Cancel</Button>
        {orderLoading ? (
          <CircularProgress />
        ) : (
          <Button onClick={() => handleSubmit(notes)}>Submit</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default OrderNotesDialog;
