import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Appbar from "../../components/appbar/appbar";
import Bottombar from "../../components/appbar/bottom_bar";
import { useErrorSnackbar } from "../../hooks/error_snack";
import { useGetAllOffers } from "../../redux/features/offers/api/query";
import { Offer } from "../../redux/features/offers/offers.types";
import { useValidateCoupon } from "../../redux/features/order/api/mutation";
import { setCreateOrderDetails } from "../../redux/features/order/order.slice";
import { useAppDispatch, useAppSelector } from "../../redux/store";

function OfferDetails() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const { offers } = useAppSelector((state) => state.offers);
  const { isLoading: isOffersLoading } = useGetAllOffers();

  const { offerId } = useParams();
  let offerIdNumber: number | undefined = undefined;
  if (offerId) offerIdNumber = parseInt(offerId);

  const [selectedOffer, setSelectedOffer] = useState<Offer | undefined>(
    undefined
  );
  useEffect(() => {
    if (offerIdNumber && offers) {
      const foundItem = offers.find((item) => item?.id === offerIdNumber);
      if (foundItem) setSelectedOffer(foundItem);
    }
  }, [offerIdNumber, offers]);

  const { createOrderDetails } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();

  const {
    isLoading: validateCouponLoading,
    data: isCouponValid,
    isSuccess: validateCouponCompleted,
    isError: validateCouponFailed,
    mutate: validateCoupon,
    reset: resetValidateCoupon,
  } = useValidateCoupon();

  const handleCouponTextChanged = (code: string | null | undefined) => {
    dispatch(
      setCreateOrderDetails({
        ...createOrderDetails,
        couponText: code ?? "",
      })
    );
  };

  const handleRemoveCoupon = () =>
    dispatch(
      setCreateOrderDetails({
        ...createOrderDetails,
        couponText: "",
        isCouponApplied: false,
      })
    );

  const handleVerifyCoupon = (code: string | undefined) =>
    code?.trim().length &&
    validateCoupon({
      couponCode: code,
    });

  useErrorSnackbar({
    isError: validateCouponFailed,
    error: "Unable to use the coupon",
  });

  useEffect(() => {
    if (validateCouponFailed) handleRemoveCoupon();
  }, [validateCouponFailed]);

  useEffect(() => {
    if (validateCouponCompleted) {
      if (isCouponValid) {
        dispatch(
          setCreateOrderDetails({
            ...createOrderDetails,
            isCouponApplied: true,
          })
        );
        navigate("/order/create");
      } else {
        dispatch(
          setCreateOrderDetails({
            ...createOrderDetails,
            isCouponApplied: false,
          })
        );
      }
      resetValidateCoupon();
    }
  }, [
    createOrderDetails,
    dispatch,
    isCouponValid,
    resetValidateCoupon,
    validateCouponCompleted,
    navigate,
  ]);

  return (
    <div>
      <Appbar currentTabIndex={0} />
      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        {isOffersLoading || !selectedOffer ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Box>
            <Box textAlign="center">
              <Box
                component="img"
                sx={{ paddingX: "10px", cursor: "pointer" }}
                src={(process.env.REACT_APP_API_URL ?? "") + selectedOffer.url}
                width={matches ? "75vw" : "50vw"}
              />
            </Box>
            <Box sx={{ p: "10px" }}></Box>
            <Typography sx={{ fontWeight: "bold" }}>Title</Typography>
            <Typography>{selectedOffer.title}</Typography>
            <Box height="20px" />
            <Typography sx={{ fontWeight: "bold" }}>Description</Typography>
            <Typography>{selectedOffer.description}</Typography>
            <Box height="20px" />
            <Typography sx={{ fontWeight: "bold" }}>Valid Upto</Typography>
            <Typography>{selectedOffer.expiry}</Typography>
            <Box height="20px" />
            <Typography sx={{ fontWeight: "bold" }}>Code</Typography>
            <Typography>{selectedOffer.code}</Typography>
            <Box height="100px" />
          </Box>
        )}
      </Box>

      <Fab
        sx={{
          margin: 0,
          left: "0",
          right: "0",
          width: matches ? "30%" : "10%",
          marginLeft: "auto",
          marginRight: "auto",
          bottom: matches ? "80px" : "20px",
          position: "fixed",
          paddingX: "40px",
          color: "white",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.main",
          },
          borderRadius: "15px",
        }}
        variant="extended"
        disableRipple
        onClick={() => {
          if (!validateCouponLoading) {
            handleCouponTextChanged(selectedOffer?.code);
            handleVerifyCoupon(selectedOffer?.code);
          }
        }}
      >
        {validateCouponLoading ? "Loading" : "Book"}
      </Fab>
      <Box sx={{ height: "250px" }}></Box>
      {matches && <Bottombar currentTabIndex={0} />}
    </div>
  );
}

export default OfferDetails;
