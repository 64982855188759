import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIService } from "../../../../utils/api.service";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Address, LocationStage } from "../address.type";
import { setDefaultCustomerAddress, setLocationStage } from "../address.slice";

const api = new APIService();

export const useGetLocationFromCoordinates = () => {
  return useMutation(
    ["address/getLocationFromCoordinates"],
    ({ latitude, longitude }: { latitude: number; longitude: number }) => {
      return api.getGoogleMap({
        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}`,
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
      });
    },
    {
      onError: (error) => {
        return "Unknown address";
      },
    }
  );
};

export const useValidateLocation = () => {
  const dispatch = useAppDispatch();
  return useMutation(
    ["address/location/validate"],
    ({ latitude, longitude }: { latitude: number; longitude: number }) =>
      api.post<boolean>({
        url: "location/validate",
        data: {
          latitude,
          longitude,
        },
      }),
    {
      onSuccess: (isValid) => {
        dispatch(
          setLocationStage(
            isValid
              ? LocationStage.LOCATION_SERVICEABLE
              : LocationStage.LOCATION_NOT_SERVICEABLE
          )
        );
      },
    }
  );
};

export const useSetCustomerDefaultAddress = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { customer } = useAppSelector((s) => s.customer);
  return useMutation(
    ["address/setCurrent"],
    ({ address }: { address: Address }) =>
      api.post<string>({
        url: `address/setCurrent/${address.id}`,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: (_, { address }) => {
        dispatch(setDefaultCustomerAddress(address));
        queryClient.invalidateQueries(["customer/me"]);
      },
    }
  );
};

export const useAddCustomerAddress = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { customer } = useAppSelector((s) => s.customer);
  return useMutation(
    ["address/addAddress"],
    ({ address }: { address: Partial<Address> }) =>
      api.post<Address>({
        url: `address`,
        data: {
          latitude: address.latitude,
          longitude: address.longitude,
          line1: address.line1,
          line2: address.line2,
          tag: address.tag,
        },
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: (address) => {
        const formattedAddress = { ...address };
        //* Format the address
        formattedAddress.latitude = parseFloat(
          formattedAddress.latitude.toString()
        );
        formattedAddress.longitude = parseFloat(
          formattedAddress.longitude.toString()
        );

        dispatch(setDefaultCustomerAddress(formattedAddress));
        queryClient.invalidateQueries(["address/getAll"]);
        queryClient.invalidateQueries(["customer/me"]);
      },
    }
  );
};

export const useEditCustomerAddress = () => {
  const { customer } = useAppSelector((s) => s.customer);
  const queryClient = useQueryClient();

  return useMutation(
    ["address/editAddress"],
    ({ addressId, address }: { address: Address; addressId: number }) =>
      api.put<Address>({
        url: `address/${addressId}`,
        data: {
          latitude: address.latitude,
          longitude: address.longitude,
          line1: address.line1,
          line2: address.line2,
          tag: address.tag,
        },
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["address/getAll"]);
      },
    }
  );
};

export const useDeleteCustomerAddress = () => {
  const { customer } = useAppSelector((s) => s.customer);
  const queryClient = useQueryClient();

  return useMutation(
    ["address/deleteAddress"],
    ({ addressId }: { addressId: number }) =>
      api.delete<string>({
        url: `address/${addressId}`,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["customer/me"]);
        queryClient.invalidateQueries(["address/getAll"]);
      },
    }
  );
};
