import { Box, Card, Typography } from "@mui/material";
import SafetyIcon1 from "../../assets/temp_checker.svg";
import SafetyIcon2 from "../../assets/santizer.svg";
import SafetyIcon3 from "../../assets/mask.svg";
import SafetyItem from "./safety_item";

function SafetyCard() {
  return (
    <Box sx={{ backgroundColor: "#e9e8e8", height: "155px" }}>
      <Typography
        sx={{ fontWeight: "bold", fontSize: "18px", padding: "10px 0 0 20px" }}
      >
        Our safety & hygiene measures
      </Typography>
      <Card
        sx={{
          flexGrow: "1",
          borderRadius: "5",
          textAlign: "center",
          boxShadow: "none",
          padding: "0px",
          height: "120px",
          backgroundColor: "#e9e8e8",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <SafetyItem title1="Regular" title2="Temp. Check" icon={SafetyIcon1} />
        <SafetyItem
          title1="Mandatory"
          title2="Sanitization"
          icon={SafetyIcon2}
        />
        <SafetyItem title1="Maximum" title2="Protection" icon={SafetyIcon3} />
      </Card>
    </Box>
  );
}

export default SafetyCard;
