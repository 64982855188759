import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Appbar from "../../components/appbar/appbar";
import { useGetProductsForService } from "../../redux/features/product/api/query";
import { setProducts } from "../../redux/features/product/product.slice";
import { useGetAllServices } from "../../redux/features/service/api/query";
import { setCurrentServiceId } from "../../redux/features/service/service.slice";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import BottomBar from "../../components/appbar/bottom_bar";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { Product } from "../../redux/features/product/product.types";
import PricingItem from "../../components/pricing/pricing_item";
import { setCreateOrderDetails } from "../../redux/features/order/order.slice";

function Pricing() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { services, currentServiceId } = useAppSelector((s) => s.service);
  const { products } = useAppSelector((s) => s.product);
  const [searchProducts, setSearchProducts] = useState<Product[]>([]);
  const [searchProductsText, setSearchProductsText] = useState<string>("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { createOrderDetails } = useAppSelector((s) => s.order);

  const { isLoading: isServiceLoading } = useGetAllServices();
  const { isLoading: isProductsLoading, data: productData } =
    useGetProductsForService();

  const onServiceClicked = (event: React.SyntheticEvent, index: number) => {
    if (services) {
      dispatch(setCurrentServiceId(services[index].id));
      resetSearchDetails();
    }
  };

  useEffect(() => {
    if (services?.length && !currentServiceId)
      dispatch(setCurrentServiceId(services[0].id));
  }, [currentServiceId, dispatch, services]);

  useEffect(() => {
    //* This is done because when the product is retrieved from cache
    //* then onSuccess is not called in react-query. Hence no updates to state
    if (productData) dispatch(setProducts(productData));
  }, [productData, dispatch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setSearchProductsText(event.target.value);
      handleSearchAction();
    } else {
      resetSearchDetails();
    }
  };

  const handleSearchAction = () => {
    if (searchProductsText && searchProductsText.length > 0) {
      var filtered_products = products?.filter(function (prod) {
        return prod.name.toLowerCase().match(searchProductsText.toLowerCase());
      });

      if (filtered_products) {
        setSearchProducts(filtered_products);
      } else {
        setSearchProducts([]);
      }
    } else {
      setSearchProducts([]);
    }
  };

  const resetSearchDetails = () => {
    setSearchProductsText("");
    setSearchProducts([]);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSearchAction();
    }
  };

  const handleBookButton = () => {
    var selSer = services?.find((e) => e.id === currentServiceId);
    if (selSer) {
      if (!createOrderDetails.addedServices) {
        dispatch(
          setCreateOrderDetails({
            ...createOrderDetails,
            addedServices: [selSer],
          })
        );
      } else {
        const i = createOrderDetails.addedServices.findIndex(
          (s) => s.id === selSer?.id
        );
        let newList = [...createOrderDetails.addedServices];

        if (i === -1) newList.push(selSer);

        dispatch(
          setCreateOrderDetails({
            ...createOrderDetails,
            addedServices: newList,
          })
        );
      }
    }

    navigate("/order/create");
  };

  return (
    <div>
      <Appbar currentTabIndex={1} isAddressbarShown={true} />
      <Box sx={{ backgroundColor: "white" }}>
        {isServiceLoading ? (
          <div />
        ) : (
          services && (
            <Tabs
              value={
                currentServiceId
                  ? services
                      .map((i) => {
                        return i.id;
                      })
                      .indexOf(currentServiceId)
                  : 0
              }
              onChange={onServiceClicked}
              variant={matches ? "scrollable" : "fullWidth"}
              scrollButtons="auto"
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              {services?.map((item, index) => {
                return <Tab key={index} label={item.title}></Tab>;
              })}
            </Tabs>
          )
        )}
        {isProductsLoading ? (
          <div />
        ) : (
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px",
              flex: "1",
              textAlign: "center",
              backgroundColor: "#f5f5f5",
            }}
          >
            <InputBase
              sx={{ m: 1, flex: 1, backgroundColor: "#f5f5f5" }}
              placeholder="Search Items"
              inputProps={{ "aria-label": "Search Items" }}
              onChange={handleSearchChange}
              value={searchProductsText}
              onKeyDown={handleKeyDown}
            />
            {searchProductsText && searchProductsText.length > 0 && (
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={resetSearchDetails}
              >
                <MdCancel />
              </IconButton>
            )}
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
              onClick={handleSearchAction}
            >
              <AiOutlineSearch />
            </IconButton>
          </Paper>
        )}

        {isProductsLoading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : products && searchProducts.length === 0 ? (
          <ul>
            {products.map((product, index, _) => {
              return (
                <div key={index}>
                  <PricingItem
                    services={services}
                    currentServiceId={currentServiceId}
                    product={product}
                  />
                </div>
              );
            })}
          </ul>
        ) : (
          <ul>
            {searchProducts.map((product, index, _) => {
              return (
                <div key={index}>
                  <PricingItem
                    services={services}
                    currentServiceId={currentServiceId}
                    product={product}
                  />
                </div>
              );
            })}
          </ul>
        )}
        <Box sx={{ height: "100px" }}></Box>
      </Box>
      <Fab
        sx={{
          margin: 0,
          top: "auto",
          right: "20px",
          bottom: matches ? "80px" : "20px",
          left: "auto",
          position: "fixed",
          paddingX: "40px",
          color: "white",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.main",
          },
          borderRadius: "15px",
        }}
        variant="extended"
        disableRipple
        onClick={handleBookButton}
      >
        Book
      </Fab>
      {matches && <BottomBar currentTabIndex={1} />}
    </div>
  );
}

export default Pricing;
