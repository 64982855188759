import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BiDownArrow } from "react-icons/bi";
import { Product } from "../../redux/features/product/product.types";
import { Service } from "../../redux/features/service/service.types";

const GenerateTitleFromProduct = (product: Product): string => {
  switch (product.title.toLowerCase()) {
    case "saree":
      return product.title + " (" + product.name + ")";

    case "bag repair":
      return product.title + " (" + product.name + ")";

    case "shoe repair":
      return product.title + " (" + product.name + ")";

    case "bag cleaning":
      return product.title + " (" + product.name + ")";

    case "shoes cleaning":
      return product.title + " (" + product.name + ")";

    default:
      return product.name;
  }
};

function PricingItem({
  services,
  currentServiceId,
  product,
}: {
  services: Service[] | undefined;
  currentServiceId: number | undefined;
  product: Product;
}) {
  return (
    <Accordion sx={{ margin: "10px" }}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={<BiDownArrow />}
      >
        {services
          ?.find((x) => x.id === currentServiceId)
          ?.title.toLowerCase() === "laundry by kg" ? (
          <Typography>{product.name}</Typography>
        ) : (
          <Typography>{GenerateTitleFromProduct(product)}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table
            sx={{ width: "300px" }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Price (Rs)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {product.subtitles.map((subtitle, index2, _) => {
                return (
                  <TableRow
                    key={index2 + subtitle}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell align="left">
                      {services
                        ?.find((x) => x.id === currentServiceId)
                        ?.title.toLowerCase() === "laundry by kg"
                        ? "One Kg"
                        : subtitle}
                    </TableCell>
                    <TableCell align="left">{product.price[index2]}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default PricingItem;
