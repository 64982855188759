import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

function DismissAction(key: any) {
  const { closeSnackbar } = useSnackbar();

  return (
    <>
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
        sx={{ color: "white" }}
      >
        Dismiss
      </Button>
    </>
  );
}

export default DismissAction;
