import { addDays } from "date-fns";

export function formatDateForOrder(date: Date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getAmPm(hour: number): string {
  let time: string = `${hour % 12}`;
  time += hour > 12 ? "pm" : "am";
  return time;
}

export const getDefaultDate = (): Date => {
  const now = new Date();
  if (now.getHours() > 19) return addDays(now, 1);
  else return now;
};
