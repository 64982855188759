import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Appbar from "../../components/appbar/appbar";
import DismissAction from "../../components/common/dismiss_action";
import { useErrorSnackbar } from "../../hooks/error_snack";
import { useUpdateUser } from "../../redux/features/customer/api/mutation";
import { useAppSelector } from "../../redux/store";
import { PaperWrapper } from "../auth/complete/complete_profile.style";
import BottomBar from "../../components/appbar/bottom_bar";
import PlaceholderImage from "../../assets/profile_update_bg.png";

function EditProfile() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { customer } = useAppSelector((s) => s.customer);

  const navigate = useNavigate();

  const {
    isLoading: updateLoading,
    isError: updateIsError,
    error: updateError,
    mutate: updateUser,
    isSuccess: isUpdateSuccess,
  } = useUpdateUser();

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      name: customer ? customer.name : "",
      email: customer ? customer.email : "",
    },
    onSubmit: ({ email, name }) => {
      if (customer) {
        updateUser({ email, name });
      }
    },
  });

  useErrorSnackbar({ error: updateError, isError: updateIsError });

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (isUpdateSuccess) {
      enqueueSnackbar("Profile Updated Successfully", {
        variant: "success",
        action: DismissAction,
      });
      navigate(-1);
    }
  }, [enqueueSnackbar, isUpdateSuccess, navigate]);

  return (
    <div>
      <Appbar currentTabIndex={2} />
      <Grid
        sx={{ minHeight: matches ? "55vh" : "75vh" }}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <PaperWrapper elevation={3}>
          <Typography
            textAlign="center"
            sx={{ fontWeight: "bold", fontSize: "1.6rem" }}
          >
            Edit Profile
          </Typography>
          <form onSubmit={handleSubmit} style={{ marginTop: "10px" }}>
            <TextField
              required
              id="name"
              label="Name"
              variant="standard"
              sx={{ minWidth: "100%" }}
              value={values.name}
              onChange={handleChange}
            />
            <TextField
              required
              id="email"
              label="Email"
              type="email"
              variant="standard"
              sx={{ minWidth: "100%", marginTop: "10px" }}
              value={values.email}
              onChange={handleChange}
            />
            <Box textAlign="center" sx={{ marginTop: "20px" }}>
              {updateLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={!values.name || !values.email}
                  type="submit"
                  value="complete"
                  variant="contained"
                  sx={{ width: "70%" }}
                >
                  Complete
                </Button>
              )}
            </Box>
          </form>
        </PaperWrapper>
      </Grid>
      <Box
        component="img"
        sx={{
          margin: 0,
          right: "20px",
          width: matches ? "60%" : "25%",
          bottom: matches ? "62px" : "20px",
          position: "fixed",
          zIndex: "-1",
        }}
        src={PlaceholderImage}
      ></Box>
      <Box sx={{ height: "100px" }}></Box>

      {matches && <BottomBar currentTabIndex={2} />}
    </div>
  );
}

export default EditProfile;
