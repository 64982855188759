import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { HiLocationMarker } from "react-icons/hi";
import Appbar from "../../../components/appbar/appbar";
import { useSetOrderId } from "../../../hooks/order_id";
import { useGetAllOrders } from "../../../redux/features/order/api/query";
import { useAppSelector } from "../../../redux/store";
import { calculateTotalTax } from "../../../utils/order.util";

function OngoingOrderSummary() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedOrder } = useAppSelector((s) => s.order);
  const { customerAddresses, defaultCustomerAddress } = useAppSelector(
    (s) => s.address
  );
  const {
    isLoading: getAllOrdersLoading,
    isError: hasGetAllOrdersError,
    error: getAllOrdersError,
  } = useGetAllOrders();

  //* Hook to set the selected order when it is not available from redux store
  useSetOrderId();
  return (
    <div>
      <Appbar currentTabIndex={2} />
      <Box sx={{ backgroundColor: "white", margin: "20px 10px 0px 10px" }}>
        {getAllOrdersLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : hasGetAllOrdersError ? (
          <div>{getAllOrdersError}</div>
        ) : !selectedOrder ? (
          <div>Order not found</div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Box
                sx={{
                  display: "inline-flex",
                  width: matches ? "85vw" : "50vw",
                }}
              >
                <Card
                  elevation={0}
                  sx={{
                    m: "12px",
                    width: matches ? "85vw" : "50vw",
                    p: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 0px 5px 0px",
                    }}
                  >
                    <Typography
                      sx={{ color: "#00C4CC", display: "flex", width: "100%" }}
                    >
                      INVOICE
                    </Typography>
                    <Typography sx={{ color: "gray", alignSelf: "center" }}>
                      #{selectedOrder.id}
                    </Typography>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        verticalAlign: "top",
                        display: "inline-block",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "2px",
                      }}
                    >
                      <HiLocationMarker size={20} color="#000000" />
                    </div>
                    <Box
                      sx={{
                        marginLeft: "5px",
                        textAlign: "start",
                      }}
                    >
                      <Typography>Address</Typography>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        {!selectedOrder.address ||
                        selectedOrder.address === "Unknown"
                          ? !defaultCustomerAddress
                            ? customerAddresses
                              ? customerAddresses[0].line1
                              : "Unknown"
                            : defaultCustomerAddress.line1 ?? "Unknown"
                          : selectedOrder?.address}
                      </Typography>
                    </Box>
                  </Box>
                  <Card sx={{ my: "10px" }}>
                    <Table
                      sx={{ width: "100%", mt: "5px" }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell width="10px">#</TableCell>
                          <TableCell align="left">Item</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedOrder.summary
                          .split("<br>")
                          .filter((item) => item.length > 0)
                          .map((s, index) => {
                            let finalItem = s.split(" x ");
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  border: "0",
                                }}
                              >
                                <TableCell align="left" sx={{ color: "gray" }}>
                                  {index + 1}
                                </TableCell>
                                <TableCell align="left" sx={{ color: "gray" }}>
                                  {finalItem[0]}
                                </TableCell>
                                <TableCell align="right" sx={{ color: "gray" }}>
                                  {finalItem[1]}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Card>
                  <Card sx={{ my: "10px" }}>
                    <Table
                      sx={{ width: "100%", mt: "15px" }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">Sub Total</TableCell>
                          <TableCell
                            align="right"
                            style={{ fontWeight: "bold" }}
                          >
                            Rs.{" "}
                            {(selectedOrder.total ?? 0) -
                              calculateTotalTax({
                                tax1: selectedOrder.tax1 ?? 0,
                                tax2: selectedOrder.tax2 ?? 0,
                                tax3: selectedOrder.tax3 ?? 0,
                              })}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">GST</TableCell>
                          <TableCell
                            align="right"
                            style={{ fontWeight: "bold" }}
                          >
                            Rs.{" "}
                            {calculateTotalTax({
                              tax1: selectedOrder.tax1 ?? 0,
                              tax2: selectedOrder.tax2 ?? 0,
                              tax3: selectedOrder.tax3 ?? 0,
                            })}
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Total amount
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              fontWeight: "bold",
                              color: "#00C4CC",
                              fontSize: "16px",
                            }}
                          >
                            <div>Rs. {selectedOrder.total}</div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box sx={{ height: "100px" }}></Box>
    </div>
  );
}

export default OngoingOrderSummary;
