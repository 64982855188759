import { Avatar, Box, Typography } from "@mui/material";
import { FaArrowRight } from "react-icons/fa";

function CategoryItem({
  title,
  title2,
  icon,
  isBlueGradient,
  onClick,
}: {
  title: string;
  title2: string;
  icon: string;
  isBlueGradient: boolean;
  onClick?: any;
}) {
  return (
    <Box
      component="div"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      sx={{
        height: "120px",
        backgroundImage: isBlueGradient
          ? "linear-gradient(to bottom right, #C4DDFE, #DDE8FF, #FFFFFC)"
          : "linear-gradient(to bottom right, #FED8F7, #FFECFB, #FFFFFC)",
        borderRadius: "10px",
        position: "relative",
        padding: "10px",
        cursor: "pointer",
      }}
    >
      <Typography>{title}</Typography>
      <Typography>{title2}</Typography>

      <Avatar sx={{ backgroundColor: "white", height: "30px", width: "30px" }}>
        <FaArrowRight color="black" />
      </Avatar>

      <Box
        component="img"
        sx={{
          height: { xs: 50, md: 50 },
          width: { xs: 50, md: 50 },
          padding: "10px",
          position: "absolute",
          bottom: "10px",
          right: "10px",
        }}
        src={icon}
      />
    </Box>
  );
}

export default CategoryItem;
