import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Appbar from "../../components/appbar/appbar";
import BottomBar from "../../components/appbar/bottom_bar";
import DismissAction from "../../components/common/dismiss_action";
import AddressSelector from "../../components/order/create_order/address_selector";
import CouponSelector from "../../components/order/create_order/coupon_selector";
import DateSelector from "../../components/order/create_order/date_selector";
import ExpressDelivery from "../../components/order/create_order/express_delivery";
import OrderNotesDialog from "../../components/order/create_order/order_notes_dialog";
import ServiceSelector from "../../components/order/create_order/service_selector";
import TimeSlotSelector from "../../components/order/create_order/time_slot_selector";
import { useErrorSnackbar } from "../../hooks/error_snack";
import { useGetAllAddressForCustomer } from "../../redux/features/address/api/query";
import { useGetAllCoupons } from "../../redux/features/coupon/api/query";
import {
  useApplyCoupon,
  useCreateOrder,
} from "../../redux/features/order/api/mutation";
import { resetCreateOrderDetails } from "../../redux/features/order/order.slice";
import { ApplyCouponInput } from "../../redux/features/order/order.types";
import { useGetAllServices } from "../../redux/features/service/api/query";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { formatDateForOrder, getAmPm } from "../../utils/date.util";

function CreateOrder() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { isLoading: addressLoading } = useGetAllAddressForCustomer();
  const { isLoading: couponsLoading } = useGetAllCoupons();
  const { isLoading: serviceLoading } = useGetAllServices();
  const { createOrderDetails, selectedOrder } = useAppSelector((s) => s.order);
  const { defaultCustomerAddress } = useAppSelector((s) => s.address);
  const [notesDialogOpen, setNotesDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const {
    mutate: createOrder,
    reset: resetCreateOrder,
    isError: isCreateOrderError,
    error: createOrderError,
    isSuccess: isCreateOrderSuccess,
    isLoading: createOrderLoading,
  } = useCreateOrder();

  const {
    mutate: applyCoupon,
    reset: resetApplyCoupon,
    isError: isApplyCouponError,
    error: applyCouponError,
    isSuccess: isApplyCouponSuccess,
    isLoading: applyCouponLoading,
  } = useApplyCoupon();

  const handleSubmit = (notes: string) => {
    //* handle pre-checks for order
    if (createOrderDetails.couponText && !createOrderDetails.isCouponApplied) {
      enqueueSnackbar("Invalid coupon", {
        variant: "error",
        action: DismissAction,
      });
      setNotesDialogOpen(false);
      return;
    } else if (!defaultCustomerAddress) {
      enqueueSnackbar("Please select or add an address", {
        variant: "error",
        action: DismissAction,
      });
      setNotesDialogOpen(false);
      return;
    } else if (
      !createOrderDetails.pickupDate ||
      !createOrderDetails.selectedSlot
    ) {
      setNotesDialogOpen(false);
      return;
    }

    let customNotes =
      notes +
      " " +
      (createOrderDetails.addedServices != null &&
      createOrderDetails.addedServices.length > 0
        ? createOrderDetails.addedServices?.map((u) => u.title).join(", ")
        : "");

    createOrder({
      addressID: defaultCustomerAddress.id,
      isExpress: !!createOrderDetails.expressDeliveryChecked,
      pickupDate: formatDateForOrder(new Date(createOrderDetails.pickupDate)),
      pickupTimeStart: getAmPm(createOrderDetails.selectedSlot.startTime),
      pickupTimeEnd: getAmPm(createOrderDetails.selectedSlot.endTime),
      couponCode: createOrderDetails.couponText,
      notes: customNotes,
      services: createOrderDetails.addedServices?.map((s) => s.id),
    });
  };

  useEffect(() => {
    if (isCreateOrderSuccess) {
      if (
        createOrderDetails.isCouponApplied &&
        createOrderDetails.couponText &&
        selectedOrder
      ) {
        const inputPayload: ApplyCouponInput = {
          code: createOrderDetails.couponText,
          order_id: parseInt(selectedOrder.id),
        };

        applyCoupon({ applyCouponInput: inputPayload });
      } else {
        setNotesDialogOpen(false);
        resetCreateOrder();
        dispatch(resetCreateOrderDetails());
        navigate("/home", { replace: true });
        enqueueSnackbar("Order placed successfully", {
          variant: "success",
          action: DismissAction,
        });
      }
    }
    if (isCreateOrderError) {
      setNotesDialogOpen(false);
      resetCreateOrder();
    }
  }, [
    enqueueSnackbar,
    isCreateOrderError,
    isCreateOrderSuccess,
    navigate,
    resetCreateOrder,
  ]);

  useEffect(() => {
    if (isApplyCouponSuccess || isApplyCouponError) {
      setNotesDialogOpen(false);
      resetCreateOrder();
      dispatch(resetCreateOrderDetails());
      navigate("/home", { replace: true });
      enqueueSnackbar("Order placed successfully", {
        variant: "success",
        action: DismissAction,
      });
    }
  }, [isApplyCouponSuccess, isApplyCouponError]);

  useErrorSnackbar({
    isError: isCreateOrderError,
    error: createOrderError,
  });
  useErrorSnackbar({
    isError: isApplyCouponError,
    error: applyCouponError,
  });

  return (
    <div>
      <Appbar currentTabIndex={0} isAddressbarShown={true} />
      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        {addressLoading || couponsLoading || serviceLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Create an Order
              </Typography>
            </Grid>

            <DateSelector />

            <TimeSlotSelector
              selectedDate={
                createOrderDetails.pickupDate
                  ? new Date(createOrderDetails.pickupDate)
                  : new Date()
              }
            />

            <ExpressDelivery />

            <ServiceSelector />

            <CouponSelector />

            <AddressSelector />
          </Grid>
        )}
        <OrderNotesDialog
          handleSubmit={handleSubmit}
          notesDialogOpen={notesDialogOpen}
          setNotesDialogOpen={setNotesDialogOpen}
          orderLoading={createOrderLoading || applyCouponLoading}
        />
        <Fab
          sx={{
            margin: 0,
            left: "0",
            right: "0",
            width: matches ? "60%" : "25%",
            marginLeft: "auto",
            marginRight: "auto",
            bottom: matches ? "80px" : "20px",
            position: "fixed",
            paddingX: "40px",
            color: "white",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            borderRadius: "15px",
          }}
          variant="extended"
          disableRipple
          onClick={() => setNotesDialogOpen(true)}
        >
          Confirm Booking
        </Fab>
        <Box sx={{ height: "250px" }}></Box>
        {matches && <BottomBar currentTabIndex={1} />}
      </Box>
    </div>
  );
}

export default CreateOrder;
