import {
  Box,
  Fab,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Appbar from "../../components/appbar/appbar";
import BottomBar from "../../components/appbar/bottom_bar";
import OffersSection from "../../components/home/offer_item";
import SafetyCard from "../../components/common/safety_card";
import { useGetAllOffers } from "../../redux/features/offers/api/query";
import { setCurrentServiceId } from "../../redux/features/service/service.slice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import ReferCard from "../../components/common/refer_card";
import CategoryItem from "../../components/home/category_item";

import DryCleaningIcon from "../../assets/dry_cleaning.png";
import AccessoryCareIcon from "../../assets/accessory_care.png";
import LaundryByKgIcon from "../../assets/laundry_by_kg.png";
import IroningIcon from "../../assets/ironing.png";
import { Order, STATUS } from "../../redux/features/order/order.types";
import OngoingItem from "../../components/home/ongoing_item";
import { useGetAllOrders } from "../../redux/features/order/api/query";

function Home() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { services } = useAppSelector((s) => s.service);
  const { offers } = useAppSelector((s) => s.offers);
  const { orders } = useAppSelector((s) => s.order);

  const { isLoading: isOffersLoading } = useGetAllOffers();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onServiceClicked = (serviceId: number) => {
    dispatch(setCurrentServiceId(serviceId));
    navigate("/pricing");
  };

  const onOfferClicked = () => {
    navigate("/offers");
  };

  const [ongoingOrders, setOngoingOrders] = useState<Order[]>([]);
  useEffect(() => {
    if (orders && orders.length) {
      const filteredOrders = orders.filter(
        (o) => o.status !== STATUS.COMPLETED
      );
      setOngoingOrders(filteredOrders);
    }
  }, [orders]);

  const { refetch: refetchOrders, isLoading: isGetOrdersLoading } =
    useGetAllOrders();

  useEffect(() => {
    if (!isGetOrdersLoading) refetchOrders();
  }, []);

  return (
    <div>
      <Appbar currentTabIndex={0} isAddressbarShown={true} />
      <Box sx={{ backgroundColor: "white", margin: "20px 10px 0px 10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
              Our Services
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box sx={{ width: matches ? "90vw" : "48vw" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    sx={{
                      width: "50%",
                      // marginLeft: matches ? "0px" : "25vw",
                    }}
                  >
                    <CategoryItem
                      title="Dry"
                      title2="Cleaning"
                      icon={DryCleaningIcon}
                      isBlueGradient={true}
                      onClick={() => {
                        if (services) {
                          onServiceClicked(services[0].id);
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: "50%",
                    }}
                  >
                    <CategoryItem
                      title="Laundry"
                      title2="by KG"
                      icon={LaundryByKgIcon}
                      isBlueGradient={false}
                      onClick={() => {
                        if (services) {
                          onServiceClicked(services[1].id);
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: "50%",
                    }}
                  >
                    <CategoryItem
                      title="Steam"
                      title2="Iron"
                      icon={IroningIcon}
                      isBlueGradient={false}
                      onClick={() => {
                        if (services) {
                          onServiceClicked(services[2].id);
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: "50%",
                    }}
                  >
                    <CategoryItem
                      title="Accessory"
                      title2="Care"
                      icon={AccessoryCareIcon}
                      isBlueGradient={true}
                      onClick={() => {
                        if (services) {
                          onServiceClicked(services[3].id);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {ongoingOrders && ongoingOrders?.length > 0 && (
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Your Ongoing Order
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {ongoingOrders && ongoingOrders?.length > 0 && (
              <OngoingItem matches={matches} ongoingOrders={ongoingOrders} />
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
              Offers of the Day
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <OffersSection
              isLoading={isOffersLoading}
              offers={offers ?? []}
              onClick={onOfferClicked}
              matches={matches}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box sx={{ width: matches ? "90vw" : "48vw" }}>
                <SafetyCard />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box sx={{ width: matches ? "90vw" : "48vw" }}>
                <ReferCard />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ height: "200px" }} />
      </Box>

      <Fab
        sx={{
          margin: 0,
          top: "auto",
          right: "20px",
          bottom: matches ? "80px" : "20px",
          left: "auto",
          position: "fixed",
          paddingX: "40px",
          color: "white",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.main",
          },
          borderRadius: "15px",
        }}
        variant="extended"
        disableRipple
        onClick={() => {
          navigate("/order/create");
        }}
      >
        Book
      </Fab>

      {matches && <BottomBar currentTabIndex={0} />}
    </div>
  );
}

export default Home;
