import {
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import Appbar from "../../components/appbar/appbar";
import BottomBar from "../../components/appbar/bottom_bar";
import DismissAction from "../../components/common/dismiss_action";
import Map from "../../components/map/map";
import {
  resetLocationStage,
  setLocationStage,
} from "../../redux/features/address/address.slice";
import {
  Address,
  LocationStage,
} from "../../redux/features/address/address.type";
import {
  useEditCustomerAddress,
  useGetLocationFromCoordinates,
  useValidateLocation,
} from "../../redux/features/address/api/mutation";
import { useAppDispatch, useAppSelector } from "../../redux/store";

function AddressEdit() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { addressId } = useParams();
  const [currentAddress, setCurrentAddress] = useState<Address>();
  const { customerAddresses, locationStage } = useAppSelector((s) => s.address);
  const [label, setLabel] = useState<"home" | "work" | "others">("home");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isLoading: editLoading,
    isSuccess: editAddressSuccess,
    mutate: updateAddress,
  } = useEditCustomerAddress();
  const {
    data: coordinateLocation,
    isLoading: getCoordinatesLoading,
    mutate: getLocationFromCoordinates,
    reset: resetGetLocationFromCoordinates,
  } = useGetLocationFromCoordinates();

  const { mutate: validateLocation } = useValidateLocation();

  //* This useEffect runs once and set the currentAddress with the
  //* addressId from the param
  useEffect(() => {
    if (!currentAddress && addressId && customerAddresses) {
      const addr = customerAddresses.find((a) => a.id === parseInt(addressId));
      if (addr && addr.tag) {
        setCurrentAddress(addr);
        const lbl = addr.tag.toLowerCase();
        lbl === "home"
          ? setLabel("home")
          : lbl === "work"
          ? setLabel("work")
          : setLabel("others");

        dispatch(setLocationStage(LocationStage.LOCATION_SERVICEABLE));
      }
    }
  }, [addressId, currentAddress, customerAddresses, dispatch]);

  const { enqueueSnackbar } = useSnackbar();

  //* This useEffect will throw alert when the locationStage is unserviceable
  useEffect(() => {
    if (locationStage === LocationStage.LOCATION_NOT_SERVICEABLE) {
      enqueueSnackbar("Location not Servicable", {
        variant: "error",
        action: DismissAction,
      });
      dispatch(resetLocationStage());
    }
  }, [dispatch, locationStage, enqueueSnackbar]);

  //* This useEffect checks if latitude and longitude has changed or not. If yes
  //* then it fetches the latest line2 and updates the address and also validates it
  useEffect(() => {
    if (
      !currentAddress ||
      currentAddress.line2?.length ||
      getCoordinatesLoading
    )
      return;

    getLocationFromCoordinates({
      latitude: currentAddress.latitude,
      longitude: currentAddress.longitude,
    });

    if (coordinateLocation) {
      setCurrentAddress({
        ...currentAddress,
        line2: coordinateLocation,
      });

      resetGetLocationFromCoordinates();

      validateLocation({
        latitude: currentAddress.latitude,
        longitude: currentAddress.longitude,
      });
    }
  }, [
    coordinateLocation,
    currentAddress,
    getCoordinatesLoading,
    getLocationFromCoordinates,
    resetGetLocationFromCoordinates,
    validateLocation,
  ]);

  useEffect(() => {
    if (editAddressSuccess) {
      navigate(-1);
      enqueueSnackbar("Address Updated Successfully", {
        variant: "success",
        action: DismissAction,
      });
    }
  }, [editAddressSuccess, navigate, enqueueSnackbar]);

  function handleLine1Change(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    currentAddress &&
      setCurrentAddress({
        ...currentAddress,
        line1: val,
      });
  }

  function handleOthersChange(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    currentAddress &&
      setCurrentAddress({
        ...currentAddress,
        tag: val,
      });
  }

  function handleUpdateAddressClicked() {
    currentAddress &&
      addressId &&
      updateAddress({
        address: currentAddress,
        addressId: parseInt(addressId),
      });
  }

  function onCoordsChanged({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) {
    currentAddress &&
      setCurrentAddress({
        ...currentAddress,
        line2: "",
        latitude,
        longitude,
      });
  }

  return (
    <div>
      <Appbar currentTabIndex={0} isAddressbarShown={true} />

      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        <Map
          latitude={currentAddress?.latitude}
          longitude={currentAddress?.longitude}
          onCoordsChanged={onCoordsChanged}
        />

        <Box sx={{ mx: "15px", my: "20px" }}>
          <div
            style={{
              display: "inline-flex",
              verticalAlign: "middle",
              textAlign: "left",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HiLocationMarker color="#00C4CC" />
            {currentAddress?.line2 && <p>{currentAddress.line2}</p>}
          </div>

          <Typography sx={{ my: "14px", fontWeight: "bold" }}>
            Details
          </Typography>
          <Box>
            <TextField
              fullWidth
              required
              label="House, Appartment, Society"
              variant="standard"
              id="line1"
              name="line1"
              value={currentAddress ? currentAddress.line1 : ""}
              onChange={handleLine1Change}
            />
            <Box sx={{ my: "15px" }}>
              <Chip
                label="Home"
                color="secondary"
                onClick={() => {
                  setLabel("home");
                  currentAddress &&
                    setCurrentAddress({
                      ...currentAddress,
                      tag: "home",
                    });
                }}
                variant={label === "home" ? "filled" : "outlined"}
                sx={{ marginRight: "15px" }}
              />
              <Chip
                label="Work"
                color="secondary"
                onClick={() => {
                  setLabel("work");
                  currentAddress &&
                    setCurrentAddress({
                      ...currentAddress,
                      tag: "work",
                    });
                }}
                variant={label === "work" ? "filled" : "outlined"}
                sx={{ marginRight: "15px" }}
              />
              <Chip
                label="Other"
                color="secondary"
                onClick={() => {
                  setLabel("others");
                  currentAddress &&
                    setCurrentAddress({
                      ...currentAddress,
                      tag: "",
                    });
                }}
                variant={label === "others" ? "filled" : "outlined"}
              />
            </Box>
            {currentAddress && !["home", "work"].includes(label) && (
              <TextField
                label="Label"
                variant="standard"
                id="others"
                name="others"
                value={currentAddress.tag}
                onChange={handleOthersChange}
              />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              {" "}
              {editLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  sx={{ width: "100px" }}
                  disabled={
                    locationStage === LocationStage.LOCATION_SERVICEABLE
                      ? false
                      : true
                  }
                  onClick={handleUpdateAddressClicked}
                >
                  Save
                </Button>
              )}
            </div>
          </Box>
          <Box height="100px" />
        </Box>
      </Box>

      {matches && <BottomBar currentTabIndex={0} />}
    </div>
  );
}

export default AddressEdit;
