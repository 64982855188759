import { useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import {
  GoogleMap,
  Marker,
  Circle,
  Autocomplete,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useCallback, useEffect } from "react";
import { useGeolocated } from "react-geolocated";
import { libraries } from "../../constants/constants";
import MapError from "./map_error";
import MapLoading from "./map_loading";
import { MapCircleStyle } from "./map_styles";

export interface MapProps {
  latitude?: number;
  longitude?: number;
  onCoordsChanged?: ({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) => void;
}

function Map({ latitude, longitude, onCoordsChanged }: MapProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [autoCompleteData, setAutoCompleteData] =
    React.useState<google.maps.places.Autocomplete | null>(null);

  //* Geolocator Initialization
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  //* Map Initialization
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    libraries,
  });

  const onLoad = useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  //* Initial load coordinates check
  useEffect(() => {
    if (
      coords &&
      coords.latitude &&
      coords.longitude &&
      !latitude &&
      !longitude
    ) {
      onCoordsChanged &&
        onCoordsChanged({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
    }
  }, [coords]);

  //* On marker Drag
  const handleOnClickMap = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        const latitude = event.latLng.lat();
        const longitude = event.latLng.lng();
        onCoordsChanged &&
          onCoordsChanged({
            latitude,
            longitude,
          });
      }
    },
    [onCoordsChanged]
  );

  //* Autocomplete Initialization
  const handleSearchSelected = () => {
    if (autoCompleteData) {
      if (!onCoordsChanged) return;
      const searchedData = autoCompleteData.getPlace();

      if (coords && searchedData.name === "") {
        onCoordsChanged({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
      } else {
        searchedData.geometry?.location &&
          searchedData.geometry?.location &&
          onCoordsChanged({
            latitude: searchedData.geometry.location.lat(),
            longitude: searchedData.geometry.location.lng(),
          });
      }
    }
  };

  const onLoadAutoComplete = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    setAutoCompleteData(autocomplete);
  };

  return (
    <div>
      {!isGeolocationAvailable ? (
        <MapError title="Your browser does not support Location Services" />
      ) : !isGeolocationEnabled ? (
        <MapError title="Please enable location services" />
      ) : coords ? (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          {isMapLoaded ? (
            <GoogleMap
              mapContainerStyle={{
                height: "50vh",
                width: matches ? "100vw" : "75vw",
              }}
              center={{
                lat: latitude ?? coords.latitude,
                lng: longitude ?? coords.longitude,
              }}
              zoom={18}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={handleOnClickMap}
              options={{
                mapTypeControl: false,
                panControl: false,
                streetViewControl: false,
              }}
            >
              {map && (
                <Marker
                  position={{
                    lat: latitude ?? coords.latitude,
                    lng: longitude ?? coords.longitude,
                  }}
                  animation={google.maps.Animation.DROP}
                ></Marker>
              )}
              {map && (
                <Circle
                  center={{
                    lat: latitude ?? coords.latitude,
                    lng: longitude ?? coords.longitude,
                  }}
                  options={MapCircleStyle}
                />
              )}

              <Autocomplete
                onPlaceChanged={handleSearchSelected}
                onLoad={onLoadAutoComplete}
              >
                <input
                  type="text"
                  placeholder="Search Location"
                  style={{
                    boxSizing: `border-box`,
                    border: `3px solid black`,
                    width: `${matches ? "240px" : "350px"}`,
                    height: `40px`,
                    padding: `0 12px`,
                    borderRadius: `20px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: `${matches ? "50%" : "44%"}`,
                    marginLeft: "-120px",
                    zIndex: "100",
                    // backgroundColor: "#f5f5f5",
                  }}
                />
              </Autocomplete>
            </GoogleMap>
          ) : (
            <MapLoading title="Loading Maps" />
          )}
        </Box>
      ) : (
        <MapLoading title="Getting your Location" />
      )}
    </div>
  );
}

export default Map;
