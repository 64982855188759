import { Service } from "../service/service.types";

export type Slot = {
  startTime: number;
  endTime: number;
  slotDisplay: string;
};

export enum PAYMENT_TYPE {
  NO_PAYMENT = "No payment",
  CASH = "cash",
  CARD = "card",
  CHECK = "check",
}

export enum STATUS {
  PENDING = "pending",
  CLEANING = "cleaning",
  READY = "ready",
  COMPLETED = "completed",
  PICKED_UP = "picked up",
}

export type Order = {
  id: string;
  customerID: number;
  total: number;
  discount: number;
  tax1: number;
  tax2: number;
  tax3: number;
  summary: string;
  paid: boolean;
  paymentType: PAYMENT_TYPE;
  pieces: number;
  paymentTime: string;
  status: STATUS;
  deliveryDate: string;
  deliveryTime: string;
  pickupDate: string;
  pickupTime: string;
  notes: string;
  address: string;
  rating: number;
  services: string[];
};

export type OrderRaw = {
  id: string;
  customerID: string;
  total: string;
  discount: string;
  tax1: string;
  tax2: string;
  tax3: string;
  summary: string;
  paid: string;
  paymentType: string;
  pieces: string;
  paymentTime: string;
  status: string;
  deliveryDate: string;
  deliveryTime: string;
  pickupDate: string;
  pickupTime: string;
  notes: string;
  address: string;
  rating: number;
  services: string[];
};

export type CreateOrderInput = {
  isExpress: boolean;
  pickupDate: string;
  pickupTimeStart: string;
  pickupTimeEnd: string;
  notes?: string;
  addressID: number;
  services?: number[] | null;
  couponCode?: string;
};

export type ReschedulePickupInput = {
  pickupDate: string;
  pickupTimeStart: string;
  pickupTimeEnd: string;
};

export type RescheduleDeliveryInput = {
  deliveryDate: string;
  deliveryTimeStart: string;
  deliveryTimeEnd: string;
};

export type ApplyCouponInput = {
  code: string;
  order_id: number;
};

export type RateOrderInput = {
  rating: number;
  recommend: number;
  feedback: string;
};

export type CreateOrderDetails = {
  currentDate: string;
  pickupDate: string;
  selectedSlot: Slot;
  expressDeliveryChecked: boolean;
  addedServices: Service[];
  couponText: string;
  isCouponApplied: boolean;
};

export type RescheduleOrderDetails = {
  rescheduleDate: string;
  rescheduleSlot: Slot;
};

export type OrderState = {
  orders?: Order[];
  selectedOrder?: Order;
  createOrderDetails: Partial<CreateOrderDetails>;
  rescheduleOrderDetails: Partial<RescheduleOrderDetails>;
};
