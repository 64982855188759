import React from "react";

import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "../components/protected_route/protected_route";
import AddressAdd from "../pages/address/address_add";
import AddressEdit from "../pages/address/address_edit";
import AddressList from "../pages/address/address_list";
import CompleteProfile from "../pages/auth/complete/complete_profile";
import Login from "../pages/auth/login/login";
import Verify from "../pages/auth/verify/verify";
import Faq from "../pages/faq/faq";
import Help from "../pages/help/help";
import Home from "../pages/home/home";
import PrivacyPolicy from "../pages/legal/privacy_policy";
import TermsAndConditions from "../pages/legal/terms_and_conditions";
import Offer from "../pages/offer/offer";
import OfferDetails from "../pages/offer/offer_details";
import CreateOrder from "../pages/order/create_order";
import CompletedOrderDetails from "../pages/order/details/completed_order_details";
import OngoingOrderDetails from "../pages/order/details/ongoing_order_details";
import UpcomingOrderDetails from "../pages/order/details/upcoming_order_details";
import OrderList from "../pages/order/order_list";
import RescheduleDelivery from "../pages/order/reschedule/reschedule_delivery";
import ReschedulePickup from "../pages/order/reschedule/reschedule_pickup";
import OngoingOrderSummary from "../pages/order/summary/ongoing_summary";
import Pricing from "../pages/pricing/pricing";
import EditProfile from "../pages/profile/edit_profile";
import Profile from "../pages/profile/profile";
import Splash from "../pages/splash/splash";

function WebRoutes() {
  return (
    <Routes>
      <Route caseSensitive path="/" element={<ProtectedRoute />}>
        {/* <Route caseSensitive path="/map" element={<Map />} />
            <Route
              caseSensitive
              path="/unserviceable"
              element={<Unserviceable />}
            /> */}
        <Route caseSensitive path="/home" element={<Home />} />
        <Route caseSensitive path="/pricing" element={<Pricing />} />
        <Route caseSensitive path="/profile" element={<Profile />} />
        <Route caseSensitive path="/orders" element={<OrderList />} />
        <Route
          caseSensitive
          path="/orders/ongoing/:orderId"
          element={<OngoingOrderDetails />}
        />
        <Route
          caseSensitive
          path="/orders/upcoming/:orderId"
          element={<UpcomingOrderDetails />}
        />
        <Route
          caseSensitive
          path="/orders/completed/:orderId"
          element={<CompletedOrderDetails />}
        />
        <Route caseSensitive path="/order/create" element={<CreateOrder />} />
        <Route
          caseSensitive
          path="/orders/ongoing/:orderId/summary"
          element={<OngoingOrderSummary />}
        />
        <Route
          caseSensitive
          path="/orders/ongoing/:orderId/reschedule"
          element={<RescheduleDelivery />}
        />
        <Route
          caseSensitive
          path="/orders/upcoming/:orderId/reschedule"
          element={<ReschedulePickup />}
        />

        <Route caseSensitive path="/offers" element={<Offer />} />
        <Route
          caseSensitive
          path="/offers/:offerId"
          element={<OfferDetails />}
        />
        <Route caseSensitive path="/help" element={<Help />} />
        <Route caseSensitive path="/address" element={<AddressList />} />
        <Route caseSensitive path="/address/add" element={<AddressAdd />} />
        <Route
          caseSensitive
          path="/address/:addressId"
          element={<AddressEdit />}
        />
        <Route caseSensitive path="/editProfile" element={<EditProfile />} />
      </Route>
      <Route caseSensitive path="/verify" element={<Verify />} />
      <Route
        caseSensitive
        path="/complete_profile"
        element={<CompleteProfile />}
      />
      <Route caseSensitive path="/login" element={<Login />} />
      <Route
        caseSensitive
        path="/termsAndConditions"
        element={<TermsAndConditions />}
      />
      <Route caseSensitive path="/privacyPolicy" element={<PrivacyPolicy />} />
      <Route caseSensitive path="/faq" element={<Faq />} />
      <Route caseSensitive path="/splash" element={<Splash />} />
      <Route index element={<Splash />} />
      <Route path="*" element={<div>Not Found</div>} />
    </Routes>
  );
}

export default WebRoutes;
