import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Coupon, CouponState } from "./coupon.types";

const initialState: CouponState = {};

export const couponSlice = createSlice({
  initialState,
  name: "coupon",
  reducers: {
    setCoupons: (state: CouponState, action: PayloadAction<Coupon[]>) => {
      state.coupons = action.payload;
    },
    resetCoupons: (state: CouponState) => {
      state.coupons = undefined;
    },
    setAppliedCouponCode: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.appliedCouponCode = action.payload;
    },
    resetAppliedCouponCode: (state: CouponState) => {
      state.appliedCouponCode = undefined;
    },
  },
});

export const {
  resetCoupons,
  setCoupons,
  resetAppliedCouponCode,
  setAppliedCouponCode,
} = couponSlice.actions;
