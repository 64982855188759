import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Service, ServiceState } from "./service.types";

const initialState: ServiceState = {};

export const serviceSlice = createSlice({
  initialState,
  name: "service",
  reducers: {
    setServices: (state: ServiceState, action: PayloadAction<Service[]>) => {
      state.services = action.payload;
    },
    resetServices: (state: ServiceState) => {
      state.services = undefined;
    },
    setCurrentServiceId: (
      state: ServiceState,
      action: PayloadAction<number>
    ) => {
      state.currentServiceId = action.payload;
    },
    resetCurrentServiceId: (state: ServiceState) => {
      state.currentServiceId = undefined;
    },
  },
});

export const {
  resetServices,
  setServices,
  resetCurrentServiceId,
  setCurrentServiceId,
} = serviceSlice.actions;
