import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Appbar from "../../components/appbar/appbar";
import { Address } from "../../redux/features/address/address.type";
import BottomBar from "../../components/appbar/bottom_bar";
import {
  useDeleteCustomerAddress,
  useSetCustomerDefaultAddress,
} from "../../redux/features/address/api/mutation";
import { useGetAllAddressForCustomer } from "../../redux/features/address/api/query";
import { useAppSelector } from "../../redux/store";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi";

function AddressList() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  const { customerAddresses, defaultCustomerAddress } = useAppSelector(
    (s) => s.address
  );

  const { isLoading: addressLoading } = useGetAllAddressForCustomer();
  const {
    isLoading: settingDefaultAddress,
    mutate: setDefaultAddress,
    isSuccess: setDefaultAddressSuccess,
    reset: resetSetDefaultAddress,
  } = useSetCustomerDefaultAddress();

  const { mutate: deleteCustomerAddress } = useDeleteCustomerAddress();

  const navigate = useNavigate();

  const handleAddressClicked = (address: Address) => {
    if (defaultCustomerAddress && defaultCustomerAddress.id === address.id)
      return;
    setDefaultAddress({
      address,
    });
  };

  useEffect(() => {
    if (redirect === "1" && setDefaultAddressSuccess) {
      navigate(-1);
      resetSetDefaultAddress();
    }
  }, [navigate, redirect, resetSetDefaultAddress, setDefaultAddressSuccess]);

  return (
    <div>
      <Appbar currentTabIndex={0} isAddressbarShown={true} />
      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        {addressLoading || settingDefaultAddress ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Your Addresses
              </Typography>
            </Grid>

            <Grid item xs={12} textAlign="center">
              <Button
                variant="contained"
                onClick={() => {
                  navigate("add");
                }}
              >
                <Box sx={{ display: "inline-flex" }}>
                  <AiOutlinePlus />
                </Box>
                <Box sx={{ marginLeft: "8px", display: "inline-flex" }}>
                  Add Address
                </Box>
              </Button>
            </Grid>

            {customerAddresses ? (
              customerAddresses.map((addr, index) => {
                return (
                  <Grid item key={index} xs={12} md={3}>
                    <Box
                      component="div"
                      onClick={() => {
                        handleAddressClicked(addr);
                      }}
                    >
                      <Paper
                        elevation={1}
                        sx={{
                          padding: "12px",
                          position: "relative",
                          backgroundColor:
                            defaultCustomerAddress?.id === addr.id
                              ? "rgba(0, 197, 204,0.1)"
                              : "white",
                          border: "1px solid rgba(0, 197, 204,1)",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            verticalAlign: "middle",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <HiLocationMarker color="#00C4CC" />
                          <Typography
                            sx={{ paddingY: "2px", marginLeft: "5px" }}
                          >
                            {addr.tag}
                          </Typography>
                        </div>

                        <Divider />
                        <Typography sx={{ color: "gray", paddingY: "10px" }}>
                          {addr.line1}
                        </Typography>
                        <Divider />
                        <Box display="flex" sx={{ marginTop: "5px" }}>
                          <Button
                            variant="text"
                            sx={{ flexGrow: "1", color: "#02898E" }}
                            onClick={() => {
                              navigate(addr.id.toString());
                            }}
                          >
                            Edit
                          </Button>
                          <Box
                            sx={{
                              height: "40px",
                              width: "1px",
                              backgroundColor: "gray",
                            }}
                          ></Box>
                          <Button
                            variant="text"
                            sx={{ flexGrow: "1", color: "#02898E" }}
                            onClick={() => {
                              deleteCustomerAddress({ addressId: addr.id });
                            }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <div />
            )}
          </Grid>
        )}
        <Box height="100px" />
      </Box>
      {matches && <BottomBar currentTabIndex={0} />}
    </div>
  );
}

export default AddressList;
