import {
  Grid,
  CircularProgress,
  Typography,
  Fab,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Appbar from "../../../components/appbar/appbar";
import BottomBar from "../../../components/appbar/bottom_bar";
import RDateSelector from "../../../components/order/reschedule_order/r_date_selector";
import RTimeSlotSelector from "../../../components/order/reschedule_order/r_time_slot_selector";
import { useErrorSnackbar } from "../../../hooks/error_snack";
import { useSetOrderId } from "../../../hooks/order_id";
import { useReschedulePickup } from "../../../redux/features/order/api/mutation";
import { useGetAllOrders } from "../../../redux/features/order/api/query";
import { setRescheduleOrderDetails } from "../../../redux/features/order/order.slice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getAmPm } from "../../../utils/date.util";
import { getSlotFromTime } from "../../../utils/order.util";

function ReschedulePickup() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedOrder, rescheduleOrderDetails } = useAppSelector(
    (s) => s.order
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading: getAllOrdersLoading } = useGetAllOrders();
  const {
    isLoading: rLoading,
    isError: hasRError,
    isSuccess: rSuccess,
    error: rError,
    reset: rReset,
    mutate: reschedulePickup,
  } = useReschedulePickup();

  //* Hook to set the selected order when it is not available from redux store
  useSetOrderId();

  //* Used for setting the initial values
  useEffect(() => {
    if (selectedOrder && !rescheduleOrderDetails.rescheduleDate) {
      dispatch(
        setRescheduleOrderDetails({
          rescheduleDate: selectedOrder.pickupDate,
          rescheduleSlot: getSlotFromTime(selectedOrder.pickupTime),
        })
      );
    }
  }, [dispatch, rescheduleOrderDetails, selectedOrder]);

  useEffect(() => {
    if (rSuccess) navigate(-1);
    if (rError) rReset();
  }, [navigate, rError, rReset, rSuccess]);

  useErrorSnackbar({ isError: hasRError, error: rError });

  const handleReschedulePickup = () => {
    if (
      rescheduleOrderDetails.rescheduleDate &&
      rescheduleOrderDetails.rescheduleSlot &&
      selectedOrder
    ) {
      reschedulePickup({
        orderId: selectedOrder.id,
        pickupInput: {
          pickupDate: rescheduleOrderDetails.rescheduleDate,
          pickupTimeStart: getAmPm(
            rescheduleOrderDetails.rescheduleSlot.startTime
          ),
          pickupTimeEnd: getAmPm(
            rescheduleOrderDetails.rescheduleSlot.endTime
          ),
        },
      });
    }
  };

  return (
    <>
      <Appbar currentTabIndex={0} />
      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        {getAllOrdersLoading || rLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : !selectedOrder || !rescheduleOrderDetails.rescheduleDate ? (
          <div>Order not found</div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Reschedule Pickup
              </Typography>
            </Grid>

            <RDateSelector isPickup/>

            <RTimeSlotSelector />
          </Grid>
        )}
        <Fab
          sx={{
            margin: 0,
            left: "0",
            right: "0",
            width: matches ? "60%" : "25%",
            marginLeft: "auto",
            marginRight: "auto",
            bottom: matches ? "80px" : "20px",
            position: "fixed",
            paddingX: "40px",
            color: "white",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            borderRadius: "15px",
          }}
          variant="extended"
          disableRipple
          onClick={() => handleReschedulePickup()}
        >
          Reschedule
        </Fab>
        <Box sx={{ height: "250px" }}></Box>
        {matches && <BottomBar currentTabIndex={1} />}
      </Box>
    </>
  );
}

export default ReschedulePickup;
