import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { addressSlice } from "./features/address/address.slice";
import { couponSlice } from "./features/coupon/coupon.slice";
import { customerSlice } from "./features/customer/customer.slice";
import { offerSlice } from "./features/offers/offers.slice";
import { orderSlice } from "./features/order/order.slice";
import { productSlice } from "./features/product/product.slice";
import { serviceSlice } from "./features/service/service.slice";

export const store = configureStore({
  reducer: {
    customer: customerSlice.reducer,
    address: addressSlice.reducer,
    coupon: couponSlice.reducer,
    offers: offerSlice.reducer,
    service: serviceSlice.reducer,
    product: productSlice.reducer,
    order: orderSlice.reducer,
  },
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
