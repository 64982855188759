import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetAllOrders } from "../redux/features/order/api/query";
import { setSelectedOrder } from "../redux/features/order/order.slice";
import { useAppSelector, useAppDispatch } from "../redux/store";

export const useSetOrderId = () => {
  const { orderId } = useParams();
  const { selectedOrder, orders } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();
  const { isLoading: getAllOrdersLoading } = useGetAllOrders();

  useEffect(() => {
    if (selectedOrder) return;
    if (!orderId) return;
    if (!orders || getAllOrdersLoading) return;
    const sOrder = orders.find((o) => o.id === orderId);
    if (!sOrder) return;
    dispatch(setSelectedOrder(sOrder));
  }, [dispatch, getAllOrdersLoading, orderId, orders, selectedOrder]);
};
