import { Grid } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { Order } from "../../redux/features/order/order.types";

import OngoingSingleItemHome from "./ongoing_single_item";

function OngoingItem({
  matches,
  ongoingOrders,
}: {
  matches: boolean;
  ongoingOrders: Order[];
}) {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Carousel
        axis="horizontal"
        autoPlay={true}
        dynamicHeight={true}
        centerMode={matches ? false : false}
        infiniteLoop={true}
        width={matches ? "90vw" : "50vw"}
        centerSlidePercentage={85}
        emulateTouch={true}
        showArrows={false}
        showStatus={false}
        showIndicators={true}
      >
        {ongoingOrders.map((item, index) => (
          <OngoingSingleItemHome matches={matches} order={item} />
        ))}
      </Carousel>
      {/* {ongoingOrders.length > 1 ? (
       
      ) : (
        <OngoingSingleItemHome matches={matches} order={ongoingOrders[0]} />
      )} */}
    </Grid>
  );
}

export default OngoingItem;
