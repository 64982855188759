import { useQuery } from "@tanstack/react-query";
import { nonChanging30Mins } from "../../../../config/query.config";
import { APIService } from "../../../../utils/api.service";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setCoupons } from "../coupon.slice";
import { Coupon } from "../coupon.types";

const api = new APIService();

export const useGetAllCoupons = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((s) => s.customer);

  return useQuery(
    ["coupon/getAll"],
    () =>
      api.get<Coupon[]>({
        url: "coupon",
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      ...nonChanging30Mins,
      enabled: !!customer,
      onSuccess: (coupons) => {
        dispatch(setCoupons(coupons));
      },
    }
  );
};
