import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useAuthStage } from "../../../hooks/auth_stage";
import { useErrorSnackbar } from "../../../hooks/error_snack";
import {
  useCreateUser,
  useUpdateUser,
} from "../../../redux/features/customer/api/mutation";
import { useAppSelector } from "../../../redux/store";
import { GridWrapper, PaperWrapper } from "./complete_profile.style";

function CompleteProfile() {
  const { customer, otpPhone, referCode } = useAppSelector((s) => s.customer);

  const {
    isLoading: createLoading,
    isError: createIsError,
    error: createError,
    mutate: createUser,
  } = useCreateUser();

  const {
    isLoading: updateLoading,
    isError: updateIsError,
    error: updateError,
    mutate: updateUser,
  } = useUpdateUser();

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      name: customer ? customer.name : "",
      email: customer ? customer.email : "",
    },
    onSubmit: ({ email, name }) => {
      var refer = referCode ?? "";
      customer
        ? updateUser({ email, name })
        : otpPhone &&
          createUser({ email, name, phone: otpPhone, referCode: refer });
    },
  });

  useAuthStage();
  useErrorSnackbar({ error: updateError, isError: updateIsError });
  useErrorSnackbar({ error: createError, isError: createIsError });

  return (
    <GridWrapper
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <PaperWrapper elevation={3}>
        <Typography
          textAlign="center"
          sx={{ fontWeight: "bold", fontSize: "1.4rem" }}
        >
          Please enter your details
        </Typography>
        <form onSubmit={handleSubmit} style={{ marginTop: "10px" }}>
          <TextField
            required
            id="name"
            label="Name"
            variant="standard"
            sx={{ minWidth: "100%" }}
            value={values.name}
            onChange={handleChange}
          />
          <TextField
            required
            id="email"
            label="Email"
            type="email"
            variant="standard"
            sx={{ minWidth: "100%", marginTop: "10px" }}
            value={values.email}
            onChange={handleChange}
          />
          <Box textAlign="center" sx={{ marginTop: "20px" }}>
            {createLoading || updateLoading ? (
              <CircularProgress />
            ) : (
              <Button
                disabled={
                  values.name === "" ||
                  values.name === null ||
                  values.email === "" ||
                  values.name === null
                }
                type="submit"
                value="complete"
                variant="contained"
                sx={{ width: "70%" }}
              >
                Save
              </Button>
            )}
          </Box>
        </form>
      </PaperWrapper>
    </GridWrapper>
  );
}

export default CompleteProfile;
