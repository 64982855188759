import { Box, useMediaQuery, useTheme } from "@mui/material";
import Appbar from "../../components/appbar/appbar";
import Bottombar from "../../components/appbar/bottom_bar";

function PrivacyPolicy() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Appbar currentTabIndex={0} />
      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        <h1>Privacy Policy</h1>
        <Box height="30px" />
        <div style={{ margin: "20px 25px" }}>
          <p style={{ marginBottom: "0.13in", lineHeight: "130%" }}>
            <span>
              <span>
                This privacy policy sets out how Elan Lifestyle Technologies
                Private Limited (hereinafter referred to as ‘Elan’) protects and
                utilises any information that you give us over our app.
              </span>
            </span>
          </p>
          <p style={{ marginBottom: "0.13in", lineHeight: "130%" }}>
            <span>
              <span>
                Elan is committed to ensuring that your privacy is protected.{" "}
              </span>
            </span>
          </p>
          <p style={{ marginBottom: "0.13in", lineHeight: "130%" }}>
            <span>
              <span>
                Elan may change this policy by updating this page. You should
                check this page from time to time to ensure that you are happy
                with any changes. This policy is effective from the date and
                time a user registers with Elan and accepts the terms and
                conditions laid out in the Policy.&nbsp;
                <br />
                <br />
                In order to provide a personalized experience, Elan may collect
                personal information from you. Additionally our app may require
                you to complete a registration form or seek some information
                from you. When you let us have your preferences, we will be able
                to deliver or allow you to access the most relevant information
                that meets your end.&nbsp;Every effort will be made to keep
                information provided by users in a safe manner. <br />
                <br />A regular user always has the option of not providing the
                information which is not mandatory. You are solely responsible
                for maintaining confidentiality of the user password and user
                identification and all activities and transmission performed by
                the User through his user identification and shall be solely
                responsible for carrying out any online or off-line transaction
                involving credit cards / debit cards or such other forms of
                instruments or documents for making such transactions and Elan
                assumes no responsibility or liability for their improper use of
                information relating to such usage of credit cards / debit cards
                used by the subscriber online / off-line.&nbsp;
              </span>
            </span>
          </p>
          <p style={{ marginBottom: "0.13in", lineHeight: "130%" }}>
            <span>
              <span>
                <br />
                You agree that Elan may use personal information about you to
                improve its marketing and promotional efforts, to analyze site
                usage, improve the app's content and product offerings, and
                customize the app’s content, layout, and services. These uses
                improve the app and better tailor it to meet your needs, so as
                to provide you with a smooth, efficient, safe and customized
                experience while using the app.
              </span>
            </span>
          </p>
          <p style={{ marginBottom: "0.13in", lineHeight: "130%" }}>
            <span>
              <span>
                You agree that Elan may use your personal information to contact
                you and deliver information to you that, in some cases, are
                targeted to your interests, such as targeted banner
                advertisements, administrative notices, product offerings, and
                communications relevant to your use of the app. By accepting the
                Terms and Conditions and Privacy Policy, you expressly agree to
                receive this information. If you do not wish to receive these
                communications, we encourage you to opt out of the receipt of
                certain communications in your profile .Elan may disclose all or
                part of your personal details in response to a request from the
                law enforcement authorities or in a case of bonafide requirement
                to prevent an imminent breach of the law.
              </span>
            </span>
          </p>
          <p
            style={{
              marginTop: "0.16in",
              marginBottom: "0in",
              background: "#ffffff",
              border: "none",
              padding: "0in",
              lineHeight: "130%",
            }}
          >
            <br />
          </p>
        </div>
      </Box>
      <Box height="100px" />
      {matches && <Bottombar currentTabIndex={0} />}
    </div>
  );
}

export default PrivacyPolicy;
