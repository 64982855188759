function SafetyItem({
  title1,
  title2,
  icon,
}: {
  title1: string;
  title2: string;
  icon: string;
}) {
  return (
    <div
      style={{
        verticalAlign: "top",
        display: "inline-block",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={icon}
        alt="Lavado Safety"
        style={{
          width: "35px",
          height: "35px",
          marginBottom: "4px",
        }}
      />
      <span style={{ display: "block" }}>
        {title1} <br /> {title2}
      </span>
    </div>
  );
}
export default SafetyItem;
