import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useFormik } from "formik";
import { resetReferCode } from "../../redux/features/customer/customer.slice";
import { useValidateReferCode } from "../../redux/features/customer/api/mutation";
import { useSecondEffect } from "../../hooks/second_effect";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { TiTick } from "react-icons/ti";
import { ImCancelCircle } from "react-icons/im";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReferDialog() {
  const { referCode } = useAppSelector((state) => state.customer);
  const dispatch = useAppDispatch();

  const {
    isLoading,
    isError,
    error,
    mutate: validateReferCode,
  } = useValidateReferCode();

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      referCode: referCode ? referCode : "",
    },
    onSubmit: ({ referCode }) => {
      referCode && validateReferCode({ referCode });
    },
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useSecondEffect(() => {
    if (referCode !== null) setIsDialogOpen(false);
  }, [referCode]);

  return (
    <div>
      {referCode ? (
        <Box
          sx={{
            border: "1px #00C4CC solid",
            padding: "10px",
            margin: "5px 10%",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={2}>
              <Avatar
                sx={{
                  bgcolor: "rgba(0,244,66,0.1)",
                  width: "25px",
                  height: "25px",
                  textAlign: "center",
                }}
              >
                <TiTick color="#00F442" />
              </Avatar>
            </Grid>
            <Grid item xs={8} sx={{ textAlign: "center" }}>
              Refer code applied.
            </Grid>
            <Grid item xs={2}>
              <IconButton
                sx={{ textAlign: "center", height: "35px", width: "35px" }}
                onClick={() => {
                  dispatch(resetReferCode());
                  values.referCode = "";
                }}
              >
                <ImCancelCircle color="red" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Link
          component="button"
          color="secondary.main"
          onClick={() => {
            setIsDialogOpen(true);
          }}
        >
          Got referral code?
        </Link>
      )}

      <Dialog
        open={isDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsDialogOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>{"Got a referral code?"}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              label="Enter your code"
              fullWidth
              variant="standard"
              id="referCode"
              name="referCode"
              value={values.referCode}
              placeholder="Refer code"
              onChange={handleChange}
            />
            {isError ? (
              <Typography
                marginTop={1}
                sx={{ fontWeight: "w800", color: "primary.main" }}
              >
                {error}{" "}
              </Typography>
            ) : (
              <div />
            )}
            {referCode === null ? <div>Invalid refer code</div> : <div />}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ color: "secondary.main" }}
              onClick={() => setIsDialogOpen(false)}
            >
              Skip
            </Button>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                value="Validate"
                variant="contained"
                sx={{ color: "white", backgroundColor: "secondary.main" }}
              >
                Apply
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default ReferDialog;
