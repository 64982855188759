import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Offer, OfferState } from "./offers.types";

const initialState: OfferState = {};

export const offerSlice = createSlice({
  initialState,
  name: "offer",
  reducers: {
    setOffers: (state: OfferState, action: PayloadAction<Offer[]>) => {
      state.offers = action.payload;
    },
    resetOffers: (state: OfferState) => {
      state.offers = undefined;
    },
  },
});

export const { resetOffers, setOffers } = offerSlice.actions;
