import { Box, useMediaQuery, useTheme } from "@mui/material";
import Appbar from "../../components/appbar/appbar";
import Bottombar from "../../components/appbar/bottom_bar";

function TermsAndConditions() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Appbar currentTabIndex={0} />
      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        <h1>Terms and Conditions</h1>
        <Box height="30px" />
        <div style={{ margin: "20px 25px" }}>
          <>
            <p
              style={{
                marginTop: "0.1in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Overview</b>
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  Welcome to our app. It is operated by Elan Lifestyle
                  Technologies Private Limited (“Elan”). Throughout the site,
                  the terms 'Elan' or 'us' or 'we' or ‘our’ refers to Elan
                  Lifestyle Technologies Private limited. All information, tools
                  and services available on our app to you, the user, are
                  conditioned upon your acceptance of all our terms, conditions,
                  policies and notices stated herein below.
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <br />
                  By visiting our app, you engage our services and thereby agree
                  to be bound by the following conditions and terms of service,
                  these are inclusive of any additional conditions, terms and
                  policies available by hyperlink and/or referred to herein.
                  These “Terms of Service” apply to all users of the site,
                  including without limitation users who are browsers, vendors,
                  customers, merchants, and/ or contributors of content.
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  You are requested to go through and understand these “Terms of
                  Service” thoroughly and carefully before accessing our app and
                  availing our services. You automatically agree to be bound by
                  these Terms of Service, by browsing, accessing or using any
                  part of this app. If these Terms of Service are considered an
                  offer, acceptance is expressly limited to these Terms of
                  Service. In the event of you not agreeing to all these terms
                  and conditions, you may not avail of any services or access
                  the app.
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <br />
                  The Terms of Service shall also apply to any new tools or
                  features which may be added to the current store in the
                  future. You can review the most current version of the Terms
                  of Service at any time on this page. We at Elan, reserve the
                  right to update, change or replace any part of these Terms of
                  Service by making changes to and/or posting updates on the
                  app. Checking this page periodically for changes/updates is
                  your responsibility. If you fail to adhere to the changes, you
                  must stop using the service. Your continuous use of the
                  services will signify your acceptance of the changed terms.
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Eligibility of User(s)</b>
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  User(s) refers to any individual or business
                  entity/organization that legally operates in India or in other
                  countries, uses and has the right to use the services provided
                  by Elan. If you are an individual, by agreeing to these Terms
                  of Service, you represent that you are at least the age of
                  majority in your state or province of residence and/ or that
                  you have given us your consent to allow any of your minor
                  dependents to use this app.
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  These terms of service are applicable to anyone who accesses
                  the app for any purpose. They also apply to any legal entity
                  which may be represented by you under actual or apparent
                  authority.{" "}
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Protection of Online content</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginTop: "0.1in",
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You may not utilise our products for any wrongful,
                        unauthorized or unlawful purpose nor may you, in the use
                        of the service, violate any laws in your jurisdiction
                        (including but not limited to copyright laws).{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You must not transmit any worms or viruses or any code
                        of a destructive nature which can potentially harm or
                        obstruct the functioning of our
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>.</span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Subject to the following restrictions and in accordance
                        with any written agreement between you and Elan
                        regarding the same, you are hereby authorized by Elan to
                        view, copy, download and print documents and the
                        graphics incorporated in them from this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>:</span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <ol type="i">
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        The online content may be used solely by you for
                        non-commercial purposes such as informational, personal
                        and educational purposes unless otherwise expressly
                        indicated; Modification or use of the content for any
                        other purpose is a violation of our copyright and other
                        proprietary rights.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Proper attribution of the appropriate source must be
                        made in each copy, including any copyright or other
                        proprietary notices originally shown or included in the
                        content.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        No content found on this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        may be used, republished, exploited, reproduced,
                        performed, publicly displayed or distributed for any
                        public or commercial purpose unless Elan has given you
                        their consent in writing, to do the same. You further
                        agree, that without express written permission given by
                        Elan, you may not reproduce, sell, resell, duplicate,
                        copy or exploit any portion of, use of or access to the
                        services or any contact on the{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        via which such service is made available.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Unless it is expressly determined, you cannot change or
                        modify any of the content available on the{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        nor can you attempt to claim ownership over any of the
                        content found on this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>.</span>
                    </span>
                  </span>
                  <span>
                    <span>
                      <u>
                        <span style={{ fontWeight: "normal" }}></span>
                      </u>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Mirroring or framing any portions of this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        in any other environment may not be done by you without
                        prior written consent from Elan.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ol>
            <p
              style={{
                marginLeft: "1in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        The right to terminate the access of any user as per its
                        discretion has been solely reserved by Elan. This
                        includes users who do not adhere to these Terms and
                        Conditions or who infringe upon or otherwise violate the
                        rights of others. You agree that you are expressly
                        prohibited from using any content found on this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        to the detriment of Elan or its affiliates in any type
                        of patent infringement proceeding.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        All trademarks, trade names and service marks are
                        proprietary to Elan, excluding all the trademarks,
                        service marks and trade names of other companies which
                        may be displayed on our{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        and those which are indicated as otherwise.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginTop: "0.19in",
                    marginBottom: "0.09in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "130%",
                  }}
                >
                  <span>
                    <span>
                      Any theme, technology, picture, content not owned by us
                      have been purchased / obtained legally by us.&nbsp;
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginTop: "0.1in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You are strictly prohibited from posting or{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>transmitting any</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        material that is obscene, libellous, defamatory,
                        threatening, unlawful, pornographic, or otherwise
                        inappropriate to Elan through this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>.</span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You understand that your content may be unencrypted,
                        transferred and involved in transmission across various
                        networks. There may be changes made in order to adapt
                        and conform to technical requirements for connecting to
                        devices or networks.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Since the content and material available on this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        are for providing general information only and are not
                        to be depended on or used as the sole basis for making
                        decisions without consulting more accurate, primary,
                        more recent and complete information sources, Elan does
                        not claim any responsibility for any inaccurate or
                        incomplete information on this site.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>You,</span>
                    </span>
                  </span>
                  <span>
                    <span>therefore, agree</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        that you are relying on any content or material on this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        at your own risk. Further, it is your responsibility to
                        monitor and take note of any updates, modifications or
                        changes made to the information available on this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        . Elan reserves the sole right to, make or not make such
                        modifications at any time, as per our discretion.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Furthermore, Elan takes no responsibility for, and will
                        not be liable for, the
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        being temporarily unavailable due to any technical
                        issues that we have no control over.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginLeft: "0.25in",
                marginTop: "0.31in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Other Uses that are prohibited</b>
                </span>
              </span>
            </p>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Along with the above prohibitions as set forth in the
                        Terms of Service, you are prohibited from using the
                        content on this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>or this </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>for:</span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <ol type="i">
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Soliciting others to participate in or perform any acts
                        that are unlawful;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        any unlawful purpose;{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Abusing, harassing, insulting, defaming, slandering,
                        disparaging, intimidating anyone;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Discriminating on the basis of sexual orientation,
                        gender, race, national origin, age, disability or
                        ethnicity ;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Submitting false or misleading information;{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Spamming, Phishing, crawling, spidering, pharming,
                        pretexting, or scraping etc.;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Tracking or collecting the personal information of
                        others;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Circumventing or interfering with the security features
                        of the Service or any related{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>, other </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        s, or the Internet;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ol>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ol type="i" start={9}>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Violating any national or state regulations, rules,
                        local ordinances or laws.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ol>
            <p
              style={{
                marginLeft: "1.03in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Incase you violate any of the above prohibited uses,
                        Elan reserves the right to terminate your use of our
                        services or any related{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        for the same.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "115%",
              }}
            >
              <span>
                <span>
                  <span style={{ fontWeight: "normal" }}>
                    <br />
                  </span>
                </span>
              </span>
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Refusal of services</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We, at Elan, reserve the right to refuse service to
                        anyone, at any time for any reason as per our sole
                        discretion.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Discrepancies in counting</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        There may be a chance of counting mistakes during pickup
                        and Elan cannot be held responsible for the same.
                        Counting of clothes will be done in front of the
                        customer at the time of the pickup. After the clothes
                        reach our facility, the exact count shall be given.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In case any discrepancy is found in the counting
                        procedure, it shall be brought to the customer’s notice
                        as soon as possible. In the event of a customer being
                        unable to verify the count, our count shall be
                        considered to be final. The customer is not entitled to
                        any compensation in situations where such discrepancies
                        occur.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Rejection of clothes during reception</b>
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        If upon receiving an order, it seems that the cloth is
                        in an unfit condition or the cloth material is capable
                        of damaging any machinery or other clothing involved in
                        the laundering process, Elan reserves the right to
                        reject it.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Any damages discovered at the time of picking the
                        clothes up shall be brought to the customer’s notice
                        beforehand. We cannot take responsibility for any such
                        damage that was already present in the clothing before
                        we cleaned or laundered it.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Damage or Loss of entrusted article(s)</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We, at Elan, understand how indispensable your clothing
                        is to you and we strive to provide quality care and
                        services to your clothes. In the rare event{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>of any</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        damage caused to any article or loss of any article, you
                        are requested to report the same within 3(three) days of
                        receiving the processed order.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Elan may take upto 7( seven) days from the day of
                        receiving the complaint in order to process it and look
                        for a suitable solution for you.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Elan may provide compensation upto 10 times the charge
                        for cleaning the article in question, subject to a
                        maximum compensation of Rs.2000. The compensation
                        provided will not depend on the price, brand or
                        condition of the garment which has been damaged or lost.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Reimbursement will be provided only for entrusted
                        articles. Elan reserves the right to retain the damaged
                        article for which our company has paid you any form of
                        compensation.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We at Elan, reserve the right to take any action beyond
                        our stated guidelines on a case by case basis as our
                        priority at the end of the day is to provide you, our
                        customer, with an appropriate solution and try our level
                        best to make things right for you.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0.14in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We are not liable for any loose items such as cash,{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>pen drives</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        , keys, wallets, or other such items forgotten in the
                        clothes given. We will try our best to return them,
                        however we do not guarantee them. We will not be liable
                        to return the same or to ensure that they are not
                        damaged or misplaced.
                        <br />
                      </span>
                    </span>
                  </span>
                  <br />
                  <br />
                </p>
              </li>
            </ul>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Exceptions to damaged articles</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We do not take any liability for articles that have been
                        damaged due to normal wear and tear since clothes tend
                        to eventually wear out over time despite our best
                        efforts to maintain and clean them. Elan will not be
                        liable for any issues that may arise during laundering
                        and dry cleaning processes due to normal wear and tear.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  “
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Normal wear and tear” would include problems like slight
                        shrinkage, slight fading of fabric, small rips or tears
                        in fabric or thinning of fabric that may arise from
                        repeated laundering procedures. Discoloration of fabric
                        because of any kind of deodorant, perfumes or colognes
                        would also be regarded as normal wear and tear.
                        Compensation will not be provided for the same.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Elan will not take responsibility for damage caused,
                        despite professional treatment, to articles due to non
                        detectable or unidentifiable properties. These
                        properties comprise; bad reactions of colours or
                        impressions, distressing of fabric, if the fabrics lack
                        sufficient resistance, presence of a pen in the lining,
                        deterioration of loops, zippers, buttons, shoulder pads,
                        ornaments or any former defects etc. or if the
                        maintenance label is incorrect. Elan will not provide
                        reimbursement to customers wherein their articles have
                        been damaged due to the same.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Elan will not be held liable for any damage caused to
                        articles arising from cloth ageing which includes issues
                        such as staining, colour bleeding, loss of buttons,
                        cloth stretching etc. These problems primarily occur due
                        to ageing of the cloth and not our laundry services.
                        Some clothes tend to bleed colours on their own because
                        of certain basic properties associated with them. Elan
                        cannot be held liable for any loss, related to such a
                        situation.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginLeft: "0.45in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "115%",
              }}
            >
              <span>
                <span>
                  <b>Delicate articles of clothing and label instructions</b>
                </span>
              </span>
            </p>
            <p
              style={{
                marginLeft: "0.45in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We require you to take note of all washing instructions
                        or any specific laundry related instructions on all
                        items of clothing before giving them to us for cleaning.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Ensuring that your clothes are safely cleaned as per the
                        manufacturer’s instructions affixed on the label and
                        informing us about the requisite instructions is
                        ultimately the customer’s i.e. your responsibility
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In case of delicate textiles that require a special type
                        of treatment or cleaning technique, the customer must
                        inform us of the same at the time of placing an order.
                        In the event of, the customer failing to do so, the
                        indication of care or any symbol of maintenance on the
                        label affixed to the fabric shall be considered as the
                        determinant for laundering provided to the textiles in
                        question. Elan cannot be held liable if the label in
                        question is incorrect or provides any incorrect
                        instructions. The same shall be applicable to any
                        article which requires a special treatment for stain
                        removal.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0.14in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In case the article does not have any attached wash care
                        and maintenance instruction, Elan has the right to use
                        its expertise to provide any treatment or service we
                        consider to be suitable and appropriate. However we
                        cannot be held liable for the same.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Stain removal</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We, at Elan, provide the highest quality of cleaning
                        available as per our standard methods and techniques.
                        However, as stain removal is a complex procedure, we do
                        not guarantee it in any case nor do we guarantee removal
                        of all stains on an article.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0.14in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We will try to inform you about any issues and stains
                        that cannot be resolved due to there being a possibility
                        of further damage to the article, whenever possible.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Delay in delivery or pick up</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Unforeseeable circumstances may lead to a delay in
                        timely delivery of clothes. Elan cannot be held
                        responsible for the same.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We strive to follow the agreed pickup and delivery
                        timeline; however, delays do not entitle you to
                        compensation.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0.14in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In case of unavailability of a customer, the clothes to
                        be delivered shall be entrusted with security or a
                        friend or neighbour only when we receive consent and a
                        confirmation from the customer, authorising us to do so.
                        We shall not be responsible for any loss or damage in
                        such cases.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Misdelivery of article(s)</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In the event{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>of a</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        mix-up in the delivery or cleaning procedures leading to
                        a misdelivery which results in you receiving someone
                        else’s laundry we request you to notify us regarding the
                        same via phone or email.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We kindly ask that you allow us to pick up all
                        misdelivered laundry so we return all misdelivered items
                        (including yours) to their rightful owners as soon as
                        possible.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Our delivery and shipping policy </b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We pick-up and deliver laundry in India starting with{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>Mumbai</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        and expanding thereafter we will pick-up and deliver
                        laundry.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "130%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Pick-up and delivery appointments can currently be made
                        on phone, website and our{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        from 9 am to 9 pm which shall be subject to change in
                        future at our sole discretion.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "130%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        For a home pick-up and delivery,{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>the current</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        minimum bill should be INR 3
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>6</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        0/- which shall be subject to change in future at our
                        sole discretion. If the minimum bill is less than Rs 3
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>6</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        0/- then an additional delivery fee of Rs 60/- will be
                        charged.&nbsp;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "130%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        After informing us the delivery time, in the event you
                        forget to reschedule the delivery time due to any change
                        from your side atleast 2 hrs prior to the scheduled
                        delivery time and there is a no show at the time of
                        delivery due to this, then an additional amount of
                        Rs.60/- will be charged by us else the garments can be
                        delivered to you for free with the next set of laundry
                        delivery scheduled by us. &nbsp;
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "130%",
                  }}
                >
                  <span>
                    <span>
                      Delivery can be made to the neighbour/watchman/friend if
                      informed well in advance to us and the payment is made by
                      them to us. However post the delivery of the garments Elan
                      shall not be responsible for any damage/loss made to the
                      garments by the said neighbour/watchman/friend.&nbsp;
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0.19in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "130%",
                  }}
                >
                  <span>
                    <span>
                      No delivery shall be made without the monitoring of a
                      person, who shall need to give an acknowledgement /
                      consent at the end of the delivery to us.&nbsp;
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Collection of article(s) after deposit</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        According to our Terms of Service, all articles given
                        for laundering must be collected within a period of
                        three (3) months after they have been deposited with us.
                        Elan has no liability in case an article is lost or
                        misplaced after the expiry of ninety (90) days, during
                        which the customer fails to collect the article in
                        question, back.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0.14in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Unless special circumstances arise, the articles will be
                        returned only against validation of receipt provided
                        earlier.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Refund and cancellation Policy </b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginTop: "0.1in",
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Partial or full refund will not be provided by Elan for
                        services or packages consumed.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In case you are unable to give laundry for pick-up or
                        receive a delivery, we request you to notify us about
                        the same at least two hours prior to the scheduled pick
                        up or delivery appointment.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        No cancellations can be made post completion of the
                        actual pick up and in case cancellation is made then
                        full charges shall be applied for the same.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        If any information made available on any related{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        or regarding any service is inaccurate at any time, or
                        contains any errors, omissions, Elan reserves the right
                        to cancel orders or correct, modify, update the
                        information, as per its own discretion, without prior
                        notice at any time. This is inclusive of a situation
                        wherein you have already submitted your order. <br />
                      </span>
                    </span>
                  </span>
                  <br />
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "115%",
              }}
            >
              <span>
                <span>
                  <b>Maintaining records</b>
                </span>
              </span>
            </p>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Elan reserves the right to keep and maintain records of
                        your clothes and store, display, reproduce or retain the
                        same. These records may include descriptions,
                        photographs, images and may be associated to records or
                        archives with your name and other information about you,
                        for periods that are commercially reasonable to execute
                        activities for inventory purposes, archival, quality
                        control, collection of data and prevention of losses.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Any personal information you have submitted through our{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        will be governed by our Privacy Policy <br />
                      </span>
                    </span>
                  </span>
                  <br />
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "1.2in",
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
              <br />
            </p>
            <p
              style={{
                marginBottom: "0.14in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
              }}
            >
              <span>
                <span>
                  <b>Modifications to the Service and Prices</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Elan has the right to discontinue or modify the service
                        or any part or content of the service, whenever we may
                        consider it to be suitable, without giving any
                        notifications regarding the same.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We reserve the right to change the prices for our
                        services and modify the description of our services,
                        without notice. In case of any change in prices,
                        discontinuance or suspension of services, we shall not
                        have any sort of liability towards you or any third
                        party for the same.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We at Elan, reserve the right without obligations, to
                        limit the availability or sale of our services to any
                        individual, jurisdiction or geographic region. The right
                        to restrict the quantities of any services we offer is
                        also solely reserved by us. Any offer for any service
                        made on this site is void where prohibited.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Therefore when it comes to amending, updating or
                        clarifying information in connection with any related{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        or pricing information or any service we undertake no
                        obligation to do so, except as required under the law.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginTop: "0.31in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>
                    Liability regarding Third-Party tools and Third-Party links
                  </b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginTop: "0.31in",
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You acknowledge and agree, that any third-party tools we
                        may provide you with access to on our{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        , shall be available without any representations,
                        warranties, endorsements or any kind of conditions.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We claim no liability whatsoever regarding your use of
                        such optional third-party tools which may be made
                        available on our{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>.</span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You agree and acknowledge that, your usage of any such
                        optional third-party tools offered through this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        shall be at your own risk and discretion; after you have
                        ensured that you approve of and are familiar with the
                        terms and conditions given by the relevant third-party
                        provider(s) who are offering these tools.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Any new features and/or services, including making new
                        tools and resources available, which may be offered
                        through our{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        in the future, shall also be subject to these Terms of
                        Service.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Third-party links available on this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        may direct you to the{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        s of third-parties, who are not affiliated to us. We
                        urge all our customers to read, review and understand
                        the practices and policies of these third-parties prior
                        to engaging in any transactions with them or before
                        availing of their services as Elan does not have any
                        liabilities, warranties or responsibilities regarding
                        any material, content or services provided by any
                        third-party.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Elan is not liable for any harm, damages or losses
                        caused due to purchase or use of products, services,
                        content, resources or any other transactions made in
                        relation to third-party{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>s.</span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Elan will direct any complaints, concerns, queries or
                        claims related to products or services provided by any
                        third-party to the third-party, without addressing or
                        being responsible for the same.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        It is not Elan’s responsibility to scrutinise or
                        appraise the content on third-party{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        s or to evaluate its accuracy. <br />
                      </span>
                    </span>
                  </span>
                  <br />
                </p>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0.31in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Usage of Feedback</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginTop: "0.31in",
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You acknowledge and agree that we reserve the right to,
                        without any restrictions, copy, publish, translate,
                        distribute, edit and use in any medium any content you
                        forward to us, at our own discretion. This content may
                        include suggestions, proposals, comments, feedback,
                        ideas or other material that you send us via mediums
                        like e-mail, post, online etc. with or without us
                        requesting you to do so.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We have no responsibility or obligation whatsoever to
                        pay compensation for any such content, respond to any
                        such content or maintain such content in confidence.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In case we find, as per our sole discretion, that any
                        such content is unlawful, libellous, defamatory,
                        threatening, offensive, obscene, objectionable or
                        pornographic in nature, we may and shall be under no
                        obligation to regulate, edit or remove such content. We
                        may do the same for content that we determine, as per
                        our own discretion, violates the intellectual property
                        rights of any party or content that does not adhere to
                        these Terms of Service.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You acknowledge and agree that you are solely liable for
                        any content you forward to us as well as, for its
                        accuracy and authenticity. Elan assumes no liability or
                        responsibility for any content posted by any third-party
                        or you.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You further agree to not send us or post any content
                        that could potentially transmit or transmits any
                        computer virus or malware that could affect our
                        functioning and operations in any manner.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <br />
                </span>
              </span>
              <span>
                <span>
                  <b>Disclaimer of Warranties</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We at Elan do not represent, guarantee or warrant that
                        your use of our services will be timely, secure, free of
                        errors or uninterrupted. We give no guarantees or
                        warranties regarding the accuracy or reliability of the
                        results arising from the use of services.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You expressly agree that your ability or inability to
                        use our services is at your own risk. The service and
                        all products and services delivered to you through the
                        service are (except as expressly stated by us) provided
                        'as is' and 'as available' for your use, without any
                        conditions of any kind, any representations or
                        warranties, either express or implied, including all
                        implied warranties or conditions of merchantability,
                        merchantable quality, fitness for a particular purpose,
                        durability, title, and non-infringement.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You further acknowledge and agree that we reserve the
                        right to remove the service for indefinite periods of
                        time or cancel the service at any time, without prior
                        notice.
                        <br />
                        <br />
                      </span>
                    </span>
                  </span>
                  <br />
                </p>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0.1in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Limitation of Liability</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In no event will we be liable for any loss or damage
                        including without limitation, indirect or consequential
                        loss or damage, or any loss or damage whatsoever arising
                        from loss of data or profits arising out of, or in
                        connection with, the use of this{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>.</span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In no case shall Elan, our directors, officers,
                        employees, affiliates, agents, contractors, interns,
                        suppliers, service providers or licensors be liable for
                        any injury, loss, claim, or any direct, indirect,
                        incidental, punitive, special, or consequential damages
                        of any kind, including, without limitation lost profits,
                        lost revenue, lost savings, loss of data, replacement
                        costs, or any similar damages, whether based in
                        contract, tort (including negligence), strict liability
                        or otherwise, arising from your use of any of the
                        service or any products procured using the service, or
                        for any other claim related in any way to your use of
                        the service or any product, including, but not limited
                        to, any errors or omissions in any content, or any loss
                        or damage of any kind incurred as a result of the use of
                        the service or any content (or product) posted,
                        transmitted, or otherwise made available via the
                        service, even if advised of their possibility.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In states or jurisdictions which do not allow the
                        exclusion or the limitation of liability for
                        consequential or incidental damages, our liability shall
                        be limited to the maximum extent permitted by law.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Jurisdiction and Governing Law</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        The general terms and conditions of this site are
                        subject to the Indian Laws and Jurisdiction of Mumbai
                        courts only.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        All taxes will be levied as per the applicable law.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Indemnification</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In the event of, you breaching or violating or not
                        adhering to these Terms of Service or your violation of
                        the rights of a third-party or any law, you acknowledge
                        and agree to indemnify, defend, protect and hold
                        harmless Elan Lifestyle Technologies Private Limited
                        including our subsidiaries, partners, offices, parent,
                        directors, agents, licensors, contractors,
                        subcontractors, service providers, employees and interns
                        against any demand or claim including reasonable
                        attorneys’ fees made by any third-party.
                        <br />
                      </span>
                    </span>
                  </span>
                  <br />
                </p>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Severability Clause</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        In the event that any provision of these Terms of
                        Service is determined to be unlawful, void or
                        unenforceable, such provision shall nonetheless be
                        enforceable to the fullest extent permitted by the
                        applicable law.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Further, the unenforceable portion shall be deemed to be
                        severed from these Terms of Service and such
                        determination shall not affect the validity and
                        enforceability of any other remaining provisions.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <b>Termination</b>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        The obligations and liabilities of the parties which
                        were undertaken before the date of termination shall
                        survive for all purposes; post the termination of this
                        agreement.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        Unless we at Elan terminate, or you, by ceasing to use
                        our{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        or informing us that you do not wish to avail of our
                        services any longer by notification, terminate these
                        Terms of Service, they shall remain effectively in
                        force.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        We may, as mentioned earlier, terminate your access to
                        and use of all or any part of our services or{" "}
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>app</span>
                  </span>
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        at any time without giving any prior notice if we
                        determine, within or sole judgement and discretion that
                        you have not adhered to or complied with any term or
                        provision of these Terms of Service or if we suspect you
                        of having done the same.{" "}
                      </span>
                    </span>
                  </span>
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginBottom: "0in",
                    background: "#ffffff",
                    border: "none",
                    padding: "0in",
                    lineHeight: "115%",
                  }}
                >
                  <span>
                    <span>
                      <span style={{ fontWeight: "normal" }}>
                        You acknowledge and agree that you shall remain liable
                        for all amounts due up to and including the date of
                        termination.
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                marginLeft: "0.5in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                fontWeight: "normal",
                lineHeight: "115%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
            </p>

            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  <u>
                    <b>DISCLAIMER</b>
                  </u>
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  The information contained in this app is for general
                  information purposes only. The information is provided by Elan
                  Lifestyle Technologies Private Limited (“Elan”) and while we
                  endeavour to keep the information up to date and correct, we
                  make no representations or warranties of any kind, express or
                  implied, about the completeness, accuracy, reliability,
                  suitability or availability with respect to the app or the
                  information, products, services, or related graphics contained
                  on the app for any purpose. Any reliance you place on such
                  information is therefore strictly at your own risk.
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  In no event will we be liable for any loss or damage including
                  without limitation, indirect or consequential loss or damage,
                  or any loss or damage whatsoever arising from loss of data or
                  profits arising out of, or in connection with, the use of this
                  app.
                </span>
              </span>
            </p>
            <p
              style={{
                marginBottom: "0.13in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <span>
                <span>
                  Every effort is made to keep the app up and running smoothly.
                  However, Elan takes no responsibility for, and will not be
                  liable for, the app being temporarily unavailable due to
                  technical issues beyond control.
                </span>
              </span>
            </p>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
            </p>
            <p
              style={{
                marginTop: "0.16in",
                marginBottom: "0in",
                background: "#ffffff",
                border: "none",
                padding: "0in",
                lineHeight: "130%",
              }}
            >
              <br />
            </p>
          </>
        </div>
        <Box height="100px" />
      </Box>
      {matches && <Bottombar currentTabIndex={0} />}
    </div>
  );
}

export default TermsAndConditions;
