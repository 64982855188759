export const nonChangingOneYear = {
  staleTime: 1000 * 60 * 60 * 24 * 365,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const nonChanging30Mins = {
  staleTime: 1000 * 60 * 30,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const nonChanging5Mins = {
  staleTime: 1000 * 60 * 5,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
}