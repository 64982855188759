import {
  Box,
  Button,
  ButtonBase,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { MdRefresh } from "react-icons/md";
import useRazorpay from "react-razorpay";
import { useNavigate, useParams } from "react-router-dom";
import Appbar from "../../../components/appbar/appbar";
import BottomBar from "../../../components/appbar/bottom_bar";
import { useSetOrderId } from "../../../hooks/order_id";
import {
  useGetRazorPayID,
  usePayForOrder,
} from "../../../redux/features/order/api/mutation";
import {
  useGetAllOrders,
  useGetOneOrder,
} from "../../../redux/features/order/api/query";
import { setRescheduleOrderDetails } from "../../../redux/features/order/order.slice";
import {
  PAYMENT_TYPE,
  STATUS,
} from "../../../redux/features/order/order.types";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  getActiveStepForOrder,
  getSlotFromTime,
} from "../../../utils/order.util";

function OngoingOrderDetails() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { orderId } = useParams();
  const RazorPay = useRazorpay();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedOrder } = useAppSelector((s) => s.order);
  const { customer } = useAppSelector((s) => s.customer);
  const {
    isLoading: getAllOrdersLoading,
    isError: hasGetAllOrdersError,
    error: getAllOrdersError,
  } = useGetAllOrders();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [paymentMode, setPaymentMode] = useState("online");
  const [isPaymentModeDialogOpen, setIsPaymentModeDialogOpen] = useState(false);
  const {
    isSuccess: isPayForOrderSuccess,
    isError: isPayForOrderError,
    error: payForOrderError,
    isLoading: payForOrderLoading,
    reset: resetPayForOrder,
    mutate: payForOrder,
  } = usePayForOrder();
  const {
    data: razorpayID,
    isLoading: getRazorpayLoading,
    reset: resetRazorpayID,
    mutate: getRazorpayID,
  } = useGetRazorPayID();

  //* Hook to set the selected order when it is not available from redux store
  useSetOrderId();

  //* Listen for selected order changes and set active step
  useEffect(() => {
    if (!selectedOrder) return;
    const step = getActiveStepForOrder(selectedOrder);
    setActiveStep(step);
  }, [selectedOrder]);

  //* Whenever the razorpayID becomes available then create the order
  useEffect(() => {
    if (razorpayID && selectedOrder && customer) {
      const paymentGateway = new RazorPay({
        key: process.env.REACT_APP_RAZORPAY_KEY!,
        amount: (selectedOrder.total * 100).toString(),
        currency: "INR",
        name: "Lavado",
        description: selectedOrder.id,
        order_id: razorpayID,
        handler: (res) => {
          resetRazorpayID();
          payForOrder({
            orderId: selectedOrder.id,
            paymentType: PAYMENT_TYPE.CARD,
          });
        },
        prefill: {
          name: customer.name,
          email: customer.email,
          contact: customer.phone,
        },
        notes: {
          address: selectedOrder.address,
        },
        theme: {
          color: "#3399cc",
        },
      });

      paymentGateway.open();
    }
  }, [
    RazorPay,
    customer,
    payForOrder,
    razorpayID,
    resetRazorpayID,
    selectedOrder,
  ]);

  //* Pay for order status listener
  useEffect(() => {
    if (isPayForOrderSuccess) {
      if (isPayForOrderError) alert(payForOrderError); //TODO: show snack for error message
      setIsPaymentModeDialogOpen(false);
    }
  }, [isPayForOrderSuccess, isPayForOrderError, payForOrderError]);

  const handleRescheduleDelivery = () => {
    selectedOrder &&
      dispatch(
        setRescheduleOrderDetails({
          rescheduleDate: selectedOrder.deliveryDate,
          rescheduleSlot: getSlotFromTime(selectedOrder.deliveryTime),
        })
      );
    navigate("reschedule");
  };

  const handleOnlinePayment = () => {
    resetRazorpayID();
    resetPayForOrder();
    selectedOrder &&
      getRazorpayID({
        orderId: selectedOrder.id,
      });
  };

  const handleOfflinePayment = () => {
    resetRazorpayID();
    resetPayForOrder();
    selectedOrder &&
      payForOrder({
        orderId: selectedOrder.id,
        paymentType: PAYMENT_TYPE.CHECK,
      });
  };

  const { refetch: refetchSingleOrder, isLoading: isGetSingleOrdersLoading } =
    useGetOneOrder({ orderId: orderId! });

  return (
    <div>
      <Appbar currentTabIndex={2} />
      <Box sx={{ backgroundColor: "white", margin: "20px 10px 0px 10px" }}>
        {getAllOrdersLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : hasGetAllOrdersError ? (
          <div>{getAllOrdersError}</div>
        ) : !selectedOrder ? (
          <div>Order not found</div>
        ) : (
          <Grid container spacing={2}>
            <Grid xs={12} textAlign="center">
              <Box
                sx={{
                  display: "inline-flex",
                  width: matches ? "85vw" : "50vw",
                }}
              >
                <Card
                  elevation={0}
                  sx={{
                    m: "12px",
                    width: matches ? "85vw" : "50vw",
                    p: "10px",
                    backgroundColor: "#ffffff",
                    display: "flex",
                    height: "70vh",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 0px 5px 0px",
                    }}
                  >
                    <Typography
                      sx={{ color: "#00C4CC", display: "flex", width: "100%" }}
                    >
                      ONGOING SERVICE
                    </Typography>
                    <Typography sx={{ color: "gray", alignSelf: "center" }}>
                      #{selectedOrder.id}
                    </Typography>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 0px 5px 0px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      {selectedOrder.summary ? (
                        <Typography>
                          {`Summary: ${selectedOrder.summary.replaceAll(
                            "<br>",
                            ", "
                          )}`}
                        </Typography>
                      ) : (
                        <Typography>Inspection in progress</Typography>
                      )}
                      <Typography
                        style={{
                          color: "grey",
                        }}
                      >
                        Picked up :{" "}
                        {selectedOrder.pickupDate &&
                          format(
                            new Date(selectedOrder.pickupDate),
                            "MMM dd, yyyy"
                          )}{" "}
                        | {selectedOrder.pickupTime}
                      </Typography>
                    </Box>
                    <ButtonBase
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      <MdRefresh style={{ color: "red" }} size={22} />
                    </ButtonBase>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <Stepper
                      orientation="vertical"
                      activeStep={activeStep}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Step key={"step-one"}>
                        <StepLabel>
                          <Typography>Pick up completed</Typography>
                        </StepLabel>
                        <StepContent
                          style={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <Typography>Inspection in progress</Typography>
                        </StepContent>
                      </Step>
                      <Step key={"step-two"}>
                        <StepLabel>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>Order in progress</Typography>
                            <Typography
                              style={{
                                cursor: "pointer",
                                color: `${
                                  selectedOrder.summary ? "red" : "gray"
                                }`,
                              }}
                              onClick={() =>
                                selectedOrder.summary
                                  ? navigate("summary")
                                  : null
                              }
                            >
                              <u>Order Summary</u>
                            </Typography>
                          </div>
                        </StepLabel>
                        <StepContent
                          style={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          {selectedOrder.summary && selectedOrder.pieces > 0 ? (
                            <Typography>
                              {`Items(${
                                selectedOrder.pieces
                              }): ${selectedOrder.summary.replaceAll(
                                "<br>",
                                ", "
                              )}`}
                            </Typography>
                          ) : (
                            <Typography>Inspection in progress</Typography>
                          )}
                        </StepContent>
                      </Step>
                      <Step key={"step-three"}>
                        <StepLabel>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>Ready for delivery</Typography>
                            <Typography
                              style={{
                                cursor: "pointer",
                                color: `${
                                  selectedOrder.total &&
                                  selectedOrder.total > 1 &&
                                  selectedOrder.paymentType ===
                                    PAYMENT_TYPE.NO_PAYMENT &&
                                  selectedOrder.status === STATUS.READY
                                    ? "red"
                                    : "gray"
                                }`,
                              }}
                              onClick={() => {
                                if (
                                  selectedOrder.total &&
                                  selectedOrder.total > 1 &&
                                  selectedOrder.paymentType ===
                                    PAYMENT_TYPE.NO_PAYMENT &&
                                  selectedOrder.status === STATUS.READY
                                )
                                  setIsPaymentModeDialogOpen(true);
                              }}
                            >
                              <u>Select Payment</u>
                            </Typography>
                          </div>
                        </StepLabel>
                        <StepContent
                          style={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          {selectedOrder.total && selectedOrder.total > 1 ? (
                            <Typography>
                              {`Pay Rs${selectedOrder.total}`}
                            </Typography>
                          ) : (
                            <Typography>Inspection in progress</Typography>
                          )}
                        </StepContent>
                      </Step>
                      {selectedOrder.paymentType !== PAYMENT_TYPE.NO_PAYMENT ? (
                        <Step key={"step-four"}>
                          <StepLabel>
                            <Typography>Delivery Scheduled</Typography>
                          </StepLabel>
                          <StepContent
                            style={{
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            <Typography>
                              {selectedOrder.deliveryDate
                                ? `${format(
                                    new Date(selectedOrder.deliveryDate),
                                    "MMM dd, yyyy"
                                  )} | 
                              ${selectedOrder.deliveryTime}`
                                : "Inspection in progress"}
                            </Typography>
                          </StepContent>
                        </Step>
                      ) : (
                        <div></div>
                      )}
                    </Stepper>
                  </Box>

                  {selectedOrder.status === STATUS.READY && (
                    <Button
                      variant="contained"
                      style={{
                        marginTop: "auto",
                      }}
                      onClick={
                        selectedOrder.paymentType === PAYMENT_TYPE.NO_PAYMENT
                          ? () => setIsPaymentModeDialogOpen(true)
                          : handleRescheduleDelivery
                      }
                    >
                      {selectedOrder.paymentType === PAYMENT_TYPE.NO_PAYMENT
                        ? "Pay Now"
                        : "Reschedule Delivery"}
                    </Button>
                  )}
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
        <Dialog open={isPaymentModeDialogOpen}>
          <DialogTitle>Choose a payment method</DialogTitle>
          <DialogContent>
            <FormControl>
              <RadioGroup
                value={paymentMode}
                onChange={(e) => setPaymentMode(e.target.value)}
              >
                <FormControlLabel
                  value="online"
                  control={<Radio />}
                  label="Pay Online"
                />
                <FormControlLabel
                  value="offline"
                  control={<Radio />}
                  label="Pay on Delivery"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setIsPaymentModeDialogOpen(false);
              }}
              style={{ color: "black" }}
            >
              Cancel
            </Button>
            {payForOrderLoading || getRazorpayLoading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={
                  paymentMode === "online"
                    ? handleOnlinePayment
                    : handleOfflinePayment
                }
              >
                Submit
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Box>
      <Box sx={{ height: "100px" }}></Box>
      {matches && <BottomBar currentTabIndex={2} />}
    </div>
  );
}

export default OngoingOrderDetails;
