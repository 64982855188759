import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { nonChanging30Mins } from "../../../../config/query.config";
import { CUSTOMER_TOKEN } from "../../../../constants/constants";
import { APIService } from "../../../../utils/api.service";
import { setCustomer } from "../customer.slice";
import { Customer } from "../customer.types";

const api = new APIService();

export const useGetCustomerData = ({ token }: { token: string | null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useQuery(
    ["customer/me"],
    () => {
      return api.get<Customer>({
        url: "customer/me",
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    },
    {
      ...nonChanging30Mins,
      enabled: !!token,
      onSuccess: (data) => {
        dispatch(setCustomer(data));
      },
      onError: (error) => {
        localStorage.removeItem(CUSTOMER_TOKEN);
        navigate("/login", { replace: true });
      },
    }
  );
};
