import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorSnackbar } from "../../hooks/error_snack";
import { useGetAllOrders } from "../../redux/features/order/api/query";
import { setSelectedOrder } from "../../redux/features/order/order.slice";
import { Order, STATUS } from "../../redux/features/order/order.types";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import NO_COMPLETED from "../../assets/no_completed_order.png";
import { TiTick } from "react-icons/ti";
import { IoIosArrowForward } from "react-icons/io";
import { format } from "date-fns";

function CompletedList() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [completedOrders, setCompletedOrders] = useState<Order[]>();
  const { orders } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();
  const {
    isLoading: getAllOrdersLoading,
    isError: hasGetAllOrdersError,
    error: getAllOrdersError,
  } = useGetAllOrders();
  const navigate = useNavigate();

  const handleOrderClicked = (order: Order) => {
    dispatch(setSelectedOrder(order));
    navigate(`completed/${order.id}`);
  };

  //* Filter all the ongoing orders from the list
  useEffect(() => {
    if (orders) {
      setCompletedOrders(
        orders.filter((order) => [STATUS.COMPLETED].includes(order.status))
      );
    }
  }, [orders]);

  useErrorSnackbar({ isError: hasGetAllOrdersError, error: getAllOrdersError });

  return (
    <div>
      <Box sx={{ backgroundColor: "white" }}>
        {getAllOrdersLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <div>
            {completedOrders ? (
              !completedOrders.length ? (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ minHeight: "85vh" }}
                >
                  <img
                    alt={"No completed orders"}
                    src={NO_COMPLETED}
                    style={{
                      width: matches ? "85vw" : "50vw",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography>You do not have any Completed order</Typography>
                </Grid>
              ) : (
                <div>
                  {completedOrders.map((o, index) => {
                    return (
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={12} textAlign="center">
                          <Box
                            onClick={() => handleOrderClicked(o)}
                            sx={{
                              marginLeft: "8px",
                              display: "inline-flex",
                              cursor: "pointer",
                            }}
                          >
                            <Card
                              sx={{
                                my: "12px",
                                mr: "5px",
                                width: matches ? "85vw" : "50vw",
                                p: "10px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "0px 0px 5px 0px",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      height: "20px",
                                      width: "20px",
                                      backgroundColor: "green",
                                      marginRight: "12px",
                                    }}
                                  >
                                    <TiTick />
                                  </Avatar>
                                  <Box
                                    sx={{
                                      textAlign: "start",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography m="0" sx={{ fontSize: "14px" }}>
                                      ID: # {o.id}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box
                                  style={{
                                    alignSelf: "center",
                                  }}
                                >
                                  <IoIosArrowForward />
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", marginTop: "10px" }}>
                                <Box
                                  sx={{ flexGrow: "2", display: "inline-flex" }}
                                >
                                  <Box textAlign="start">
                                    <Typography
                                      sx={{ fontSize: "12px", color: "gray" }}
                                    >
                                      Picked
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "inline-flex",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          height: "14px",
                                          width: "14px",
                                          backgroundColor: "#c2c2c2",
                                          borderRadius: "100px",
                                        }}
                                      />
                                      <Box
                                        sx={{
                                          height: "2px",
                                          width: "25vw",
                                          backgroundColor: "#c2c2c2",
                                          alignSelf: "center",
                                        }}
                                      ></Box>
                                    </Box>
                                    <Typography
                                      sx={{ fontSize: "12px", color: "gray" }}
                                    >
                                      {format(
                                        new Date(o.pickupDate),
                                        "MMM dd, yyyy"
                                      )}
                                    </Typography>
                                  </Box>{" "}
                                  <Box textAlign="start">
                                    <Typography sx={{ fontSize: "12px" }}>
                                      Delivered
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "inline-flex",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          height: "14px",
                                          width: "14px",
                                          backgroundColor: "#c2c2c2",
                                          borderRadius: "100px",
                                        }}
                                      />
                                    </Box>
                                    <Typography sx={{ fontSize: "12px" }}>
                                      {format(
                                        new Date(o.deliveryDate),
                                        "MMM dd, yyyy"
                                      )}
                                    </Typography>
                                  </Box>{" "}
                                </Box>

                                <Box>
                                  <Box
                                    sx={{
                                      backgroundColor: "rgba(47, 198, 62,0.1)",
                                      display: "inline-flex",
                                    }}
                                  >
                                    <Typography sx={{ color: "#00CE50" }}>
                                      Paid: &nbsp;
                                    </Typography>
                                    <Typography>₹{o.total}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Card>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              )
            ) : (
              <div />
            )}
          </div>
        )}
      </Box>
    </div>
  );
}

export default CompletedList;
