function BottomBarItem({ title, image }: { title: string; image: string }) {
  return (
    <div
      style={{
        verticalAlign: "top",
        display: "inline-block",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={image}
        alt="Lavado Menu"
        style={{
          width: "20px",
          height: "20px",
          marginBottom: "4px",
        }}
      />
      <span style={{ display: "block", fontSize: "12px" }}>{title}</span>
    </div>
  );
}

export default BottomBarItem;
