import { Grid, styled, TextField, Typography } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import React, { useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setCreateOrderDetails } from "../../../redux/features/order/order.slice";
import { SECONDARY_COLOR } from "../../../constants/theme";
import { getDefaultDate } from "../../../utils/date.util";

function DateSelector() {
  const { createOrderDetails } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();

  const handlePickupDateChanged = (date: Date | null) =>
    date &&
    dispatch(
      setCreateOrderDetails({
        ...createOrderDetails,
        pickupDate: date.toISOString(),
        selectedSlot: undefined,
      })
  );

  useEffect(() => {
    if (!createOrderDetails.pickupDate) {
      dispatch(
        setCreateOrderDetails({
          ...createOrderDetails,
          pickupDate: getDefaultDate().toISOString(),
          selectedSlot: undefined,
        })
      );
    }
  }, [createOrderDetails, dispatch]);

  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
          Select pickup date
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label="Pickup date"
            inputFormat="dd/MM/yyyy"
            value={createOrderDetails.pickupDate ?? getDefaultDate()}
            onChange={handlePickupDateChanged}
            disablePast
            renderInput={(params) => (
              <CustomBorderTextField disabled {...params} />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
}

export default DateSelector;

const CustomBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: black;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${SECONDARY_COLOR};
    }
  }
`;
