import { useQuery } from "@tanstack/react-query";
import { nonChanging5Mins } from "../../../../config/query.config";
import { APIService } from "../../../../utils/api.service";
import { formatOrder, sortOrders } from "../../../../utils/order.util";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setOrders, setSelectedOrder } from "../order.slice";
import { OrderRaw } from "../order.types";

const api = new APIService();

export const useGetAllOrders = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  return useQuery(
    ["ccorder/getAll"],
    () =>
      api.get<OrderRaw[]>({
        url: "ccorder",
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      ...nonChanging5Mins,
      enabled: !!customer,
      onSuccess: (orders) => {
        orders = sortOrders(orders);
        const formattedOrders = orders.map((o) => formatOrder(o));
        dispatch(setOrders(formattedOrders));
      },
    }
  );
};

export const useGetOneOrder = ({ orderId }: { orderId: string }) => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  return useQuery(
    ["ccorder/getOne", orderId],
    () =>
      api.get<OrderRaw>({
        url: `ccorder/${orderId}`,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      ...nonChanging5Mins,
      enabled: !!customer,
      onSuccess: (orderRaw) => {
        dispatch(setSelectedOrder(formatOrder(orderRaw)));
      },
    }
  );
};
