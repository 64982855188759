import { Grid, Card, Typography, Chip } from "@mui/material";
import React from "react";
import { TiTick } from "react-icons/ti";
import { setCreateOrderDetails } from "../../../redux/features/order/order.slice";
import { Service } from "../../../redux/features/service/service.types";
import { useAppSelector, useAppDispatch } from "../../../redux/store";

function ServiceSelector() {
  const { createOrderDetails } = useAppSelector((s) => s.order);
  const { services } = useAppSelector((s) => s.service);
  const dispatch = useAppDispatch();

  const handleServiceClicked = (service: Service) => {
    if (!createOrderDetails.addedServices) {
      dispatch(
        setCreateOrderDetails({
          ...createOrderDetails,
          addedServices: [service],
        })
      );
    } else {
      const i = createOrderDetails.addedServices.findIndex(
        (s) => s.id === service.id
      );
      let newList = [...createOrderDetails.addedServices];
      
      i === -1
      ? newList.push(service)
      : (newList = newList.filter((s) => s.id !== service.id));
      
      dispatch(
        setCreateOrderDetails({
          ...createOrderDetails,
          addedServices: newList,
        })
      );
    }
  };

  return (
    <>
      <Grid item xs={12} md={4.5}>
        <Card
          sx={{
            border: "1px solid #e8e8e8",
            padding: "10px",
          }}
        >
          <Typography
            sx={{ fontWeight: "500", fontSize: "16px", flexGrow: "1" }}
          >
            Add Service
          </Typography>
          {services &&
            services.map((item, index) => {
              return (
                <Chip
                  key={index}
                  label={item.title}
                  icon={
                    createOrderDetails?.addedServices?.includes(item) ? (
                      <TiTick />
                    ) : undefined
                  }
                  sx={{
                    margin: "7px",
                    backgroundColor:
                      createOrderDetails?.addedServices?.includes(item)
                        ? "#ffebee"
                        : "#eeeeee",
                    "&:hover": {
                      backgroundColor:
                        createOrderDetails?.addedServices?.includes(item)
                          ? "#ffebee"
                          : "#eeeeee",
                    },
                  }}
                  onClick={() => {
                    handleServiceClicked(item);
                  }}
                ></Chip>
              );
            })}
        </Card>
      </Grid>
    </>
  );
}

export default ServiceSelector;
