import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Background from "../../../assets/bg.svg";

export const PaperWrapper = styled(Paper)(({ theme }) => ({
  padding: "30px 20px",
  border: "1px red solid",
  [theme.breakpoints.down("md")]: {
    width: "70%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "35%",
  },
}));

export const VerifyGridWrapper = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover !important",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover !important",
  },
}));
