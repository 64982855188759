import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import Appbar from "../../components/appbar/appbar";
import BottomBar from "../../components/appbar/bottom_bar";
import ReferCard from "../../components/common/refer_card";
import ProfileItemCard from "../../components/profile/profile_item_card";
import SocialCard from "../../components/profile/social_card";
import UserCard from "../../components/profile/user_card";

function Profile() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Appbar currentTabIndex={2} />
      <Box sx={{ backgroundColor: "white", margin: "20px 10px 0px 10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box
                sx={{
                  width: matches ? "90vw" : "48vw",
                  border: "1px gray solid",
                  margin: "0px 0px 10px 0px",
                }}
              >
                <UserCard />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box
                sx={{
                  width: matches ? "90vw" : "48vw",
                  border: "1px gray solid",
                  margin: "0px 0px 10px 0px",
                }}
              >
                <ProfileItemCard
                  title="Orders"
                  subtitle="Track orders, upcoming services & past orders."
                  to="/orders"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box
                sx={{
                  width: matches ? "90vw" : "48vw",
                  border: "1px gray solid",
                  margin: "0px 0px 10px 0px",
                }}
              >
                <ProfileItemCard
                  title="Manage Addresses"
                  subtitle="Add, edit or delete your addresses."
                  to="/address"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box
                sx={{
                  width: matches ? "90vw" : "48vw",
                  border: "1px gray solid",
                  margin: "0px 0px 10px 0px",
                }}
              >
                <ProfileItemCard
                  title="Help"
                  subtitle="Terms & Conditions, Privacy Policy, FAQs etc.."
                  to="/help"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box
                sx={{
                  width: matches ? "90vw" : "48vw",
                  border: "1px gray solid",
                  margin: "0px 0px 10px 0px",
                }}
              >
                <ProfileItemCard
                  title="Offers"
                  subtitle="Offer and Promotion updates"
                  to="/offers"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box
                sx={{
                  width: matches ? "90vw" : "48vw",
                  border: "1px gray solid",
                  margin: "0px 0px 10px 0px",
                }}
              >
                <ProfileItemCard
                  title="Logout"
                  subtitle="Logout from your account"
                  to="/"
                  color="primary.main"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box sx={{ width: matches ? "90vw" : "48vw" }}>
                <ReferCard />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{}}
            >
              <Box sx={{ width: matches ? "90vw" : "48vw" }}>
                <SocialCard />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ height: "100px" }}></Box>
      {matches && <BottomBar currentTabIndex={2} />}
    </div>
  );
}

export default Profile;
