import React, { useRef, useEffect } from "react";

export function useSecondEffect(fn: Function, deps: React.DependencyList) {
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
