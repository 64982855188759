import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { CUSTOMER_TOKEN } from "../../../../constants/constants";
import { APIService } from "../../../../utils/api.service";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setAuthStage,
  setCustomer,
  setOTPPhone,
  setReferCode,
} from "../customer.slice";
import { AuthStage, Customer } from "../customer.types";

const api = new APIService();

export const useSendOTPRecaptcha = () => {
  const dispatch = useDispatch();
  return useMutation(
    ["customer/sendOTPRecaptcha"],
    ({ phone, captchaToken }: { phone: string; captchaToken: string }) =>
      api.post<string>({
        url: "customer/sendOTPRecaptcha",
        data: {
          phone,
          captchaToken,
        },
      }),
    {
      onSuccess: (_, { phone }) => {
        dispatch(setOTPPhone(phone));
        dispatch(setAuthStage(AuthStage.OTP_SENT));
      },
    }
  );
};

export const useSendOTP = () => {
  const dispatch = useDispatch();
  return useMutation(
    ["customer/sendOTP"],
    ({ phone }: { phone: string }) =>
      api.post<string>({
        url: "customer/sendOTP",
        data: {
          phone,
        },
      }),
    {
      onSuccess: (_, { phone }) => {
        dispatch(setOTPPhone(phone));
        dispatch(setAuthStage(AuthStage.OTP_SENT));
      },
    }
  );
};

export const useVerifyOTP = () => {
  const dispatch = useDispatch();

  return useMutation(
    ["customer/verifyOTP"],
    ({ phone, otp }: { phone: string; otp: string }) =>
      api.post<Customer | null>({
        url: "customer/verifyOTP",
        data: {
          phone,
          otp,
        },
      }),
    {
      onSuccess: (customer) => {
        if (customer) {
          dispatch(setCustomer(customer));
          dispatch(setAuthStage(AuthStage.OTP_VERIFIED_WITH_USER));
          localStorage.setItem(CUSTOMER_TOKEN, customer.token);
        } else {
          dispatch(setAuthStage(AuthStage.OTP_VERIFIED_NO_USER));
        }
      },
    }
  );
};

export const useCreateUser = () => {
  const dispatch = useAppDispatch();
  return useMutation(
    ["customer/createUser"],
    ({
      phone,
      name,
      email,
      referCode,
    }: {
      phone: string;
      name: string;
      email: string;
      referCode: string;
    }) =>
      api.post<Customer>({
        url: "customer/register",
        data:
          referCode.length > 0
            ? {
                phone,
                name,
                email,
                referCode,
              }
            : {
                phone,
                name,
                email,
              },
      }),
    {
      onSuccess: (customer) => {
        dispatch(setCustomer(customer));
        dispatch(setAuthStage(AuthStage.PROFILE_COMPLETE));
        localStorage.setItem(CUSTOMER_TOKEN, customer.token);
      },
    }
  );
};

export const useUpdateUser = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  return useMutation(
    ["customer/updateUser"],
    ({ email, name }: { name: string; email: string }) =>
      api.put<Customer>({
        url: "customer",
        data: {
          name,
          email,
        },
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: (customer) => {
        dispatch(setCustomer(customer));
        dispatch(setAuthStage(AuthStage.PROFILE_COMPLETE));
      },
    }
  );
};

export const useValidateReferCode = () => {
  const dispatch = useAppDispatch();

  return useMutation(
    ["customer/validateRefer"],
    ({ referCode }: { referCode: string }) => {
      return api.post<boolean>({
        url: "customer/referValidate",
        data: {
          referCode,
        },
      });
    },
    {
      onSuccess: (isValid, { referCode }) => {
        isValid
          ? referCode
            ? dispatch(setReferCode(referCode))
            : dispatch(setReferCode(""))
          : dispatch(setReferCode(null));
      },
    }
  );
};
