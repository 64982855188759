import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductState, Product } from "./product.types";

const initialState: ProductState = {};

export const productSlice = createSlice({
  initialState,
  name: "product",
  reducers: {
    setProducts: (state: ProductState, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    resetProducts: (state: ProductState) => {
      state.products = undefined;
    },
  },
});

export const { resetProducts, setProducts } = productSlice.actions;
