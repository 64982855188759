import { Navigate, Outlet } from "react-router-dom";
import { CUSTOMER_TOKEN } from "../../constants/constants";

function ProtectedRoute() {
  const token = localStorage.getItem(CUSTOMER_TOKEN);

  return token ? <Outlet /> : <Navigate to="/splash" replace />;
}

export default ProtectedRoute;
