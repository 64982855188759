import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorSnackbar } from "../../hooks/error_snack";
import { useGetAllOrders } from "../../redux/features/order/api/query";
import { setSelectedOrder } from "../../redux/features/order/order.slice";
import {
  Order,
  PAYMENT_TYPE,
  STATUS,
} from "../../redux/features/order/order.types";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import AWAITING_PICKUP from "../../assets/delivery_scheduled.svg";
import DELIVERY_SCHEDULED from "../../assets/awaiting_pickup.svg";
import ORDER_IN_PROGRESS from "../../assets/order_in_progress.svg";
import READY_FOR_DELIVERY from "../../assets/ready_for_delivery.svg";
import NO_ONGOING from "../../assets/no_ongoing.png";
import { IoIosArrowForward } from "react-icons/io";
import { format } from "date-fns";
import {
  calculateTitleForOrderStatus,
  getOrderStatus,
} from "../../utils/order.util";

function OngoingList() {
  const calculateImage = (order: Order) => {
    switch (order.status) {
      case STATUS.PENDING:
      case STATUS.PICKED_UP:
        return AWAITING_PICKUP;
      case STATUS.CLEANING:
        return ORDER_IN_PROGRESS;
      case STATUS.READY:
        return calculateTitleForOrderStatus(getOrderStatus(order)) ===
          "Ready for Delivery"
          ? READY_FOR_DELIVERY
          : DELIVERY_SCHEDULED;
      default:
        return ORDER_IN_PROGRESS;
    }
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [ongoingOrders, setOngoingOrders] = useState<Order[]>();
  const { orders } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();
  const {
    isLoading: getAllOrdersLoading,
    isError: hasGetAllOrdersError,
    error: getAllOrdersError,
  } = useGetAllOrders();
  const navigate = useNavigate();

  const handleOrderClicked = (order: Order) => {
    dispatch(setSelectedOrder(order));
    navigate(`ongoing/${order.id}`);
  };

  //* Filter all the ongoing orders from the list
  useEffect(() => {
    if (orders) {
      setOngoingOrders(
        orders.filter((order) =>
          [STATUS.CLEANING, STATUS.READY, STATUS.PICKED_UP].includes(
            order.status
          )
        )
      );
    }
  }, [orders]);

  useErrorSnackbar({ isError: hasGetAllOrdersError, error: getAllOrdersError });

  return (
    <div>
      <Box sx={{ backgroundColor: "white" }}>
        {getAllOrdersLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <div>
            {ongoingOrders ? (
              !ongoingOrders.length ? (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ minHeight: "85vh" }}
                >
                  <img
                    alt={"No ongoing orders"}
                    src={NO_ONGOING}
                    style={{
                      width: matches ? "85vw" : "50vw",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography>You do not have any Ongoing order</Typography>
                </Grid>
              ) : (
                <div>
                  {ongoingOrders.map((o, index) => {
                    return (
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={12} textAlign="center">
                          <Box
                            onClick={() => handleOrderClicked(o)}
                            sx={{
                              marginLeft: "8px",
                              display: "inline-flex",
                              cursor: "pointer",
                            }}
                          >
                            <Card
                              sx={{
                                my: "12px",
                                mr: "5px",
                                width: matches ? "85vw" : "50vw",
                                p: "10px",
                                backgroundImage:
                                  "linear-gradient(to left, #0a696e, #108288)",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "0px 0px 5px 0px",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      height: "30px",
                                      width: "30px",
                                      alignSelf: "center",
                                      marginRight: "12px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <img
                                      src={calculateImage(o)}
                                      style={{ height: "18px", width: "18px" }}
                                      alt="avatar"
                                    />
                                  </Avatar>

                                  <Box
                                    sx={{
                                      textAlign: "start",
                                    }}
                                  >
                                    <Typography
                                      m="0"
                                      sx={{ fontWeight: "500", color: "white" }}
                                    >
                                      {calculateTitleForOrderStatus(
                                        getOrderStatus(o)
                                      )}
                                    </Typography>
                                    <Typography
                                      m="0"
                                      sx={{
                                        fontSize: "14px",
                                        color: "#00CE50",
                                      }}
                                    >
                                      Ongoing
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box
                                  style={{
                                    alignSelf: "center",
                                  }}
                                >
                                  <IoIosArrowForward color="white" />
                                </Box>
                              </Box>
                              <Divider />
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "10px 0px 0px 0px",
                                }}
                              >
                                <Typography
                                  m="0"
                                  sx={{ fontSize: "14px", color: "white" }}
                                >
                                  ID: # {o.id}
                                </Typography>
                                <Typography
                                  m="0"
                                  sx={{ color: "white", fontSize: "14px" }}
                                >
                                  {o.paymentType !== PAYMENT_TYPE.NO_PAYMENT
                                    ? "Delivery on " +
                                      format(
                                        new Date(o.deliveryDate),
                                        "MMM dd, yyyy"
                                      )
                                    : "Est Delivery on " +
                                      (o.deliveryDate
                                        ? format(
                                            new Date(o.deliveryDate),
                                            "MMM dd, yyyy"
                                          )
                                        : format(
                                            new Date(o.pickupDate),
                                            "MMM dd, yyyy"
                                          ))}
                                </Typography>
                              </Box>
                            </Card>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              )
            ) : (
              <div />
            )}
          </div>
        )}
      </Box>
    </div>
  );
}

export default OngoingList;
