import { Grid, Typography } from "@mui/material";
import { AiFillWarning } from "react-icons/ai";

function MapError({ title }: { title: string }) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <AiFillWarning size="45px" color="#F92335" />
      <Typography>{title}</Typography>
    </Grid>
  );
}

export default MapError;
