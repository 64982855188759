export interface Customer {
  id: number;
  customerId: number;
  name: string;
  phone: string;
  email: string;
  token: string;
  referCode: string;
  role: String;
  currentAddressID?: number;
}

export enum AuthStage {
  DEFAULT = "default",
  OTP_SENT = "otp_sent",
  OTP_VERIFIED_NO_USER = "otp_verified_no_user",
  OTP_VERIFIED_WITH_USER = "otp_verified_with_user",
  PROFILE_COMPLETE = "profile_complete",
}

export interface CustomerState {
  customer?: Customer;
  authStage: AuthStage;
  otpPhone?: string;
  referCode: string | null;
}
