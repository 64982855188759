import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import DismissAction from "../../../components/common/dismiss_action";
import { PRIMARY_COLOR } from "../../../constants/theme";
import { useAuthStage } from "../../../hooks/auth_stage";
import { useErrorSnackbar } from "../../../hooks/error_snack";
import {
  useSendOTP,
  useVerifyOTP,
} from "../../../redux/features/customer/api/mutation";
import { useAppSelector } from "../../../redux/store";
import { PaperWrapper, VerifyGridWrapper } from "./verify_page.style";

function Verify() {
  const { otpPhone } = useAppSelector((s) => s.customer);
  const { enqueueSnackbar } = useSnackbar();

  const [otp, setOtp] = useState(new Array(4).fill(""));

  const handleOtpChange = (element: any, index: number) => {
    if (isNaN(element.value)) return false;

    setOtp([
      ...otp.map((d, idx) => (idx === index ? element.value.toString() : d)),
    ]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const {
    isLoading: isSendOtpLoading,
    isSuccess: isSendOtpSuccess,
    mutate: sendOTP,
  } = useSendOTP();

  const handleSubmit = () => {
    const newOTP = otp.join("");
    otpPhone &&
      verifyOTP({
        otp: newOTP,
        phone: otpPhone,
      });
  };

  const { isLoading, isError, error, mutate: verifyOTP } = useVerifyOTP();

  useEffect(() => {
    if (isSendOtpSuccess) {
      enqueueSnackbar("OTP Resend Successfully", {
        variant: "success",
        action: DismissAction,
      });
    }
  }, [isSendOtpSuccess, enqueueSnackbar]);

  useAuthStage();
  useErrorSnackbar({ isError: isError, error: error });

  return (
    <VerifyGridWrapper
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <PaperWrapper elevation={3}>
        <Typography
          textAlign="center"
          sx={{ fontWeight: "medium", fontSize: "1.5rem" }}
        >
          One-time verification
        </Typography>
        <Typography
          textAlign="center"
          component="div"
          sx={{ fontSize: "14px" }}
        >
          Please enter the one time verification code shared on
          <Box textAlign="center" fontWeight="bold" display="inline">
            {" "}
            +91{otpPhone}
          </Box>
        </Typography>

        <form style={{ marginTop: "10px" }}>
          <div style={{ textAlign: "center" }}>
            {otp.map((data, index) => {
              return (
                <input
                  aria-label="OTP"
                  className="otp-field"
                  type="tel"
                  name="otp"
                  style={{
                    width: "10%",
                    height: "30px",
                    fontSize: "18px",
                    margin: "10px",
                    textAlign: "center",
                  }}
                  maxLength={1}
                  key={index}
                  value={data}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })}
          </div>
          <Box textAlign="center" sx={{ marginTop: "15px" }}>
            <Button
              variant="text"
              onClick={() => {
                sendOTP({ phone: `${otpPhone}` });
              }}
            >
              {!isSendOtpLoading && <u style={{ fontSize: "12px" }}>Resend</u>}
            </Button>
          </Box>
          <Box textAlign="center" sx={{ marginTop: "20px" }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                disabled={otp.join("").length !== 4 ? true : false}
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  width: "70%",
                  "&.Mui-disabled": {
                    opacity: 0.65,
                    color: "white",
                    backgroundColor: PRIMARY_COLOR,
                  },
                }}
              >
                Verify
              </Button>
            )}
          </Box>
        </form>
      </PaperWrapper>
    </VerifyGridWrapper>
  );
}

export default Verify;
