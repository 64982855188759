import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Address, AddressState, Location, LocationStage } from "./address.type";

const initialState: AddressState = {
  locationStage: LocationStage.DEFAULT,
};

export const addressSlice = createSlice({
  initialState,
  name: "address",
  reducers: {
    setLocationStage: (
      state: AddressState,
      action: PayloadAction<LocationStage>
    ) => {
      state.locationStage = action.payload;
    },
    setServiceableLocations: (
      state: AddressState,
      action: PayloadAction<Location[]>
    ) => {
      state.serviceableLocations = action.payload;
    },
    setCustomerAddresses: (
      state: AddressState,
      action: PayloadAction<Address[]>
    ) => {
      state.customerAddresses = action.payload;
    },
    setDefaultCustomerAddress: (
      state: AddressState,
      action: PayloadAction<Address>
    ) => {
      state.defaultCustomerAddress = action.payload;
    },
    setCurrentLocation: (
      state: AddressState,
      action: PayloadAction<Address>
    ) => {
      state.currentLocation = action.payload;
    },
    resetDefaultCustomerAddress: (state: AddressState) => {
      state.defaultCustomerAddress = undefined;
    },
    resetLocationStage: (state: AddressState) => {
      state.locationStage = LocationStage.DEFAULT;
    },
    resetServiceableLocations: (state: AddressState) => {
      state.serviceableLocations = undefined;
    },
    resetCustomerAddresses: (state: AddressState) => {
      state.customerAddresses = undefined;
    },
    resetCurrentLocation: (state: AddressState) => {
      state.currentLocation = undefined;
    },
  },
});

export const {
  resetCustomerAddresses,
  resetLocationStage,
  setCustomerAddresses,
  setLocationStage,
  resetCurrentLocation,
  resetDefaultCustomerAddress,
  resetServiceableLocations,
  setCurrentLocation,
  setDefaultCustomerAddress,
  setServiceableLocations,
} = addressSlice.actions;
