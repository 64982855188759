import {
  Grid,
  CircularProgress,
  Card,
  Typography,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { AiTwotoneCalendar } from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Appbar from "../../../components/appbar/appbar";
import BottomBar from "../../../components/appbar/bottom_bar";
import { orderCancelReasonList } from "../../../constants/constants";
import { useErrorSnackbar } from "../../../hooks/error_snack";
import { useSetOrderId } from "../../../hooks/order_id";
import { useCancelOrder } from "../../../redux/features/order/api/mutation";
import { useGetAllOrders } from "../../../redux/features/order/api/query";
import { useAppSelector } from "../../../redux/store";

function UpcomingOrderDetails() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedOrder } = useAppSelector((s) => s.order);
  const { customerAddresses, defaultCustomerAddress } = useAppSelector(
    (s) => s.address
  );
  const navigate = useNavigate();
  const {
    isLoading: getAllOrdersLoading,
    isError: hasGetAllOrdersError,
    error: getAllOrdersError,
  } = useGetAllOrders();

  const {
    isLoading: cancelOrderLoading,
    isSuccess: cancelOrderSuccess,
    isError: hasCancelOrderError,
    error: cancelOrderError,
    reset: resetCancelOrder,
    mutate: cancelOrder,
  } = useCancelOrder();

  const [cancelPopupOpen, setCancelPopupOpen] = useState<boolean>(false);
  const [selectedCancelReason, setSelectedCancelReason] = useState<string>(
    orderCancelReasonList[0]
  );

  //* Hook to set the selected order when it is not available from redux store
  useSetOrderId();

  //* Close the popup whenever we have any action completed
  useEffect(() => {
    if (hasCancelOrderError || cancelOrderSuccess) {
      setCancelPopupOpen(false);
      resetCancelOrder();
      if (cancelOrderSuccess) navigate(-1);
    }
  }, [hasCancelOrderError, cancelOrderSuccess, navigate, resetCancelOrder]);

  useErrorSnackbar({ isError: hasGetAllOrdersError, error: getAllOrdersError });
  useErrorSnackbar({ isError: hasCancelOrderError, error: cancelOrderError });

  const handleCancel = () => {
    if (selectedOrder) {
      cancelOrder({
        orderId: selectedOrder.id,
        reason: selectedCancelReason,
      });
    }
  };

  const handleReschedulePickup = () => navigate("reschedule");

  return (
    <div>
      <Appbar currentTabIndex={2} />
      <Box sx={{ backgroundColor: "white", margin: "20px 10px 0px 10px" }}>
        {getAllOrdersLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : !selectedOrder ? (
          <div>Order not found</div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Box
                sx={{
                  display: "inline-flex",
                  width: matches ? "85vw" : "50vw",
                }}
              >
                <Card
                  elevation={0}
                  sx={{
                    m: "12px",
                    width: matches ? "85vw" : "50vw",
                    p: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 0px 5px 0px",
                    }}
                  >
                    <Typography
                      sx={{ color: "#00C4CC", display: "flex", width: "100%" }}
                    >
                      UPCOMING SERVICE
                    </Typography>
                    <Typography sx={{ color: "gray", alignSelf: "center" }}>
                      #{selectedOrder.id}
                    </Typography>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        verticalAlign: "top",
                        display: "inline-block",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AiTwotoneCalendar size={20} color="#000000" />
                      <Box
                        sx={{
                          height: "60px",
                          width: "2px",
                          backgroundColor: "black",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      ></Box>
                    </div>
                    <Box
                      sx={{
                        marginLeft: "5px",
                        textAlign: "start",
                      }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        Pick Up:{" "}
                        {format(
                          new Date(selectedOrder.pickupDate),
                          "MMM dd, yyyy"
                        )}{" "}
                        | {selectedOrder.pickupTime}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        verticalAlign: "top",
                        display: "inline-block",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <HiLocationMarker size={20} color="#000000" />
                    </div>
                    <Box
                      sx={{
                        marginLeft: "5px",
                        textAlign: "start",
                      }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        {!selectedOrder?.address ||
                        selectedOrder?.address.toLowerCase() === "unknown"
                          ? !defaultCustomerAddress
                            ? customerAddresses
                              ? customerAddresses[0].line1
                              : "Unknown"
                            : defaultCustomerAddress?.line1 ?? ""
                          : selectedOrder?.address}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ mt: "16px", display: "flex" }}>
                    <Button
                      variant="contained"
                      sx={{ flexGrow: "1", mr: "5px" }}
                      onClick={() => setCancelPopupOpen(true)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ flexGrow: "3", ml: "5px" }}
                      onClick={handleReschedulePickup}
                    >
                      Re-schedule
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#e6e6e6",
                      height: "2px",
                      my: "15px",
                    }}
                  />
                  <TextField
                    disabled
                    id="outlined"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root.Mui-disabled": {
                        "& > fieldset": {
                          borderColor: "#00C4CC",
                        },
                      },
                    }}
                    label="Note"
                    value={selectedOrder.notes ?? ""}
                  />
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Dialog
        open={cancelPopupOpen}
        // onClose={() => setCancelPopupOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Tell us why you want to cancel? Let us know how can we help"}
        </DialogTitle>
        <DialogContent>
          <Box>
            <FormGroup>
              {orderCancelReasonList.map((item, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox checked={selectedCancelReason === item} />
                    }
                    onChange={() => {
                      setSelectedCancelReason(item);
                    }}
                    label={item}
                  />
                );
              })}
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelPopupOpen(false)}>Go back</Button>
          {cancelOrderLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleCancel} autoFocus>
              Cancel Order
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Box sx={{ height: "100px" }}></Box>
      {matches && <BottomBar currentTabIndex={2} />}
    </div>
  );
}

export default UpcomingOrderDetails;
