import { Slot } from "../redux/features/order/order.types";

export const CUSTOMER_TOKEN = "customer_token";

export const callNumber: string = "8080454454";
export const emailId: string = "care@lavado.in";
export const whatsappNumber: string = "9137822717";

export const storeLink: string = "http://onelink.to/gk6d72";

export const libraries: any[] = ["places"];

export const availableSlots: Slot[] = [
  {
    startTime: 9,
    endTime: 11,
    slotDisplay: "9am - 11am",
  },
  {
    startTime: 11,
    endTime: 13,
    slotDisplay: "11am - 1pm",
  },
  {
    startTime: 13,
    endTime: 15,
    slotDisplay: "1pm - 3pm",
  },
  {
    startTime: 15,
    endTime: 17,
    slotDisplay: "3pm - 5pm",
  },
  {
    startTime: 17,
    endTime: 19,
    slotDisplay: "5pm - 7pm",
  },
  {
    startTime: 19,
    endTime: 21,
    slotDisplay: "7pm - 9pm",
  },
];

export const orderDisplayStatus = ["Ongoing", "Upcoming", "Completed"];

export const orderCancelReasonList = [
  "I changed my mind",
  "Something has come up",
  "Placed this order by mistake",
];
