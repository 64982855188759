import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorSnackbar } from "../../hooks/error_snack";
import { useGetAllOrders } from "../../redux/features/order/api/query";
import { setSelectedOrder } from "../../redux/features/order/order.slice";
import { Order, STATUS } from "../../redux/features/order/order.types";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import NO_UPCOMING from "../../assets/no_upcoming_order.png";
import { IoIosArrowForward } from "react-icons/io";
import { format } from "date-fns";
import AwaitingPickup from "../../assets/awaiting_pickup.svg";

function UpcomingList() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [upcomingOrders, setUpcomingOrders] = useState<Order[]>();
  const { orders } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();
  const {
    isLoading: getAllOrdersLoading,
    isError: hasGetAllOrdersError,
    error: getAllOrdersError,
  } = useGetAllOrders();
  const navigate = useNavigate();

  const handleOrderClicked = (order: Order) => {
    dispatch(setSelectedOrder(order));
    navigate(`upcoming/${order.id}`);
  };

  //* Filter all the ongoing orders from the list
  useEffect(() => {
    if (orders) {
      setUpcomingOrders(
        orders.filter((order) => [STATUS.PENDING].includes(order.status))
      );
    }
  }, [orders]);

  useErrorSnackbar({ isError: hasGetAllOrdersError, error: getAllOrdersError });

  return (
    <div>
      <Box sx={{ backgroundColor: "white" }}>
        {getAllOrdersLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <div>
            {upcomingOrders ? (
              !upcomingOrders.length ? (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ minHeight: "85vh" }}
                >
                  <img
                    alt={"no upcoming icon"}
                    src={NO_UPCOMING}
                    style={{
                      width: matches ? "85vw" : "50vw",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography>You do not have any Upcoming order</Typography>
                </Grid>
              ) : (
                <div>
                  {upcomingOrders.map((o, index) => {
                    return (
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={12} textAlign="center">
                          <Box
                            onClick={() => handleOrderClicked(o)}
                            sx={{
                              marginLeft: "8px",
                              display: "inline-flex",
                              cursor: "pointer",
                            }}
                          >
                            <Card
                              sx={{
                                my: "12px",
                                mr: "5px",
                                width: matches ? "85vw" : "50vw",
                                p: "10px",
                                backgroundColor: "#f7f5f5",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "0px 0px 5px 0px",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  <img
                                    src={AwaitingPickup}
                                    alt={"pickup icon"}
                                    style={{
                                      height: "40px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      textAlign: "start",
                                    }}
                                  >
                                    <Typography
                                      m="0"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      New Booking
                                    </Typography>
                                    <Typography m="0" sx={{ fontSize: "14px" }}>
                                      Pickup Date:{" "}
                                      {format(
                                        new Date(o.pickupDate),
                                        "MMM dd, yyyy"
                                      )}{" "}
                                      | {o.pickupTime}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box
                                  style={{
                                    alignSelf: "center",
                                  }}
                                >
                                  <IoIosArrowForward />
                                </Box>
                              </Box>
                              <Divider />
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "10px 0px 0px 0px",
                                }}
                              >
                                <Typography
                                  m="0"
                                  sx={{ fontSize: "14px", color: "gray" }}
                                >
                                  Order ID: # {o.id}
                                </Typography>
                                <Typography
                                  m="0"
                                  sx={{ color: "gray", fontSize: "14px" }}
                                >
                                  Awaiting Pickup
                                </Typography>
                              </Box>
                            </Card>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              )
            ) : (
              <div />
            )}
          </div>
        )}
      </Box>
    </div>
  );
}

export default UpcomingList;
