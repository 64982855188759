import { Paper, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { CUSTOMER_TOKEN } from "../../constants/constants";
import {
  resetCustomerAddresses,
  resetDefaultCustomerAddress,
  resetLocationStage,
} from "../../redux/features/address/address.slice";
import {
  resetAuthStage,
  resetCustomer,
  resetOTPPhone,
  resetReferCode,
} from "../../redux/features/customer/customer.slice";
import {
  resetCreateOrderDetails,
  resetOrders,
  resetRescheduleOrderDetails,
  resetSelectedOrder,
} from "../../redux/features/order/order.slice";

function ProfileItemCard({
  title,
  subtitle,
  to,
  color = "black",
}: {
  title: string;
  subtitle: string;
  to: string;
  color?: string;
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    if (title === "Logout") {
      localStorage.removeItem(CUSTOMER_TOKEN);
      queryClient.clear();
      queryClient.removeQueries({
        stale: true
      });
      dispatch(resetAuthStage());
      dispatch(resetCustomer());
      dispatch(resetCustomerAddresses());
      dispatch(resetOTPPhone());
      dispatch(resetReferCode());
      dispatch(resetLocationStage());
      dispatch(resetOrders());
      dispatch(resetCreateOrderDetails());
      dispatch(resetDefaultCustomerAddress());
      dispatch(resetRescheduleOrderDetails());
      dispatch(resetSelectedOrder());
    }
  };

  return (
    <Link
      to={to}
      onClick={handleLogout}
      style={{
        textDecoration: "none",
        cursor: "pointer",
      }}
    >
      <Paper elevation={0} sx={{ padding: "10px", height: "65px" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "16px", color: color }}>
          {title}
        </Typography>
        <Typography sx={{ ontSize: "14px", color: "gray" }}>
          {subtitle}
        </Typography>
      </Paper>
    </Link>
  );
}

export default ProfileItemCard;
