import { Box, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Offer } from "../../redux/features/offers/offers.types";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function OffersSection({
  isLoading,
  offers,
  onClick,
  matches,
}: {
  isLoading: boolean;
  offers: Offer[];
  onClick: Function;
  matches: boolean;
}) {
  return isLoading ? (
    <CircularProgress />
  ) : (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Carousel
        axis="horizontal"
        autoPlay
        dynamicHeight={true}
        centerMode={true}
        infiniteLoop={true}
        showThumbs={false}
        width={matches ? "90vw" : "48vw"}
        centerSlidePercentage={85}
        emulateTouch={true}
        onClickItem={(index) => onClick()}
      >
        {offers?.map((item, index) => {
          return (
            <Box
              key={index}
              component="img"
              sx={{ paddingX: "10px", cursor: "pointer" }}
              src={(process.env.REACT_APP_API_URL ?? "") + item.url}
            ></Box>
          );
        })}
      </Carousel>
    </Grid>
  );
}

export default OffersSection;
