import {
  Grid,
  Card,
  Typography,
  Button,
  TextField,
  AppBar,
  Dialog,
  Divider,
  Toolbar,
  Slide,
  CircularProgress,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import { useErrorSnackbar } from "../../../hooks/error_snack";
import { useValidateCoupon } from "../../../redux/features/order/api/mutation";
import { setCreateOrderDetails } from "../../../redux/features/order/order.slice";
import { useAppSelector, useAppDispatch } from "../../../redux/store";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CouponSelector() {
  const [couponDialogOpen, setCouponDialogOpen] = useState<boolean>(false);
  const { createOrderDetails } = useAppSelector((s) => s.order);
  const { coupons } = useAppSelector((s) => s.coupon);
  const dispatch = useAppDispatch();

  const {
    isLoading: validateCouponLoading,
    data: isCouponValid,
    isSuccess: validateCouponCompleted,
    isError: validateCouponFailed,
    mutate: validateCoupon,
    reset: resetValidateCoupon,
  } = useValidateCoupon();

  const handleCouponTextChanged = (code: string | null | undefined) => {
    dispatch(
      setCreateOrderDetails({
        ...createOrderDetails,
        couponText: code ?? "",
      })
    );
  };
  const handleRemoveCoupon = () =>
    dispatch(
      setCreateOrderDetails({
        ...createOrderDetails,
        couponText: "",
        isCouponApplied: false,
      })
    );

  const handleVerifyCoupon = (code: string | undefined) =>
    code?.trim().length &&
    validateCoupon({
      couponCode: code,
    });

  useErrorSnackbar({
    isError: validateCouponFailed,
    error: "Invalid coupon",
  });

  useEffect(() => {
    if (validateCouponCompleted) {
      if (isCouponValid) {
        dispatch(
          setCreateOrderDetails({
            ...createOrderDetails,
            isCouponApplied: true,
          })
        );
      } else {
        dispatch(
          setCreateOrderDetails({
            ...createOrderDetails,
            isCouponApplied: false,
          })
        );
      }
      resetValidateCoupon();
    }
  }, [
    createOrderDetails,
    dispatch,
    isCouponValid,
    resetValidateCoupon,
    validateCouponCompleted,
  ]);

  return (
    <>
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            border: "1px solid #e8e8e8",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{ fontWeight: "500", fontSize: "16px", flexGrow: "1" }}
            >
              Apply Coupon
            </Typography>
            <Button
              sx={{
                textDecoration: "underline",
                color: "secondary.main",
                "&:hover": {
                  backgroundColor: "white",
                  textDecoration: "underline",
                },
              }}
              onClick={() => setCouponDialogOpen(true)}
            >
              Available Coupon
            </Button>
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {createOrderDetails.isCouponApplied ? (
              <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                <TiTick color="green" />{" "}
                <Typography>{createOrderDetails.couponText}</Typography>
              </Box>
            ) : (
              <TextField
                label="Coupon"
                id="filled-start-adornment"
                sx={{ m: 1, width: "100%" }}
                variant="standard"
                value={createOrderDetails.couponText}
                onChange={(e) => handleCouponTextChanged(e.target.value)}
              />
            )}

            {createOrderDetails.isCouponApplied ? (
              <Button onClick={handleRemoveCoupon}>Remove</Button>
            ) : validateCouponLoading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={() =>
                  handleVerifyCoupon(createOrderDetails.couponText)
                }
              >
                Apply
              </Button>
            )}
          </Box>
        </Card>
      </Grid>
      <Dialog
        fullScreen
        open={couponDialogOpen}
        TransitionComponent={Transition}
        sx={{ overflowX: "hidden" }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ backgroundColor: "white" }}>
            <Typography
              sx={{ ml: 2, flex: 1, color: "black" }}
              variant="h6"
              component="div"
            >
              Apply Coupon
            </Typography>{" "}
            <Button
              sx={{ color: "red" }}
              onClick={() => setCouponDialogOpen(false)}
              aria-label="close"
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Box>
          <Box
            sx={{
              textAlign: "center",
              py: "10px",
              color: "white",
              fontWeight: "bold",
              backgroundColor: "#00C4CC",
            }}
          >
            Available Coupons
          </Box>
          <Grid container sx={{ m: "15px" }}>
            {coupons?.map((item, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={11}
                  md={4}
                  sx={{ border: "1px solid gray", padding: "15px" }}
                >
                  <Box
                    sx={{
                      display: "inline-flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        border: "1px dotted gray",
                        padding: "8px",
                      }}
                    >
                      {item.code}
                    </Typography>
                    <Button
                      sx={{
                        color: "primary.main",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                      }}
                      onClick={() => {
                        handleCouponTextChanged(item.code);
                        handleVerifyCoupon(item.code);
                        setCouponDialogOpen(false);
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                  <Divider sx={{ marginY: "8px" }} />
                  <Typography sx={{ fontSize: "15px", color: "gray" }}>
                    {item.description}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Dialog>
    </>
  );
}

export default CouponSelector;
