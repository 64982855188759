import { Box, Card, Divider, Typography } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Offer } from "../../redux/features/offers/offers.types";

function OfferCard({ item }: { item: Offer }) {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        padding: "10px",
        backgroundColor: "#fafafa",
      }}
      onClick={() => {
        navigate(`/offers/${item.id}`);
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 0px 5px 0px",
          cursor: "pointer",
        }}
      >
        <Box>
          <Typography m="0" sx={{ fontWeight: "bold" }}>
            {item.title}
          </Typography>
          <Typography m="0" sx={{ fontSize: "14px" }}>
            {item.description?.substring(0, 30) + "..."}
          </Typography>
        </Box>
        <Box
          style={{
            alignSelf: "center",
          }}
        >
          <IoIosArrowForward />
        </Box>
      </Box>
      <Divider />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0px 0px 0px",
        }}
      >
        <Typography m="0" sx={{ fontSize: "14px", color: "gray" }}>
          Code: {item.code}
        </Typography>
        <Typography m="0" sx={{ color: "gray" }}>
          Validity: {item.expiry}
        </Typography>
      </Box>
    </Card>
  );
}

export default OfferCard;
