import { useQuery } from "@tanstack/react-query";
import { nonChanging30Mins } from "../../../../config/query.config";
import { APIService } from "../../../../utils/api.service";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setProducts } from "../product.slice";
import { Product } from "../product.types";

const api = new APIService();

export const useGetProductsForService = () => {
  const dispatch = useAppDispatch();
  const { services, currentServiceId } = useAppSelector((s) => s.service);
  return useQuery(
    ["product/getAllForService", currentServiceId],
    () =>
      api.get<Product[]>({
        url: `product/${currentServiceId}`,
      }),
    {
      ...nonChanging30Mins,
      enabled: !!services && !!currentServiceId,
      onSuccess: (products) => {
        dispatch(setProducts(products));
      },
    }
  );
};
