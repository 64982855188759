import { Avatar, Box, Card, Grid, Typography } from "@mui/material";
import { Order, STATUS } from "../../redux/features/order/order.types";
import AWAITING_PICKUP from "../../assets/delivery_scheduled.svg";
import DELIVERY_SCHEDULED from "../../assets/awaiting_pickup.svg";
import ORDER_IN_PROGRESS from "../../assets/order_in_progress.svg";
import READY_FOR_DELIVERY from "../../assets/ready_for_delivery.svg";
import {
  calculateTitleForOrderStatus,
  getOrderStatus,
} from "../../utils/order.util";
import { useNavigate } from "react-router-dom";
import { setSelectedOrder } from "../../redux/features/order/order.slice";
import { useAppDispatch } from "../../redux/store";

function OngoingSingleItemHome({
  matches,
  order,
}: {
  matches: boolean;
  order: Order;
}) {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const calculateImage = (order: Order) => {
    switch (order.status) {
      case STATUS.PENDING:
      case STATUS.PICKED_UP:
        return AWAITING_PICKUP;
      case STATUS.CLEANING:
        return ORDER_IN_PROGRESS;
      case STATUS.READY:
        return calculateTitleForOrderStatus(getOrderStatus(order)) ===
          "Ready for Delivery"
          ? READY_FOR_DELIVERY
          : DELIVERY_SCHEDULED;
      default:
        return ORDER_IN_PROGRESS;
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          // marginLeft: "8px",
          display: "inline-flex",

          // cursor: "pointer",
        }}
      >
        <Card
          component="div"
          sx={{
            my: "12px",
            width: matches ? "90vw" : "48vw",
            p: "10px",
            backgroundColor: "#4D426C",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(setSelectedOrder(order));

            if (order.status === STATUS.PENDING) {
              navigate(`/orders/upcoming/${order.id}`);
            } else {
              navigate(`/orders/ongoing/${order.id}`);
            }
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 0px 5px 0px",
            }}
          >
            <Box
              style={{
                display: "flex",
                width: "90%",
              }}
            >
              <Avatar
                sx={{
                  height: "30px",
                  width: "30px",
                  alignSelf: "center",
                  marginRight: "12px",
                  backgroundColor: "white",
                }}
              >
                <img
                  src={calculateImage(order)}
                  style={{
                    // height: "18px",
                    width: "18px",
                    height: "120px",
                  }}
                  alt="avatar"
                />
              </Avatar>

              <Box
                sx={{
                  textAlign: "start",
                }}
              >
                <Typography m="0" sx={{ fontWeight: "500", color: "white" }}>
                  {order.status.toLowerCase() === "pending"
                    ? "Awaiting pickup"
                    : order.status}
                </Typography>
                <Typography
                  m="0"
                  sx={{
                    fontSize: "14px",
                    color: "#00CE50",
                  }}
                >
                  {order.status === STATUS.PENDING ? "Upcoming" : "Ongoing"}
                </Typography>
              </Box>
            </Box>

            <Box
              style={{
                alignSelf: "center",
              }}
            >
              <Typography
                sx={{ color: "white", fontSize: "14px", fontWeight: "500" }}
              >
                TRACK
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
    </Grid>
  );
}

export default OngoingSingleItemHome;
