import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "./constants/theme";
import { CUSTOMER_TOKEN } from "./constants/constants";
import { useGetAllAddressForCustomer } from "./redux/features/address/api/query";
import { useGetAllCoupons } from "./redux/features/coupon/api/query";
import { useGetCustomerData } from "./redux/features/customer/api/query";
import { useGetAllOffers } from "./redux/features/offers/api/query";
import { useGetAllServices } from "./redux/features/service/api/query";
import WebRoutes from "./routes/routes";
import { useGetAllOrders } from "./redux/features/order/api/query";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
    },
  });

  const token = localStorage.getItem(CUSTOMER_TOKEN);
  //* Queries that should be active throughout the app
  useGetCustomerData({ token });
  useGetAllServices();
  useGetAllAddressForCustomer();
  useGetAllOrders();
  useGetAllOffers();
  useGetAllCoupons();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={5}
        preventDuplicate
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={5000}
      >
        <WebRoutes />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
