import { Grid, Typography, Button } from "@mui/material";
import React from "react";
import { availableSlots } from "../../../constants/constants";
import { setRescheduleOrderDetails } from "../../../redux/features/order/order.slice";
import { Slot } from "../../../redux/features/order/order.types";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { formatDateForOrder } from "../../../utils/date.util";

function RTimeSlotSelector() {
  const { rescheduleOrderDetails } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();

  const handleSlotChanged = (slot: Slot) =>
    slot &&
    dispatch(
      setRescheduleOrderDetails({
        ...rescheduleOrderDetails,
        rescheduleSlot: slot,
      })
    );

  const isSlotDisabled = (slot: Slot): boolean => {
    if (!rescheduleOrderDetails.rescheduleDate) return true;
    const selectedDate = new Date(rescheduleOrderDetails.rescheduleDate);
    const now = new Date();
    if (formatDateForOrder(selectedDate) === formatDateForOrder(now)) {
      return now.getHours() >= slot.startTime;
    } else {
      return false;
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
          Select suitable time slot
        </Typography>
      </Grid>
      {availableSlots.map((item, index) => {
        return (
          <Grid item xs={true} md={1.5} key={index}>
            <Button
              disableRipple
              sx={{
                px: "16px",
                py: "8px",
                border: "1px solid #e6e6e6",
                width: "150px",
                color:
                  rescheduleOrderDetails.rescheduleSlot?.slotDisplay ===
                  item.slotDisplay
                    ? "white"
                    : "black",
                backgroundColor:
                  rescheduleOrderDetails.rescheduleSlot?.slotDisplay ===
                  item.slotDisplay
                    ? "#00C4CC"
                    : "white",
                "&:hover": {
                  backgroundColor:
                    rescheduleOrderDetails.rescheduleSlot?.slotDisplay ===
                    item.slotDisplay
                      ? "#00C4CC"
                      : "white",
                },
              }}
              onClick={() => {
                handleSlotChanged(item);
              }}
              disabled={isSlotDisabled(item)}
            >
              {item.slotDisplay}
            </Button>
          </Grid>
        );
      })}
    </>
  );
}

export default RTimeSlotSelector;
