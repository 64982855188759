import { Avatar, Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_TOKEN } from "../../constants/constants";
import { useAppSelector } from "../../redux/store";

import Logo from "../../assets/lavado_default_logo.png";

function Splash() {
  const { customer } = useAppSelector((s) => s.customer);
  const navigate = useNavigate();
  const token = localStorage.getItem(CUSTOMER_TOKEN);

  useEffect(() => {
    if (token) {
      if (customer) navigate("/home", { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  }, [customer, navigate, token]);

  return (
    <Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh", zIndex: "-10", position: "absolute" }}
      >
        <CircularProgress size={110} thickness={1.5} />

        <Box mt={5.8}></Box>
      </Grid>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Avatar
          src={Logo}
          sx={{
            backgroundColor: "transperant",
            height: "105px",
            width: "105px",
            padding: "5px",
          }}
        />
        <Typography
          mt={2}
          sx={{ fontWeight: "bold", fontSize: "21px", color: "primary.main" }}
        >
          LAVADO
        </Typography>
      </Grid>
    </Box>
  );
}

export default Splash;
