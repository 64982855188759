import {
  Box,
  Card,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import WhatsappBubble from "../../assets/WhatsappBubble.png";
import MailBubble from "../../assets/MailBubble.png";
import CallBubble from "../../assets/CallBubble.png";
import Appbar from "../../components/appbar/appbar";
import { callNumber, emailId, whatsappNumber } from "../../constants/constants";
import ContactItem from "../../components/help/contact_item";
import Bottombar from "../../components/appbar/bottom_bar";

function Help() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const onItemSelected = (navigateTo: string) => {
    navigate(navigateTo);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Appbar currentTabIndex={2} />
      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        <h1>Help</h1>
        <List sx={{ mt: "12px" }}>
          <Card sx={{ my: "10px" }}>
            <ListItemButton
              onClick={() => {
                onItemSelected("/termsAndConditions");
              }}
            >
              <ListItemText primary="Terms and Conditions" />
            </ListItemButton>
          </Card>
          <Card sx={{ my: "10px" }}>
            <ListItemButton
              onClick={() => {
                onItemSelected("/privacyPolicy");
              }}
            >
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>
          </Card>
          <Card sx={{ my: "10px" }}>
            <ListItemButton
              onClick={() => {
                onItemSelected("/faq");
              }}
            >
              <ListItemText primary="FAQs" />
            </ListItemButton>
          </Card>
          <Card sx={{ my: "10px" }}>
            <ListItemButton onClick={handleOpen}>
              <ListItemText primary="Contact Us" />
            </ListItemButton>
          </Card>
        </List>
        <Box height="100px" />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: matches ? "75vw" : "300px",
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Contact us
          </Typography>
          <Box sx={{ display: "flex", mt: "10px" }}>
            <Link
              sx={{
                flexGrow: "1",
                borderRadius: "0",
                textAlign: "center",
                boxShadow: "none",
                padding: "0px",
                backgroundColor: "white",
              }}
              href={`mailto:${emailId}`}
            >
              <ContactItem title="Mail" image={MailBubble} />
            </Link>
            <Link
              sx={{
                flexGrow: "1",
                borderRadius: "0",
                textAlign: "center",
                boxShadow: "none",
                padding: "0px",
                backgroundColor: "white",
              }}
              href={`tel:+91${callNumber}`}
            >
              <ContactItem title="Call" image={CallBubble} />
            </Link>
            <Link
              sx={{
                flexGrow: "1",
                borderRadius: "0",
                textAlign: "center",
                boxShadow: "none",
                padding: "0px",
                backgroundColor: "white",
              }}
              href={`https://wa.me/91${whatsappNumber}`}
            >
              <ContactItem title="Whatsapp" image={WhatsappBubble} />
            </Link>
          </Box>
        </Box>
      </Modal>
      {matches && <Bottombar currentTabIndex={2} />}
    </div>
  );
}

export default Help;
