import { AiFillPhone } from "react-icons/ai";
import { HiMail } from "react-icons/hi";
import { useAppSelector } from "../../redux/store";
import { Box, Button, CircularProgress, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

function UserCard() {
  const { customer } = useAppSelector((state) => state.customer);

  const navigate = useNavigate();

  return customer ? (
    <Paper sx={{ padding: "10px", backgroundColor: "#f8fdfe" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "25px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            {/* <FaUser size={20} style={{ marginRight: "5px" }} /> */}
            {customer.name}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "13px",
              marginBottom: "5px",
            }}
          >
            <AiFillPhone size={20} style={{ marginRight: "5px" }} />
            {customer.phone}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "13px",
            }}
          >
            <HiMail size={20} style={{ marginRight: "5px" }} />
            {customer.email}
          </Box>
        </Grid>
        <Box sx={{ flexGrow: 1 }} />
        <Grid item>
          <Button
            onClick={() => {
              navigate("/editProfile");
            }}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  ) : (
    <Box textAlign="center">
      <CircularProgress />
    </Box>
  );
}
export default UserCard;
