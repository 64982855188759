import { Grid, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { availableSlots } from "../../../constants/constants";
import { setCreateOrderDetails } from "../../../redux/features/order/order.slice";
import { Slot } from "../../../redux/features/order/order.types";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { formatDateForOrder } from "../../../utils/date.util";

interface TimeSlotSelectorProps {
  selectedDate: Date;
}

function TimeSlotSelector({ selectedDate }: TimeSlotSelectorProps) {
  const { createOrderDetails } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();

  const handleSlotChanged = (item: Slot) =>
    item &&
    dispatch(
      setCreateOrderDetails({
        ...createOrderDetails,
        selectedSlot: item,
      })
    );

  useEffect(() => {
    if (!createOrderDetails.selectedSlot) {
      let availableSlot = availableSlots[0];
      if (formatDateForOrder(selectedDate) === formatDateForOrder(new Date())) {
        availableSlot =
          availableSlots.find((s) => s.startTime > new Date().getHours()) ??
          availableSlots[0];
      }
      dispatch(
        setCreateOrderDetails({
          ...createOrderDetails,
          selectedSlot: availableSlot,
        })
      );
    }
  }, [createOrderDetails, dispatch, selectedDate]);

  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
          Select suitable time slot
        </Typography>
      </Grid>
      {availableSlots.map((item, index) => {
        return (
          <Grid item xs={true} md={1.5} key={index}>
            <Button
              disableRipple
              sx={{
                px: "16px",
                py: "8px",
                border: "1px solid #e6e6e6",
                width: "150px",
                color:
                  createOrderDetails.selectedSlot?.slotDisplay ===
                  item.slotDisplay
                    ? "white"
                    : "black",
                backgroundColor:
                  createOrderDetails.selectedSlot?.slotDisplay ===
                  item.slotDisplay
                    ? "#00C4CC"
                    : "white",
                "&:hover": {
                  backgroundColor:
                    createOrderDetails.selectedSlot?.slotDisplay ===
                    item.slotDisplay
                      ? "#00C4CC"
                      : "white",
                },
              }}
              onClick={() => {
                handleSlotChanged(item);
              }}
              disabled={
                item.startTime <= new Date().getHours() &&
                new Date() > selectedDate
              }
            >
              {item.slotDisplay}
            </Button>
          </Grid>
        );
      })}
    </>
  );
}

export default TimeSlotSelector;
