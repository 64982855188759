import { Grid, Card, Typography, Paper, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { MdLocationPin } from "react-icons/md";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";

function AddressSelector() {
  const { customerAddresses, defaultCustomerAddress } = useAppSelector(
    (s) => s.address
  );
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12} md={4.5}>
        <Card
          sx={{
            border: "1px solid #e8e8e8",
            padding: "10px",
          }}
        >
          <Typography
            sx={{ fontWeight: "500", fontSize: "16px", flexGrow: "1" }}
          >
            Address
          </Typography>
          {customerAddresses && customerAddresses.length > 0 ? (
            <Box>
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    display: "flex",
                    boxShadow: "none",
                    margin: "0 0 0 0",
                  }}
                >
                  {" "}
                  <MdLocationPin size="20px" color="red" />
                  <Typography sx={{ color: "black", fontSize: "15px" }}>
                    {" "}
                    {defaultCustomerAddress?.tag ?? customerAddresses[0]?.tag}
                  </Typography>
                </Paper>
                <Button
                  onClick={() => {
                    navigate({
                      pathname: "/address",
                      search: createSearchParams({
                        redirect: "1",
                      }).toString(),
                    });
                  }}
                >
                  Change
                </Button>
              </Box>

              <Typography
                sx={{
                  margin: "0 0 0 5px",
                  color: "gray",
                  fontSize: "13px",
                }}
              >
                {" "}
                {defaultCustomerAddress
                  ? defaultCustomerAddress.line1
                  : customerAddresses[0]?.line1}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    display: "flex",
                    boxShadow: "none",
                    margin: "0 0 0 0",
                  }}
                >
                  {" "}
                  <Typography sx={{ color: "black", fontSize: "15px" }}>
                    No Saved Address
                  </Typography>
                </Paper>
                <Button
                  onClick={() => {
                    navigate('/address/add');
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}
        </Card>
      </Grid>
    </>
  );
}

export default AddressSelector;
