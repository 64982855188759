import {
  AppBar,
  Card,
  CardActionArea,
  CardContent,
  Toolbar,
} from "@mui/material";

import ProfileImage from "../../assets/account_icon.png";
import PricingImage from "../../assets/price_icon.png";
import HomeImage from "../../assets/home_icon.png";

import BottomBarItem from "./bottom_bar_item";
import { HOME_TABS } from "../../constants/tabs_constant";
import { useNavigate } from "react-router-dom";

function BottomBar({ currentTabIndex }: { currentTabIndex: number }) {
  const navigate = useNavigate();

  const handleNavClicked = (navIndex: number) => {
    if (navIndex === 0) {
      navigate("/home");
    } else if (navIndex === 1) {
      navigate("/pricing");
    } else if (navIndex === 2) {
      navigate("/profile");
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{ top: "auto", bottom: 0, backgroundColor: "white" }}
    >
      <Toolbar sx={{ margin: "0px", padding: "0px" }}>
        {HOME_TABS.map((x, i) => (
          <Card
            key={i}
            sx={{
              flexGrow: "1",
              borderRadius: "0",
              textAlign: "center",
              boxShadow: "none",
              padding: "0px",
              backgroundColor: "white",
            }}
          >
            <CardActionArea onClick={() => handleNavClicked(i)}>
              <CardContent
                sx={{
                  p: "10px",
                  fontSize: currentTabIndex === i ? "15px" : "14px",
                  color: currentTabIndex === i ? "primary.main" : "black",
                }}
              >
                <BottomBarItem
                  title={x}
                  image={
                    i === 0 ? HomeImage : i === 1 ? PricingImage : ProfileImage
                  }
                />
              </CardContent>{" "}
            </CardActionArea>
          </Card>
        ))}
      </Toolbar>
    </AppBar>
  );
}

export default BottomBar;
