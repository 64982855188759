function ContactItem({ title, image }: { title: string; image: string }) {
  return (
    <div
      style={{
        verticalAlign: "top",
        display: "inline-block",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
      }}
    >
      <img
        src={image}
        alt="Lavado Menu"
        style={{
          width: "35px",
          height: "35px",
          marginBottom: "2px",
        }}
      />
      <span style={{ display: "block", fontSize: "14px" }}>{title}</span>
    </div>
  );
}

export default ContactItem;
