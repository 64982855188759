import {
  Box,
  Card,
  Link,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import CoinImage from "../../assets/coin.png";
import { storeLink } from "../../constants/constants";
import { useAppSelector } from "../../redux/store";
import { useSnackbar } from "notistack";
import DismissAction from "./dismiss_action";
import MailBubble from "../../assets/MailBubble.png";
import LinkBubble from "../../assets/LinkBubble.png";
import WhatsappBubble from "../../assets/WhatsappBubble.png";
import ContactItem from "../help/contact_item";

function ReferCard() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { enqueueSnackbar } = useSnackbar();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "75vw" : "300px",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    outline: 0,
  };

  const { customer } = useAppSelector((state) => state.customer);

  const [prefilledMessage, setPrefilledMessage] = useState(
    `Join me on Lavado App and enjoy clean clothes without the hassle of doing laundry. Use my code ${customer?.referCode} while registering and get Rs 30 off on your first order. Download Link: ${storeLink}`
  );

  useEffect(() => {
    setPrefilledMessage(
      `Join me on Lavado App and enjoy clean clothes without the hassle of doing laundry. Use my code ${customer?.referCode} while registering and get Rs 30 off on your first order. Download Link: ${storeLink}`
    );
  }, [customer]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ height: "155px", backgroundColor: "#e8f2ca" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "18px",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        <Box
          component="img"
          sx={{
            height: { xs: 25, md: 50 },
            width: { xs: 25, md: 50 },
            padding: "10px",
          }}
          alt="Refer"
          src={CoinImage}
        />
        Refer & Earn
      </Box>
      <Typography sx={{ padding: "0px 0px 0px 10px" }}>
        Share this code{" "}
        <span style={{ fontWeight: "bold" }}>
          {customer?.referCode ?? "Loading"}
        </span>{" "}
        and earn Rs.30 on your friend's first servicing.{" "}
      </Typography>

      <Box
        sx={{
          border: "1px solid red",
          width: "70px",
          py: "4px",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: "2px",
          mt: "8px",
          mr: "10px",
          ml: "auto",
          cursor: "pointer",
        }}
        component="button"
        onClick={handleOpen}
      >
        <Typography sx={{ color: "red" }}>Invite</Typography>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Share via
          </Typography>
          <Box sx={{ display: "flex", mt: "10px" }}>
            <Link
              sx={{
                flexGrow: "1",
                borderRadius: "0",
                textAlign: "center",
                boxShadow: "none",
                padding: "0px",
                backgroundColor: "white",
              }}
              target="_blank"
              href={`whatsapp://send?text=${prefilledMessage}`}
            >
              <ContactItem title="Whatsapp" image={WhatsappBubble} />
            </Link>
            <Link
              sx={{
                flexGrow: "1",
                borderRadius: "0",
                textAlign: "center",
                boxShadow: "none",
                padding: "0px",
                backgroundColor: "white",
              }}
              href={`mailto:?subject=Share Lavado&body=${prefilledMessage}`}
              target="_blank"
            >
              <ContactItem title="Mail" image={MailBubble} />
            </Link>
            <Link
              sx={{
                flexGrow: "1",
                borderRadius: "0",
                textAlign: "center",
                boxShadow: "none",
                padding: "0px",
                backgroundColor: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                navigator.clipboard.writeText(prefilledMessage);
                enqueueSnackbar("Copied to clipboard", {
                  variant: "success",
                  action: DismissAction,
                });
              }}
            >
              <ContactItem title="Copy link" image={LinkBubble} />
            </Link>
          </Box>
        </Box>
      </Modal>
    </Card>
  );
}

export default ReferCard;
