import { Grid, styled, TextField, Typography } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setRescheduleOrderDetails } from "../../../redux/features/order/order.slice";
import { SECONDARY_COLOR } from "../../../constants/theme";
import { getDefaultDate } from "../../../utils/date.util";

interface RDateSelectorProps {
  isPickup?: boolean;
}

function RDateSelector({ isPickup }: RDateSelectorProps) {
  const { rescheduleOrderDetails } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();

  const handlePickupDateChanged = (date: Date | null) =>
    date &&
    dispatch(
      setRescheduleOrderDetails({
        rescheduleDate: date.toISOString(),
        rescheduleSlot: undefined,
      })
    );

  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
          {`Select ${isPickup ? "pickup" : "delivery"} date`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label={`${isPickup ? "Pickup" : "Delivery"} date`}
            inputFormat="dd/MM/yyyy"
            value={rescheduleOrderDetails.rescheduleDate ?? getDefaultDate()}
            onChange={handlePickupDateChanged}
            disablePast
            renderInput={(params) => (
              <CustomBorderTextField disabled {...params} />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
}

export default RDateSelector;

const CustomBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: black;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${SECONDARY_COLOR};
    }
  }
`;
