import axios, { AxiosRequestConfig } from "axios";

export type GlobalResponse<T> = {
  success: boolean;
  data: T;
};

export type GoogleMapResponse = {
  status: string;
  results: {
    formatted_address: string;
  }[];
};

export type ParamWithData = {
  url: string;
  data?: any;
  config?: AxiosRequestConfig;
};

export type ParamWithoutData = {
  url: string;
  config?: AxiosRequestConfig;
};

export class APIService {
  async get<T>({ url, config }: ParamWithoutData) {
    const res = await axios.get<GlobalResponse<T>>(
      `${process.env.REACT_APP_API_URL}/${url}`,
      config
    );
    return res.data.data;
  }

  async getGoogleMap({
    url,
    apiKey,
  }: {
    url: string;
    apiKey: string;
  }): Promise<string> {
    const { data } = await axios.get<GoogleMapResponse>(`${url}&key=${apiKey}`);
    if (data.status !== "OK" || !data.results.length)
      throw new Error("Error connecting to Google Map Services");

    let addr = data.results[0].formatted_address;
    const [, ...rest] = addr.split(" ");
    addr = rest.join(" ").trim();
    return addr;
  }

  async post<T>({ url, data, config }: ParamWithData) {
    const res = await axios.post<GlobalResponse<T>>(
      `${process.env.REACT_APP_API_URL}/${url}`,
      data,
      config
    );
    return res.data.data;
  }

  async put<T>({ url, data, config }: ParamWithData) {
    const res = await axios.put<GlobalResponse<T>>(
      `${process.env.REACT_APP_API_URL}/${url}`,
      data,
      config
    );
    return res.data.data;
  }

  async delete<T>({ url, data, config }: ParamWithData) {
    const res = await axios.delete<GlobalResponse<T>>(
      `${process.env.REACT_APP_API_URL}/${url}`,
      {
        ...config,
        data,
      }
    );
    return res.data.data;
  }
}
