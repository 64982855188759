import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import DismissAction from "../components/common/dismiss_action";

export function useErrorSnackbar({
  isError,
  error,
}: {
  isError: boolean;
  error: any;
}) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (isError) {
      const data =
        typeof error === "string"
          ? error
          : (
              error as AxiosError<{
                message?: string;
                success: boolean;
              }>
            ).response?.data?.message;

      enqueueSnackbar(data ?? "Something went wrong", {
        variant: "error",
        action: DismissAction,
      });
    }
  }, [isError, error, enqueueSnackbar]);
}
