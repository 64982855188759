import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { APIService } from "../../../../utils/api.service";
import {
  formatOrder,
  getUpdatedOrdersArray,
} from "../../../../utils/order.util";
import { useAppSelector } from "../../../store";
import {
  resetSelectedOrder,
  setOrders,
  setSelectedOrder,
} from "../order.slice";
import {
  ApplyCouponInput,
  CreateOrderInput,
  OrderRaw,
  PAYMENT_TYPE,
  RateOrderInput,
  RescheduleDeliveryInput,
  ReschedulePickupInput,
} from "../order.types";

const api = new APIService();

export const useCreateOrder = () => {
  const dispatch = useDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  const queryClient = useQueryClient();

  return useMutation(
    ["ccorder/create"],
    (order: CreateOrderInput) =>
      api.post<OrderRaw>({
        url: "ccorder",
        data: order,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: (order) => {
        dispatch(setSelectedOrder(formatOrder(order)));
        queryClient.invalidateQueries(["ccorder/getAll"]);
      },
    }
  );
};

export const useValidateCoupon = () => {
  const { customer } = useAppSelector((s) => s.customer);

  return useMutation(
    ["ccorder/validateCoupon"],
    ({ couponCode }: { couponCode: string }) =>
      api.get<boolean>({
        url: `ccorder/coupon/${couponCode}`,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      })
  );
};

export const useApplyCoupon = () => {
  const dispatch = useDispatch();
  const { customer } = useAppSelector((s) => s.customer);

  return useMutation(
    ["ccorder/applyCoupon"],
    ({ applyCouponInput }: {
      applyCouponInput: ApplyCouponInput;
    }) =>
      api.post<OrderRaw>({
        url: `ccorder/applyCoupon`,
        data: applyCouponInput,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: (order) => {
        dispatch(setSelectedOrder(formatOrder(order)));
      },
    }
  );
};

export const useReschedulePickup = () => {
  const dispatch = useDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  const { orders } = useAppSelector((s) => s.order);

  return useMutation(
    ["ccorder/reschedulePickup"],
    ({
      orderId,
      pickupInput,
    }: {
      orderId: string;
      pickupInput: ReschedulePickupInput;
    }) =>
      api.put<OrderRaw>({
        url: `ccorder/reschedulePickup/${orderId}`,
        data: pickupInput,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: (order) => {
        dispatch(setSelectedOrder(formatOrder(order)));
        orders && dispatch(setOrders(getUpdatedOrdersArray(orders, order)));
      },
    }
  );
};

export const useRescheduleDelivery = () => {
  const dispatch = useDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  const { orders } = useAppSelector((s) => s.order);

  return useMutation(
    ["ccorder/rescheduleDelivery"],
    ({
      orderId,
      deliveryInput,
    }: {
      orderId: string;
      deliveryInput: RescheduleDeliveryInput;
    }) =>
      api.put<OrderRaw>({
        url: `ccorder/rescheduleDelivery/${orderId}`,
        data: deliveryInput,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: (order) => {
        dispatch(setSelectedOrder(formatOrder(order)));
        orders && dispatch(setOrders(getUpdatedOrdersArray(orders, order)));
      },
    }
  );
};

export const useCancelOrder = () => {
  const dispatch = useDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  const queryClient = useQueryClient();

  return useMutation(
    ["ccorder/cancel"],
    ({ orderId, reason }: { orderId: string; reason: string }) =>
      api.delete<OrderRaw>({
        url: `ccorder/${orderId}`,
        data: { reason },
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: () => {
        dispatch(resetSelectedOrder());
        queryClient.invalidateQueries(["ccorder/getAll"]);
      },
    }
  );
};

export const useGetRazorPayID = () => {
  const { customer } = useAppSelector((s) => s.customer);

  return useMutation(
    ["ccorder/getRazorPayID"],
    ({ orderId }: { orderId: string }) =>
      api.get<string>({
        url: `ccorder/razorpay/${orderId}`,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      })
  );
};

export const usePayForOrder = () => {
  const dispatch = useDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  const { orders } = useAppSelector((s) => s.order);

  return useMutation(
    ["ccorder/payForOrder"],
    ({
      orderId,
      paymentType,
    }: {
      orderId: string;
      paymentType: PAYMENT_TYPE;
    }) =>
      api.put<OrderRaw>({
        url:
          paymentType === PAYMENT_TYPE.CHECK
            ? `ccorder/payCOD/${orderId}`
            : `ccorder/payOnline/${orderId}`,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: (order) => {
        dispatch(setSelectedOrder(formatOrder(order)));
        orders && dispatch(setOrders(getUpdatedOrdersArray(orders, order)));
      },
    }
  );
};

export const useRateOrder = () => {
  const dispatch = useDispatch();
  const { customer } = useAppSelector((s) => s.customer);
  const { orders } = useAppSelector((s) => s.order);

  return useMutation(
    ["ccorder/rateOrder"],
    ({
      orderId,
      rateOrderInput,
    }: {
      orderId: string;
      rateOrderInput: RateOrderInput;
    }) =>
      api.post<OrderRaw>({
        url: `ccorder/rate/${orderId}`,
        data: rateOrderInput,
        config: {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        },
      }),
    {
      onSuccess: (order) => {
        dispatch(setSelectedOrder(formatOrder(order)));
        orders && dispatch(setOrders(getUpdatedOrdersArray(orders, order)));
      },
    }
  );
};
