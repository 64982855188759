import { Box, Card, Link } from "@mui/material";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
} from "react-icons/ai";

function SocialCard() {
  return (
    <Card sx={{ height: "155px", backgroundColor: "#4e3f71" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "16px",
          marginBottom: "5px",
          padding: "10px",
          color: "white",
        }}
      >
        Follow Us!
      </Box>
      <Box sx={{ fontSize: "40px", marginLeft: "5px" }}>
        <Link
          href="https://www.facebook.com/lavadocare"
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          <AiFillFacebook style={{ marginRight: "8px", color: "white" }} />
        </Link>
        <Link
          href="https://www.instagram.com/lavadodrycleaning/"
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          <AiFillInstagram style={{ marginRight: "8px", color: "white" }} />
        </Link>

        <Link
          href="https://twitter.com/LavadoCare"
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          <AiFillTwitterSquare style={{ marginRight: "8px", color: "white" }} />
        </Link>
      </Box>
    </Card>
  );
}

export default SocialCard;
