import {
  AppBar,
  Avatar,
  Box,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HOME_TABS } from "../../constants/tabs_constant";
import { setDefaultCustomerAddress } from "../../redux/features/address/address.slice";
import { setCurrentServiceId } from "../../redux/features/service/service.slice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import AddressBar from "./address_bar";

import Logo from "../../assets/lavado_default_logo.png";

function Appbar({
  currentTabIndex,
  isAddressbarShown = false,
}: {
  currentTabIndex: number;
  isAddressbarShown?: boolean;
}) {
  const navigate = useNavigate();
  const { customerAddresses, defaultCustomerAddress } = useAppSelector(
    (s) => s.address
  );

  const { customer } = useAppSelector((s) => s.customer);
  const { services } = useAppSelector((event) => event.service);

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (customer && customerAddresses?.length && !defaultCustomerAddress) {
      const defaultAddress = customerAddresses.find(
        (a) => a.id === customer.currentAddressID
      );
      defaultAddress && dispatch(setDefaultCustomerAddress(defaultAddress));
    }
  }, [customer, customerAddresses, defaultCustomerAddress, dispatch]);

  const handleNavClicked = (navIndex: number) => {
    if (navIndex === 0) {
      navigate("/home");
    } else if (navIndex === 1) {
      if (services) {
        dispatch(setCurrentServiceId(services[0].id));
      }
      navigate("/pricing");
    } else if (navIndex === 2) {
      navigate("/profile");
    }
  };

  return (
    <AppBar
      style={{ flexGrow: 1 }}
      position="static"
      elevation={0}
      sx={{ backgroundColor: "white", textAlign: "start" }}
    >
      <Toolbar>
        {!isMobile && (
          <Link to="/home">
            <Avatar
              src={Logo}
              sx={{
                backgroundColor: "transperant",
                height: "50px",
                width: "50px",
                padding: "5px",
              }}
            />
          </Link>
        )}
        {isAddressbarShown && (
          <AddressBar
            line1={
              defaultCustomerAddress
                ? defaultCustomerAddress.line1
                : "Unknown Location"
            }
            tag={
              defaultCustomerAddress
                ? defaultCustomerAddress.tag ?? "Home"
                : "Home"
            }
          />
        )}
        <Box sx={{ flexGrow: 1 }} />
        {!isMobile &&
          HOME_TABS.map((x, i) => (
            <Button
              key={i}
              onClick={() => handleNavClicked(i)}
              sx={{ color: i === currentTabIndex ? "primary.main" : "black" }}
            >
              {x}
            </Button>
          ))}
      </Toolbar>
    </AppBar>
  );
}

export default Appbar;
