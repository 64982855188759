import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthStage, Customer, CustomerState } from "./customer.types";

export const initialState: CustomerState = {
  authStage: AuthStage.DEFAULT,
  referCode: "",
};

export const customerSlice = createSlice({
  initialState,
  name: "customer",
  reducers: {
    setCustomer: (state: CustomerState, action: PayloadAction<Customer>) => {
      state.customer = action.payload;
    },
    resetCustomer: (state: CustomerState) => {
      state.customer = undefined;
    },
    setOTPPhone: (state: CustomerState, action: PayloadAction<string>) => {
      state.otpPhone = action.payload;
    },
    resetOTPPhone: (state: CustomerState) => {
      state.otpPhone = undefined;
    },
    setReferCode: (
      state: CustomerState,
      action: PayloadAction<string | null>
    ) => {
      state.referCode = action.payload;
    },
    resetReferCode: (state: CustomerState) => {
      state.referCode = "";
    },
    setAuthStage: (state: CustomerState, action: PayloadAction<AuthStage>) => {
      state.authStage = action.payload;
    },
    resetAuthStage: (state: CustomerState) => {
      state.authStage = AuthStage.DEFAULT;
    },
  },
});

export const {
  resetCustomer,
  setCustomer,
  setOTPPhone,
  setReferCode,
  resetAuthStage,
  resetOTPPhone,
  resetReferCode,
  setAuthStage,
} = customerSlice.actions;
