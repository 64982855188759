export interface Location {
  id: number;
  latitude: number;
  longitude: number;
  line1: string;
  line2?: string;
}

export interface Address extends Location {
  tag?: string;
}

export enum LocationStage {
  DEFAULT = "default",
  LOCATION_NOT_SERVICEABLE = "location_not_serviceable",
  LOCATION_SERVICEABLE = "location_serviceable",
}

export interface AddressState {
  serviceableLocations?: Location[];
  locationStage: LocationStage;
  currentLocation?: Address;
  customerAddresses?: Address[];
  defaultCustomerAddress?: Address;
}
