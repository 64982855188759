import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import Appbar from "../../components/appbar/appbar";
import CompletedList from "../../components/order/completed_list";
import OngoingList from "../../components/order/ongoing_list";
import UpcomingList from "../../components/order/upcoming_list";
import { orderDisplayStatus } from "../../constants/constants";

import Bottombar from "../../components/appbar/bottom_bar";
import { useGetAllOrders } from "../../redux/features/order/api/query";

function OrderList() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedDisplayStatus, setSelectedDisplayStatus] = useState<number>(0);
  const { refetch: refetchOrders, isLoading: isGetOrdersLoading } =
    useGetAllOrders();

  const getOrderList = (): ReactNode => {
    switch (selectedDisplayStatus) {
      case 0:
        return <OngoingList />;
      case 1:
        return <UpcomingList />;
      case 2:
        return <CompletedList />;
      default:
        return <div />;
    }
  };

  const handleOrderStatusClicked = (
    event: React.SyntheticEvent,
    index: number
  ) => {
    setSelectedDisplayStatus(index);
    if (!isGetOrdersLoading) refetchOrders();
  };

  useEffect(() => {
    if (!isGetOrdersLoading) refetchOrders();
  }, []);

  return (
    <>
      <Appbar currentTabIndex={2} />
      <Box sx={{ backgroundColor: "white" }}>
        <Tabs
          value={selectedDisplayStatus ?? 0}
          onChange={handleOrderStatusClicked}
          variant={matches ? "scrollable" : "fullWidth"}
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {orderDisplayStatus.map((item, index) => {
            return <Tab key={index} label={item}></Tab>;
          })}
        </Tabs>

        <div>{getOrderList()}</div>
        <Box sx={{ height: "100px" }}></Box>
        {matches && <Bottombar currentTabIndex={2} />}
      </Box>
    </>
  );
}

export default OrderList;
