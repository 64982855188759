import { Box, Paper, Typography } from "@mui/material";

import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function AddressBar({ line1, tag }: { line1: string; tag: string }) {
  const navigate = useNavigate();

  const handleAddressClick = () => {
    navigate("/address");
  };

  return (
    <Box
      sx={{ margin: "0 0 0 5px", cursor: "pointer" }}
      component="div"
      onClick={handleAddressClick}
    >
      <Paper
        sx={{
          display: "flex",
          boxShadow: "none",
          margin: "0 0 0 5px",
        }}
      >
        <Typography sx={{ color: "black", fontSize: "15px" }}>
          {tag ?? "Home"}
        </Typography>
        <IoIosArrowDown size="20px" color="gray" />
      </Paper>
      <Typography sx={{ margin: "0 0 0 5px", color: "gray", fontSize: "13px" }}>
        {line1}
      </Typography>
    </Box>
  );
}

export default AddressBar;
