import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_TOKEN } from "../constants/constants";
import { AuthStage } from "../redux/features/customer/customer.types";
import { useAppSelector, useAppDispatch } from "../redux/store";

export function useAuthStage() {
  const { authStage, customer, otpPhone } = useAppSelector(
    (state) => state.customer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!otpPhone) return navigate("/login");

    switch (authStage) {
      case AuthStage.OTP_SENT:
        navigate("/verify");
        break;
      case AuthStage.OTP_VERIFIED_NO_USER:
        otpPhone && !customer && navigate("/complete_profile");
        break;
      case AuthStage.OTP_VERIFIED_WITH_USER:
        customer && localStorage.setItem(CUSTOMER_TOKEN, customer.token);
        customer && navigate("/complete_profile");
        break;
      case AuthStage.PROFILE_COMPLETE:
        customer && navigate("/home");
        break;
      case AuthStage.DEFAULT:
        navigate("/login");
        break;
    }
  }, [authStage, customer, otpPhone, navigate, dispatch]);
}
