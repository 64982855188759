import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import Appbar from "../../components/appbar/appbar";
import Bottombar from "../../components/appbar/bottom_bar";
import OfferCard from "../../components/offer/offer_card";
import { useGetAllOffers } from "../../redux/features/offers/api/query";
import { useAppSelector } from "../../redux/store";

function Offer() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { offers } = useAppSelector((state) => state.offers);
  const { isLoading: isOffersLoading } = useGetAllOffers();

  return (
    <div>
      <Appbar currentTabIndex={0} />
      <Box sx={{ backgroundColor: "white", margin: "10px 10px 0px 10px" }}>
        {isOffersLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Offers for the day
              </Typography>
            </Grid>

            {offers?.map((item, index) => {
              return (
                <Grid item key={index} xs={12} md={3}>
                  <OfferCard item={item} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      {matches && <Bottombar currentTabIndex={0} />}
    </div>
  );
}

export default Offer;
