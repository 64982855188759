import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Grid,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { HiLocationMarker } from "react-icons/hi";
import { TiTick } from "react-icons/ti";
import Appbar from "../../../components/appbar/appbar";
import BottomBar from "../../../components/appbar/bottom_bar";
import { useSetOrderId } from "../../../hooks/order_id";
import { useGetAllOrders } from "../../../redux/features/order/api/query";
import { PAYMENT_TYPE } from "../../../redux/features/order/order.types";
import { useAppSelector } from "../../../redux/store";
import { calculateTotalTax } from "../../../utils/order.util";

function CompletedOrderDetails() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedOrder } = useAppSelector((s) => s.order);
  const { customerAddresses, defaultCustomerAddress } = useAppSelector(
    (s) => s.address
  );
  const {
    isLoading: getAllOrdersLoading,
    isError: hasGetAllOrdersError,
    error: getAllOrdersError,
  } = useGetAllOrders();

  //* Hook to set the selected order when it is not available from redux store
  useSetOrderId();

  return (
    <div>
      <Appbar currentTabIndex={2} />
      <Box sx={{ backgroundColor: "white", margin: "20px 10px 0px 10px" }}>
        {getAllOrdersLoading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : hasGetAllOrdersError ? (
          <div>{getAllOrdersError}</div>
        ) : !selectedOrder ? (
          <div>Order not found</div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Box
                sx={{
                  display: "inline-flex",
                  width: matches ? "85vw" : "50vw",
                }}
              >
                <Card
                  elevation={0}
                  sx={{
                    m: "12px",
                    width: matches ? "85vw" : "50vw",
                    p: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 0px 5px 0px",
                    }}
                  >
                    <Typography
                      sx={{ color: "#00C4CC", display: "flex", width: "100%" }}
                    >
                      COMPLETED SERVICE
                    </Typography>
                    <Typography sx={{ color: "gray", alignSelf: "center" }}>
                      #{selectedOrder?.id}
                    </Typography>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        verticalAlign: "top",
                        display: "inline-block",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <HiLocationMarker size={20} color="#000000" />
                      <Box
                        sx={{
                          height: "60px",
                          width: "2px",
                          backgroundColor: "black",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      ></Box>
                    </div>
                    <Box
                      sx={{
                        marginLeft: "5px",
                        textAlign: "start",
                      }}
                    >
                      <Typography sx={{ color: "#00CE50", fontSize: "16px" }}>
                        Address
                      </Typography>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        {!selectedOrder?.address ||
                        selectedOrder?.address === "Unknown"
                          ? !defaultCustomerAddress
                            ? customerAddresses
                              ? customerAddresses[0].line1
                              : "Unknown"
                            : defaultCustomerAddress?.line1 ?? ""
                          : selectedOrder?.address}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        verticalAlign: "top",
                        display: "inline-block",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Avatar
                        sx={{
                          height: "20px",
                          width: "20px",
                          backgroundColor: "green",
                        }}
                      >
                        <TiTick />
                      </Avatar>
                    </div>
                    <Box
                      sx={{
                        marginLeft: "5px",
                        textAlign: "start",
                      }}
                    >
                      <Typography sx={{ color: "#00CE50", fontSize: "16px" }}>
                        Completed
                      </Typography>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        Completed on{" "}
                        {selectedOrder
                          ? format(
                              new Date(selectedOrder.pickupDate),
                              "MMM dd, yyyy"
                            )
                          : ""}{" "}
                        | {selectedOrder ? selectedOrder.pickupTime : ""}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      textAlign: "start",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "18", fontWeight: "bold", mt: "16px" }}
                    >
                      You Rated
                    </Typography>{" "}
                    <Rating
                      size="large"
                      value={selectedOrder?.rating}
                      readOnly
                    />
                    <Table
                      sx={{ width: "100%", mt: "15px" }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead sx={{ backgroundColor: "#e6e6e6" }}>
                        <TableRow>
                          <TableCell align="left">Garment</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedOrder?.summary
                          .split("<br>")
                          .filter((item) => item.length > 0)
                          .map((s, index) => {
                            let finalItem = s.split(" x ");
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  border: "0",
                                }}
                              >
                                <TableCell align="left" sx={{ color: "gray" }}>
                                  {finalItem[0]}
                                </TableCell>
                                <TableCell align="right" sx={{ color: "gray" }}>
                                  {finalItem[1]}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        <TableRow>
                          <TableCell align="left">Service Total</TableCell>
                          <TableCell align="right">
                            Rs.{" "}
                            {(selectedOrder?.total ?? 0) -
                              calculateTotalTax({
                                tax1: selectedOrder?.tax1 ?? 0,
                                tax2: selectedOrder?.tax2 ?? 0,
                                tax3: selectedOrder?.tax3 ?? 0,
                              })}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Taxes</TableCell>
                          <TableCell align="right">
                            Rs.{" "}
                            {calculateTotalTax({
                              tax1: selectedOrder?.tax1 ?? 0,
                              tax2: selectedOrder?.tax2 ?? 0,
                              tax3: selectedOrder?.tax3 ?? 0,
                            })}
                          </TableCell>{" "}
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Total</TableCell>
                          <TableCell align="right">
                            <div>Rs. {selectedOrder?.total}</div>
                            <div>
                              Paid via{" "}
                              {selectedOrder?.paymentType ===
                              PAYMENT_TYPE.NO_PAYMENT
                                ? "Unknown Mode"
                                : selectedOrder?.paymentType}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box sx={{ height: "100px" }}></Box>
      {matches && <BottomBar currentTabIndex={2} />}
    </div>
  );
}

export default CompletedOrderDetails;
