export type FaqModel = {
    type:string,
    question:string,
    answer:string
   };
   
 
 export const FaqData = {
     data: [
       {
         type: "Service Related",
         question: "How do you maintain hygiene?",
         answer:
           "Periodical cleaning and sanitisation of the facility, machines, equipment and delivery vehicles. The laundry collection bags are washed and sanitised before taking it into rotation.",
       },
       {
         type: "Service Related",
         question: "Why is my garment returned unprocessed?",
         answer:
           "Prior to processing, each garment is carefully inspected, if we feel that the garment may get damaged if processed, instead of risking damage we put the garment on hold and return it unprocessed.",
       },
       {
         type: "Service Related",
         question: "Do I need to separate my laundry & dry cleaning items?",
         answer:
           "Yes, please do. This will ensure everything will be processed as per your requirements. Inform our pickup representative he will put them in separate bags for each type of service you require (laundry, dry cleaning, ironing, etc).",
       },
       {
         type: "Service Related",
         question: "How will my garments be delivered?",
         answer:
           "The  garments  after  being  processed  are  neatly  folded  or  hung  and  placed  in  biodegradable covers. Garments  are then  loaded  in our delivery vehicle. A Lavado  representative will drop your order at your chosen date and time.",
       },
       {
         type: "Service Related",
         question: "Will Lavado be able to remove all the stains?",
         answer:
           "There are multiple types of stains, and they affect your garment in different ways. A stain that dyes the threads making your garment cannot be removed. Some may come out in a single attempt some may take multiple attempts. Our experts conduct spotting and follow pre-treatment procedures. If it still doesn’t come out we repeat the process but also taking care that no damage is done to the garment.",
       },
       {
         type: "Service Related",
         question: "What services does Lavado provide?",
         answer:
           "Dry cleaning & Premium Laundry: /n The items are processed individually, spot treatment is conducted for stains then dry cleaned or washed followed by steam ironing and packing on hanger or folded as per your choice (no additional charge). /n Laundry by kg: /n We process the order by weight so that you need not worry about the count, all items are machine washed, tumble dried, folded and packed together.  /n Steam ironing: /n You can hand over your clean garments and we will iron it for you. Depending on your requirement you can choose between basic and premium iron. Basic iron is good for your daily need, steam ironed and folded in bundles, whereas premium iron is good for your formal or party wear, here individual garment is either folded or put on a hanger as per your choice. /n Accessory care: /n Expert handling and care for your expensive accessories. From cleaning, washing, repairs, polish, to maintenance, our team does anything that helps improve the life of your shoes, bags, jackets, suitcase, belts and wallets. (subject to service availability in your area)",
       },
       {
         type: "Service Related",
         question: "How does Lavado work?",
         answer:
           "At Lavado it is all about convenience. Select your date, time and place we provide pick up / delivery for dry cleaning, laundry, steam ironing, tailor services, bags/shoes cleaning and repair.",
       },
       {
         type: "Order Related",
         question: "How to apply coupon code?",
         answer:
           "Once your order is ready for delivery you will have the option of selecting your preferred payment mode and you can  apply any available coupon code for the order.",
       },
       {
         type: "Order Related",
         question: "What is the turnaround time?",
         answer:
           "We provide 48 hrs delivery for most items, however curtains, carpets, bags, shoes and toys may take longer. Next day delivery is available on all other items at an additional cost.",
       },
       {
         type: "Order Related",
         question: "Is there any minimum order?",
         answer:
           "There is no minimum order you may schedule a pick-up even for one shirt, however on orders below Rs 360 there will be a pick-up & delivery charge of Rs 60.",
       },
       {
         type: "Order Related",
         question: "How can I reschedule my order?",
         answer:
           "You can easily reschedule your pick up uptill 2 hrs prior to your pick up time slot, by clicking on the tracker on the home screen or you will find it in ‘My Orders’ in the Upcoming section.",
       },
       {
         type: "Order Related",
         question: "How to place an order?",
         answer:
           "You may place an order on our app or call us on 8080-454-454, just select your convenient date and time and pick up address, you may even add a note for any special instructions. We will make sure you get your garments the way you like them.",
       },
       {
         type: "Payment Related",
         question: "When do I have to make the payment?",
         answer:
           "We collect the payment at the time of delivery. Your invoice is available once your items are inspected, you may complete the payment online and enjoy a contactless delivery.",
       },
       {
         type: "Payment Related",
         question: "What are the types of payment accepted?",
         answer:
           "We accept online payment via a link shared on your SMS and email, UPI & cash payments can be made at the time of delivery.",
       },
       {
         type: "Policy Related",
         question: "What if customer is not happy with the service?",
         answer:
           "If you feel the service quality is not satisfactory, please share pictures of the item in question on email / whatsapp and we will re-do the item for free.",
       },
       {
         type: "Policy Related",
         question: "What happens in case of damage / lost garment?",
         answer:
           "We take utmost care of all items submitted, it undergoes various inspection checks at each stage of the process. In the rare occasion there is a damage or the garment is lost then you will be compensated according to our terms & condition, the issue has to be raised within 72 hours of the delivery.",
       },
       {
         type: "Refer & Earn",
         question: "How to apply referral?",
         answer:
           "Please add the referral code along with your mobile number when you sign up in Lavado app",
       },
       {
         type: "Refer & Earn",
         question: "Where can I find my code?",
         answer:
           "Your referral code can be found at the bottom of the home page or my account page",
       },
       {
         type: "Refer & Earn",
         question: "How it works?",
         answer:
           "Share your referral code with a friend and you both get Rs 30 credit once they place their first order after signing up using your code.",
       },
     ],
   };
   