import { Grid, Card, Typography, Switch } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { setCreateOrderDetails } from "../../../redux/features/order/order.slice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";

function ExpressDelivery() {
  const { createOrderDetails } = useAppSelector((s) => s.order);
  const dispatch = useAppDispatch();

  const handleExpressDeliveryChanged = () =>
    dispatch(
      setCreateOrderDetails({
        ...createOrderDetails,
        expressDeliveryChecked: !createOrderDetails.expressDeliveryChecked,
      })
    );

  return (
    <>
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            border: "1px solid #e8e8e8",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{ fontWeight: "500", fontSize: "16px", flexGrow: "1" }}
            >
              Next Day Business Delivery
            </Typography>
            <Switch
              checked={createOrderDetails.expressDeliveryChecked}
              onChange={handleExpressDeliveryChanged}
            />
          </Box>
          {createOrderDetails.expressDeliveryChecked && (
            <Box>
              <Typography sx={{ color: "#00C4CC", fontSize: "13px" }}>
                Additional Charges Apply
              </Typography>
              <Typography sx={{ color: "gray", fontSize: "13px" }}>
                Expedited delivery invites an additional 100% of your net bill
                amount.
              </Typography>
              <Typography
                sx={{
                  color: "#00C4CC",
                  fontSize: "13px",
                  marginTop: "8px",
                }}
              >
                Excluded Items
              </Typography>
              <Typography sx={{ color: "gray", fontSize: "13px" }}>
                Next Business Day Delivery is not available for curtains,
                carpets, shoes, bags, pillows, and toys.
              </Typography>
            </Box>
          )}
        </Card>
      </Grid>
    </>
  );
}

export default ExpressDelivery;
