import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import ReferDialog from "../../../components/refer_dialog/refer_dialog";
import { useAuthStage } from "../../../hooks/auth_stage";
import { useErrorSnackbar } from "../../../hooks/error_snack";
import { useSendOTPRecaptcha } from "../../../redux/features/customer/api/mutation";

import Logo from "../../../assets/logo.png";
import { PRIMARY_COLOR } from "../../../constants/theme";
import { useQueryClient } from "@tanstack/react-query";
import ReCAPTCHA from "react-google-recaptcha";

function Login() {
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      phone: "",
    },

    onSubmit: async ({ phone }) => {
      const token = await reCaptchaRef.current?.executeAsync();
      sendOTP({ phone: `${phone.toString()}`, captchaToken: `${token ?? ""}` });
    },
  });

  const { isLoading, isError, error, mutate: sendOTP } = useSendOTPRecaptcha();
  const queryClient = useQueryClient();

  const handleRecaptchaChange = (value: string | null) => {};

  useAuthStage();
  useErrorSnackbar({ error: error, isError: isError });

  useEffect(() => {
    if (queryClient) {
      queryClient.clear();
      queryClient.removeQueries({
        stale: true,
      });
    }
  }, [queryClient]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_URL ?? ""}
        onChange={handleRecaptchaChange}
        size="invisible"
        ref={reCaptchaRef}
      />
      <Paper elevation={3} sx={{ padding: "30px 10px" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ marginBottom: "30px" }}
        >
          <Link href="https://lavado.in/">
            <Avatar
              src={Logo}
              sx={{
                backgroundColor: "primary.main",
                height: "70px",
                width: "70px",
                padding: "15px",
              }}
            />
          </Link>
        </Grid>

        <Box sx={{ border: "1px red solid", padding: "20px", margin: "10px" }}>
          <Typography>Simplifying garment and accessory care!</Typography>
          <Typography display="inline">Welcome to </Typography>
          <Typography
            display="inline"
            sx={{ color: "primary.main", fontWeight: "bold" }}
          >
            Lavado.
          </Typography>

          <form onSubmit={handleSubmit} style={{ marginTop: "10px" }}>
            <TextField
              required
              id="phone"
              label="Phone Number"
              type="tel"
              variant="standard"
              sx={{
                minWidth: "100%",
              }}
              onChange={handleChange}
              inputProps={{ maxLength: 10 }}
            />
            <Box textAlign="center" sx={{ marginTop: "20px" }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={
                    values.phone.toString().length !== 10 ? true : false
                  }
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "70%",
                    "&.Mui-disabled": {
                      opacity: 0.65,
                      color: "white",
                      backgroundColor: PRIMARY_COLOR,
                    },
                  }}
                >
                  Send
                </Button>
              )}
            </Box>
          </form>
        </Box>
        <Box textAlign="center" mt={2}>
          <ReferDialog />
        </Box>
      </Paper>
    </Grid>
  );
}

export default Login;
