import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

import Image from "../../../assets/profile_update_bg.png";

export const PaperWrapper = styled(Paper)(({ theme }) => ({
  padding: "30px 20px",
  border: "1px red solid",
  [theme.breakpoints.down("md")]: {
    width: "70%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "35%",
  },
}));

export const GridWrapper = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",
  backgroundImage: `url(${Image})`,
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundPosition: "bottom right",

  [theme.breakpoints.down("md")]: {
    backgroundSize: "300px 200px",
  },
  [theme.breakpoints.up("lg")]: {
    backgroundSize: "400px 300px",
  },
}));
