import { useQuery } from "@tanstack/react-query";
import { nonChangingOneYear } from "../../../../config/query.config";
import { APIService } from "../../../../utils/api.service";
import { useAppDispatch } from "../../../store";
import { setServices } from "../service.slice";
import { Service } from "../service.types";

const api = new APIService();

export const useGetAllServices = () => {
  const dispatch = useAppDispatch();
  return useQuery(
    ["service/getAll"],
    () =>
      api.get<Service[]>({
        url: "service",
      }),
    {
      ...nonChangingOneYear,
      onSuccess: (services) => {
        dispatch(setServices(services));
      },
    }
  );
};
